import { Picker } from "@react-native-picker/picker";
import WebModal from "modal-react-native-web";
import moment from "moment";
import React, { Component } from "react";
import { View } from "react-native";
import { Modal, Platform } from "react-native";
import { Button, Icon, Text } from "react-native-elements";
import { Overlay } from "react-native-elements/dist/overlay/Overlay";
import { connect } from "react-redux";

import { getContrastColor, HSLToHex } from "../../shared/helpers";
import { appColors, styles } from "../../shared/styles";

class ChooseDayToCook extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chosenDayToCook: null,
            daysToChooseFrom: [],
            timesToChooseFrom: [],
        };

        this.handleAddToCartPress = this.handleAddToCartPress.bind(this);
        this.getDaysToChoose = this.getDaysToChoose.bind(this);
        this.calculateDeliveryTimes = this.calculateDeliveryTimes.bind(this);
    }

    handleAddToCartPress() {
        const { onBackdropPress, onAddToCartPress, entry } = this.props;

        if (onAddToCartPress) {
            onAddToCartPress(entry);
            onBackdropPress();
        }
    }

    componentDidMount() {
        this.getDaysToChoose();
    }

    calculateDeliveryTimes() {
        var date = new Date(this.props.weeklyPlanDay);
        var dayOfTheWeek = date.getDay() === 0 ? 6 : date.getDay() - 1;
        date.setHours(this.props.settings.store.storeTimes[dayOfTheWeek].opening.slice(0, 2));
        date.setMinutes(this.props.settings.store.storeTimes[dayOfTheWeek].opening.slice(3));
        var endTime = new Date(this.props.weeklyPlanDay);
        endTime.setHours(this.props.settings.store.storeTimes[dayOfTheWeek].closing.slice(0, 2));
        endTime.setMinutes(this.props.settings.store.storeTimes[dayOfTheWeek].closing.slice(3));
        let deliveryTimes = [];
        if (date < endTime)
            while (date < endTime) {
                deliveryTimes.push(new Date(date));
                date.setMinutes(date.getMinutes() + this.props.settings.store.orderInterval);
            }
        this.setState({
            timesToChooseFrom: deliveryTimes,
        });
        this.props.onValueChange(deliveryTimes[0]);
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.visible !== this.props.visible) {
    //         if (this.props.visible) {
    //             if (this.state.daysToChooseFrom.length > 0) {
    //                 this.props.onValueChange(this.state.daysToChooseFrom[0]);
    //             }
    //         }
    //     }
    // }

    getDaysToChoose() {
        const { zipCode, entry, settings } = this.props;
        let nextDeliveryDate = zipCode.nextDeliveryDate ? zipCode.nextDeliveryDate : null;
        let expiration = entry.Article ? entry.Article.expiration : 1;
        expiration = expiration ? expiration : 1;
        let expirationAfterDelivery = expiration - settings.store.preparationLeadTimeInDays;
        let daysToChooseFrom = [];

        if (nextDeliveryDate) {
            let deliveryDate = moment(nextDeliveryDate);
            let i = 0;

            deliveryDate.subtract(1, "d");

            while (i <= expirationAfterDelivery) {
                deliveryDate.add(1, "d");
                daysToChooseFrom.push(deliveryDate.unix());
                i++;
            }
        }
        this.setState(
            {
                daysToChooseFrom: daysToChooseFrom,
            },
            () => this.calculateDeliveryTimes()
        );
    }

    render() {
        const { visible, article, onBackdropPress, onValueChange, theme } = this.props;
        const { timesToChooseFrom } = this.state;
        return (
            <Overlay
                overlayStyle={{
                    width: "80%",
                    padding: 0,
                    maxWidth: 320,
                    minWidth: 200,
                    overflow: "auto",
                    maxHeight: "100vh",
                    borderRadius: 10,
                    paddingHorizontal: 30,
                    paddingVertical: 20,
                }}
                visible={visible}
                ariaHideApp={false}
                ModalComponent={Platform.OS === "web" ? WebModal : Modal}
                onBackdropPress={onBackdropPress}
            >
                <Icon
                    name="times"
                    type="font-awesome-5"
                    size={14}
                    onPress={onBackdropPress}
                    containerStyle={[styles.overlayCloseIcon, { backgroundColor: appColors.primary }]}
                    color={getContrastColor(appColors.primary)}
                />
                <Text h2>
                    Möchtest du <span style={{ color: appColors.grey3 }}>{article.name}</span> in den Warenkorb legen?
                </Text>
                <View style={styles.pickerWrapper}>
                    <Text>Abholzeit wählen</Text>
                    <Picker onValueChange={onValueChange} style={styles.picker}>
                        {timesToChooseFrom.map((time, index) => {
                            return (
                                <Picker.Item
                                    key={index}
                                    label={
                                        time.getHours() +
                                        ":" +
                                        (time.getMinutes() < 10 ? "0" : "") +
                                        time.getMinutes() +
                                        " Uhr"
                                    }
                                    value={time}
                                />
                            );
                        })}
                    </Picker>
                </View>
                <Button
                    icon={
                        <Icon
                            name="shopping-cart"
                            type="font-awesome"
                            size={20}
                            color={getContrastColor(this.props.settings.store.accentColor)}
                            style={{ marginLeft: 10 }}
                        />
                    }
                    buttonStyle={{
                        backgroundColor: this.props.settings.store.accentColor,
                    }}
                    titleStyle={{
                        color: getContrastColor(this.props.settings.store.accentColor),
                        fontFamily: theme.theme.fonts.regular.fontFamily,
                    }}
                    title="In den Warenkorb"
                    iconRight
                    onPress={this.handleAddToCartPress}
                />

                {/* Not needed for the mensa shop
                <Text style={{ fontSize: 12, marginTop: 10 }}>
                    (Dieses Gericht ist ab Produktion {article.expiration === 0 ? 1 : article.expiration} Tage haltbar)
                </Text> */}
            </Overlay>
        );
    }
}

function mapStateToProps(state) {
    const { zipCode, settings, theme } = state;
    return { zipCode, settings, theme };
}

export default connect(mapStateToProps)(ChooseDayToCook);
