import { StyleSheet } from "react-native";

export const cartStyles = StyleSheet.create({
    container: {
        flex: 1,
    },
    articleContainer: {
        flexGrow: 1,
        paddingBottom: 10,
    },
});
