import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-elements";

import { articleDetailsStyle } from "./ArticleDetailsStyle";

export default function Additives({ additives }) {
    const additiveGroups = getAdditiveGroups(additives);
    return (
        <View>
            {additives.length > 0 && (
                <View>
                    {additiveGroups.map((additiveGroup, index) => {
                        let additivesFromGroup = getAdditivesByGroupsId(additives, additiveGroup.id);
                        return (
                            <View key={index}>
                                <Text h4 h4Style={StyleSheet.flatten(articleDetailsStyle.h4)}>
                                    {additiveGroup.name}:
                                </Text>
                                <Text style={StyleSheet.flatten(articleDetailsStyle.additives)}>
                                    {additivesFromGroup.map((additive, index) => {
                                        let divider = ", ";
                                        let name = additive.name;

                                        if (additivesFromGroup.length === index + 1) {
                                            divider = "";
                                        }

                                        return name + divider;
                                    })}
                                </Text>
                            </View>
                        );
                    })}
                </View>
            )}
            {additives.length === 0 && (
                <View>
                    <Text h4 h4Style={StyleSheet.flatten(articleDetailsStyle.h4)}>
                        Zusatzstoffe:
                    </Text>
                    <Text style={StyleSheet.flatten(articleDetailsStyle.additives)}>keine</Text>
                </View>
            )}
        </View>
    );
}

function getAdditiveGroups(additives) {
    const result = [];
    const additiveGroupsMap = new Map();

    for (const additve of additives) {
        if (!additiveGroupsMap.has(additve.AdditiveGroup.id)) {
            additiveGroupsMap.set(additve.AdditiveGroup.id, true);
            result.push(additve.AdditiveGroup);
        }
    }
    return result;
}

function getAdditivesByGroupsId(additives, groupsId) {
    return additives.filter((a) => a.AdditiveGroup.id === groupsId);
}
