import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-elements";
import { articleDetailsStyle } from "./ArticleDetailsStyle";

export default function Ingredients({ ingredients }) {
    const uniqueIngredients = getUniqueIngredients(ingredients);
    const splittedIngredients = splitIngredients(uniqueIngredients);
    return (
        <View style={articleDetailsStyle.ingredients}>
            <Text h4 h4Style={StyleSheet.flatten(articleDetailsStyle.h4)}>
                Zutaten:
            </Text>
            {splittedIngredients.single.length > 0 && (
                <Text style={StyleSheet.flatten(articleDetailsStyle.ingredientsList)}>
                    {splittedIngredients.single.map((ingredient, index) => {
                        let divider = ", ";
                        let name = ingredient.name;

                        if (splittedIngredients.single.length === index + 1) {
                            divider = "";
                        }

                        return name + divider;
                    })}
                </Text>
            )}

            {splittedIngredients.multiple.length > 0 && (
                <Text style={StyleSheet.flatten(articleDetailsStyle.ingredientsList)}>
                    {splittedIngredients.multiple.map((ingredient, index) => {
                        let uniqueIngredients = getUniqueIngredients(ingredient.IngredientArticle.Ingredients);
                        return (
                            <View key={index}>
                                <Text style={StyleSheet.flatten(articleDetailsStyle.h5)}>{ingredient.name}:</Text>
                                <Text style={StyleSheet.flatten(articleDetailsStyle.ingredientsList)}>
                                    {uniqueIngredients.map((ingredient, index) => {
                                        let divider = ", ";
                                        let name = ingredient.name;

                                        if (uniqueIngredients.length === index + 1) {
                                            divider = "";
                                        }

                                        return name + divider;
                                    })}
                                </Text>
                            </View>
                        );
                    })}
                </Text>
            )}
        </View>
    );
}

function getUniqueIngredients(ingredients) {
    const result = [];
    const map = new Map();

    for (const ingredient of ingredients) {
        if (!map.has(ingredient.ingredientsArticlesId)) {
            map.set(ingredient.ingredientsArticlesId, true);
            result.push(ingredient);
        }
    }

    return result;
}

function splitIngredients(ingredients) {
    const result = {
        single: [],
        multiple: [],
    };

    for (const ingredient of ingredients) {
        if (ingredient.IngredientArticle.Ingredients.length > 0) {
            result.multiple.push(ingredient);
        } else {
            result.single.push(ingredient);
        }
    }

    return result;
}
