import React, { Component } from "react";
import { Text, View } from "react-native";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";

class CartTotal extends Component {
    constructor(props) {
        super(props);

        this.calculateTotal = this.calculateTotal.bind(this);
    }

    calculateTotal() {
        let total = 0;

        if (this.props.cart.orderItems.length > 0) {
            let i = 0;

            while (this.props.cart.orderItems[i]) {
                const orderItem = this.props.cart.orderItems[i];

                if (orderItem) {
                    const price = orderItem.Price ? orderItem.Price.price1 : 0;
                    const { amount } = orderItem.entry;

                    if (price && amount) {
                        total += price * amount;
                    }
                }

                i++;
            }
        }

        return total;
    }

    render() {
        return (
            <View>
                <Text style={{ color: "#fff", lineHeight: "24px", paddingTop: "3px" }}>
                    <NumberFormat
                        value={this.calculateTotal()}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        thousandSeparator="."
                        suffix=" €"
                        displayType="text"
                    />
                </Text>
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { cart, customer } = state;
    return { cart, customer };
}

export default connect(mapStateToProps)(CartTotal);
