import React from "react";
import { View, StyleSheet, ScrollView, Pressable } from "react-native";
import { styles } from "../../shared/styles";
import { Icon } from "react-native-elements";

const CustomOverlay = ({ children, onHide, windowWidth, extraPadding = true }) => {
    return (
        <View style={styles.overlayWrapper}>
            <Pressable onPress={onHide} style={styles.closeButton}>
                <Icon name="close" type="font-awesome" size={20} color="white" />
            </Pressable>
            <View style={{ ...StyleSheet.flatten(styles.overlay), width: windowWidth >= 1200 ? "65vw" : "90vw" }}>
                <ScrollView
                    style={styles.overlayContent}
                    contentContainerStyle={
                        extraPadding === true
                            ? styles.scrollContentContainer
                            : styles.scrollContentContainerWithoutPadding
                    }
                >
                    <View style={styles.formContainer}>{children}</View>
                </ScrollView>
            </View>
        </View>
    );
};

export default CustomOverlay;
