import Config from "../../../Config";
import { loadFont } from "../../helpers/fontLoader/fontLoader";
import { handleResponse } from "../../helpers/requestHelpers";
import { store } from "../store";
import { set_theme } from "./themeActions";

const config = new Config();

export const SET_SETTINGS = "SET_SETTINGS";

export function setSettings(settings) {
    return {
        type: SET_SETTINGS,
        store: settings,
    };
}

export function getSettings() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/getShopSettings", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => {
                const theme = {
                    title: json.title,
                    logoUrl: json.logoUrl,
                    primary: json.primaryColor,
                    accentColor: json.accentColor,
                    backgroundColor: json.backgroundColor,
                    surface: json.surfaceColor,
                    error: json.errorColor,
                    text: json.textColor,
                    disabled: json.disabledColor,
                    placeholder: json.placeholderColor,
                    backdrop: json.backdropColor,
                    notification: json.notificationColor,
                    onSurface: json.onSurfaceColor,
                    fonts: {
                        regular: {
                            fontFamily: "regular",
                            fontWeight: json.regularFontWeight,
                        },
                        medium: {
                            fontFamily: "medium",
                            fontWeight: json.mediumFontWeight,
                        },
                        light: {
                            fontFamily: "light",
                            fontWeight: json.lightFontWeight,
                        },
                        thin: {
                            fontFamily: "thin",
                            fontWeight: json.thinFontWeight,
                        },
                    },
                    customCSS: json.customCSS,
                    regularFontPath: json.regularFontPath,
                    mediumFontPath: json.mediumFontPath,
                    lightFontPath: json.lightFontPath,
                    thinFontPath: json.thinFontPath,
                    customCSS: json.customCSS,
                };
                loadFont(json);
                let allFonts = [
                    json.regularFontFamily + ":" + json.regularFontWeight,
                    json.mediumFontFamily + ":" + json.mediumFontWeight,
                    json.lightFontFamily + ":" + json.lightFontWeight,
                    json.thinFontFamily + ":" + json.thinFontWeight,
                ];

                let fontsToLoad = allFonts.filter((v, i, a) => a.indexOf(v) === i);

                // loadFont(fontsToLoad);
                const styleElement = document.createElement("style");
                styleElement.textContent = json.customCSS;
                document.head.appendChild(styleElement);

                store.dispatch(setSettings(json));
                store.dispatch(set_theme(theme));

                return theme;
            });
    };
}
