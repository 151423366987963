import React from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import { appColors } from "../../shared/styles";
import { orderTrackingStyles } from "./OrderTrackingStyles";

export default function CustomerInformations(props) {
    const { customer } = props;
    return (
        <View style={orderTrackingStyles.customerInformations}>
            <View>
                <Text h2 h2Style={{ color: props.textColor }}>
                    Kundendaten
                </Text>
            </View>
            <View>
                <Text h4>Adresse</Text>
                <Text>{customer.fullName}</Text>
                {customer.company && <Text>{customer.company}</Text>}
                <Text>
                    {customer.txtStreet} {customer.housenumber}
                </Text>
                <Text>
                    {customer.txtZipCode} {customer.txtCity}
                </Text>
                <Text>{customer.email}</Text>
                {customer.phoneNumbers && <Text>{customer.phoneNumbers}</Text>}
            </View>
            {customer.useDifferentBillingAddress && (
                <View style={orderTrackingStyles.billingAddress}>
                    <Text h4>Rechnungsadresse</Text>
                    <Text>{customer.billingFullName}</Text>
                    {customer.billingCompany && <Text>{customer.billingCompany}</Text>}
                    <Text>
                        {customer.billingStreet} {customer.billingHousenumber}
                    </Text>
                    <Text>
                        {customer.billingZipcode} {customer.billingCity}
                    </Text>
                </View>
            )}
        </View>
    );
}
