import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

import { customerStyle } from "./CustomerStyle";
import { store } from "../../store/store";
import { Dimensions, Pressable, StyleSheet, View } from "react-native";
import { getContrastColor } from "../../shared/helpers";
import { Avatar, Card, Text } from "react-native-elements";
import CustomTooltipIconButton from "../../helpers/IconButton/CustomIconButton";

/**
 * for Icons visit: https://material.io/resources/icons/
 */

class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }
    componentDidMount() {
        // store.dispatch(setOnlineShopItemType(-2));
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    render() {
        const { theme } = this.props;
        const { windowWidth } = this.state;
        return (
            <React.Fragment>
                <View style={{ display: "flex", flexDirection: "row", zIndex: 10 }}>
                    <CustomTooltipIconButton
                        icon="arrow-left"
                        tooltipText="Zurück"
                        iconColor={this.props.settings.store.accentColor}
                        onButtonClick={() => this.props.history.push("/")}
                        containerStyle={{ marginLeft: 25, marginTop: 5 }}
                    />
                    <Text style={{ marginTop: 10, marginLeft: 25, marginBottom: 25 }}>
                        {"Hallo " + this.props.account.customer.firstName}
                    </Text>
                </View>

                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        maxWidth: "1280px",
                        width: "100%",
                        marginLeft: windowWidth <= 1024 ? "auto" : 15,
                        marginRight: windowWidth <= 1024 ? "auto" : 15,
                    }}
                >
                    <Card
                        containerStyle={{
                            ...StyleSheet.flatten(customerStyle.card),
                            backgroundColor: this.props.settings.store.surfaceColor,
                            border: "1px solid " + this.props.settings.store.accentColor,
                            width:
                                windowWidth <= 376
                                    ? "calc(100% - 10px)"
                                    : windowWidth <= 1024
                                    ? "calc( (100% / 3) - 10px)"
                                    : "calc( (100% / 4) - 10px)",
                            zIndex: 10,
                        }}
                    >
                        <Pressable
                            onPress={() => this.props.history.push("/customer/addresses/")}
                            style={customerStyle.cardTouchable}
                        >
                            <Card.Title
                                style={{
                                    color: getContrastColor(this.props.settings.store.surfaceColor),
                                    marginBottom: 25,
                                }}
                            >
                                Adressen
                            </Card.Title>

                            <View style={customerStyle.cardInnerContainer}>
                                <Avatar
                                    size="small"
                                    rounded
                                    icon={{
                                        name: "home",
                                        type: "font-awesome",
                                        color: getContrastColor(this.props.settings.store.primaryColor),
                                    }}
                                    containerStyle={{
                                        backgroundColor: this.props.settings.store.primaryColor,
                                    }}
                                />

                                <Text
                                    style={{
                                        marginTop: 25,
                                        color: getContrastColor(this.props.settings.store.surfaceColor),
                                    }}
                                >
                                    Adressen verwalten
                                </Text>
                            </View>
                        </Pressable>
                    </Card>

                    <Card
                        containerStyle={{
                            ...StyleSheet.flatten(customerStyle.card),
                            backgroundColor: this.props.settings.store.surfaceColor,
                            border: "1px solid " + this.props.settings.store.accentColor,
                            width:
                                windowWidth <= 376
                                    ? "calc(100% - 10px)"
                                    : windowWidth <= 1024
                                    ? "calc( (100% / 3) - 10px)"
                                    : "calc( (100% / 4) - 10px)",
                        }}
                    >
                        <Pressable
                            onPress={() => this.props.history.push("/customer/profile/")}
                            style={customerStyle.cardTouchable}
                        >
                            <Card.Title
                                style={{
                                    color: getContrastColor(this.props.settings.store.surfaceColor),
                                    marginBottom: 25,
                                }}
                            >
                                Kundendaten
                            </Card.Title>

                            <View style={customerStyle.cardInnerContainer}>
                                <Avatar
                                    size="small"
                                    rounded
                                    icon={{
                                        name: "edit",
                                        color: getContrastColor(this.props.settings.store.primaryColor),
                                    }}
                                    containerStyle={{
                                        backgroundColor: this.props.settings.store.primaryColor,
                                    }}
                                />

                                <Text
                                    style={{
                                        marginTop: 25,
                                        color: getContrastColor(this.props.settings.store.surfaceColor),
                                    }}
                                >
                                    Daten verwalten
                                </Text>
                            </View>
                        </Pressable>
                    </Card>
                </View>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, theme } = state;
    return { settings, account, theme };
}

export default connect(mapStateToProps)(withRouter(Customer));
