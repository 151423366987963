import { getIngredientAticle } from "../store/actions/weeklyPlanActions";
import { store } from "../store/store";

export function getArticlesFromWeeklyPlan(weeklyPlan = null) {
    if (weeklyPlan !== null) {
        let weeklyPlanData = getWeeklyPlanData(weeklyPlan);
        let i = 0;
        let result = [];

        while (weeklyPlanData.validDays[i]) {
            let day = weeklyPlanData.validDays[i];
            let weeklyPlanEntriesForDay = getWeeklyPlanEntriesForDay(day, weeklyPlanData.weeklyPlanEntries);
            if (weeklyPlanEntriesForDay) {
                let j = 0;
                while (weeklyPlanData.weeklyPlanComponents[j]) {
                    let weeklyPlanComponent = weeklyPlanData.weeklyPlanComponents[j];
                    let hasEntry = hasValidEntries(weeklyPlanComponent.weeklyPlanComponentsId, weeklyPlanEntriesForDay);
                    if (hasEntry) {
                        let entries = getWeeklyPlanEnrtiesForWeeklyPlanComponent(
                            weeklyPlanComponent.weeklyPlanComponentsId,
                            weeklyPlanEntriesForDay
                        );
                        let k = 0;
                        while (entries[k]) {
                            let entry = entries[k];
                            if (hasSizeAndPrice(entry.Article)) {
                                entry.weeklyPlanComponent = weeklyPlanComponent;
                                result.push(entry);
                            }

                            k++;
                        }
                    }
                    j++;
                }
            }

            i++;
        }
        return result;
    } else {
        return;
    }
}

export async function getEntriesIngredients(entries) {
    let i = 0;
    let result = [];

    while (entries[i]) {
        const entry = entries[i];
        await store.dispatch(getIngredientAticle(entry.Article.articlesId)).then((response) => {
            if (response.length > 0) {
                let j = 0;
                while (response[j]) {
                    let article = response[j].IngredientArticle;
                    const existingEntry = result.find((element) => element.articlesId === article.articlesId);
                    if (hasSizeAndPrice(article) && !existingEntry) {
                        result.push({
                            Article: article,
                            articlesId: article.articlesId,
                            weeklyPlanComponentsId: entry.weeklyPlanComponent.weeklyPlanComponentsId,
                            weeklyPlanEntriesId: entry.weeklyPlanEntriesId,
                            validOnDay: entry.validOnDay,
                            amount: 0,
                            weeklyPlanComponent: entry.weeklyPlanComponent,
                        });
                    }

                    j++;
                }
            }
        });

        i++;
    }

    return result;
}

function getWeeklyPlanData(weeklyPlan) {
    let result = {
        weeklyPlanComponents: [],
        weeklyPlanEntries: [],
        validDays: [],
    };
    result.validDays = weeklyPlan.validOnDays.toString().split("");
    for (let i = 0; i < weeklyPlan.WeeklyPlanComponents.length; i++) {
        const weeklyPlanComponent = weeklyPlan.WeeklyPlanComponents[i];
        result.weeklyPlanComponents.push(weeklyPlanComponent);

        for (let j = 0; j < weeklyPlanComponent.WeeklyPlanEntries.length; j++) {
            const weeklyPlanEntry = weeklyPlanComponent.WeeklyPlanEntries[j];
            result.weeklyPlanEntries.push(weeklyPlanEntry);
        }
    }

    return result;
}

function getWeeklyPlanEntriesForDay(day, weeklyPlanEntries) {
    const result = [];
    const currentDay = parseInt(day);
    for (let i = 0; i < weeklyPlanEntries.length; i++) {
        const weeklyPlanEntry = weeklyPlanEntries[i];
        if (weeklyPlanEntry.validOnDay === currentDay) {
            result.push(weeklyPlanEntry);
        }
    }
    return result;
}

function getWeeklyPlanEnrtiesForWeeklyPlanComponent(weeklyPlanComponentsId, weeklyPlanEntries) {
    const result = [];
    for (let i = 0; i < weeklyPlanEntries.length; i++) {
        const weeklyPlanEntry = weeklyPlanEntries[i];

        if (weeklyPlanEntry.weeklyPlanComponentsId === weeklyPlanComponentsId) {
            result.push(weeklyPlanEntry);
        }
    }

    return result;
}

function hasValidEntries(weeklyPlanComponentsId, weeklyPlanEntries) {
    let hasEntry = false;
    const entries = getWeeklyPlanEnrtiesForWeeklyPlanComponent(weeklyPlanComponentsId, weeklyPlanEntries);
    let i = 0;

    while (entries[i]) {
        const entryArticleSizes = entries[i].Article.ArticleSizes.filter((articleSize) => {
            if (!articleSize.Size.isDefault) {
                return true;
            }
            return false;
        });

        if (entryArticleSizes.length > 0) {
            hasEntry = true;
        }
        i++;
    }

    return hasEntry;
}

function hasSizeAndPrice(article) {
    let result = false;
    if (article.ArticleSizes.length > 0) {
        let i = 0;
        while (i <= article.ArticleSizes.length - 1) {
            const articleSize = article.ArticleSizes[i];
            if (articleSize.Prices.length > 0) {
                result = true;
                break;
            }

            i++;
        }
    }
    return result;
}
