import { SET_ORDERTYPE, SET_ORDERTYPE_BY_ID, SET_CUSTOMER, SET_CUSTOMERCOURSES } from "../actions/accountActions";

const initialState = {
    orderTypes: [
        {
            id: 3,
            name: "Restaurant",
            isDelivery: false,
            bookImmediately: false,
        },
    ],
    orderType: {
        id: 3,
        name: "Restaurant",
        isDelivery: false,
        bookImmediately: false,
    },
    customer: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDERTYPE:
            return Object.assign({}, state, {
                orderType: action.orderType,
            });
        case SET_ORDERTYPE_BY_ID:
            let newState = Object.assign({}, state);
            let orderTypeIndex = newState.orderTypes.findIndex((x) => {
                if (x) {
                    return x.id === parseInt(action.orderTypeId);
                } else {
                    return false;
                }
            });
            if (newState.orderTypes[orderTypeIndex]) {
                newState.orderType = newState.orderTypes[orderTypeIndex];
            }
            return newState;
        case SET_CUSTOMER:
            if (!action.customer || (action.customer && action.customer.customerUid)) {
                let newState = { ...state, customer: action.customer };

                return newState;
            }
        case SET_CUSTOMERCOURSES:
            newState = { ...state, customer: { ...state.customer, customerCourses: action.customerCourses } };
            return newState;
        default:
            return state;
    }
};
