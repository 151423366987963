import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

import {
    deleteAddress,
    updateStandardBillingAddress,
    updateStandardDeliveryAddress,
} from "../../store/actions/accountActions";
import { customerStyle } from "./CustomerStyle";
import { store } from "../../store/store";
import AddAddressPopup from "./AddAddressPopup";
import ConfirmDeletePopup from "./ConfirmDeletePopup";
import { Dimensions, Pressable, StyleSheet, View } from "react-native";
import { getContrastColor } from "../../shared/helpers";
import { Avatar, Card, Text } from "react-native-elements";
import CustomTooltipIconButton from "../../helpers/IconButton/CustomIconButton";
import CustomOverlay from "../../helpers/Overlay/CustomOverlay";

class CustomerAddresses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
            showAddAddressPopup: false,
            test: false,
            additionalAddressToEdit: 0,
            edit: false,
            showConfirmDeletePopup: false,
            addressToDelete: undefined,
            indexToDelete: 0,
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        };

        this.toggleAddAddressPopup = this.toggleAddAddressPopup.bind(this);
        this.toggleConfirmDeletePopup = this.toggleConfirmDeletePopup.bind(this);
        this.editAddress = this.editAddress.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        const { customer } = this.props.account;
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    toggleAddAddressPopup() {
        if (this.state.edit && this.state.showAddAddressPopup) this.setState({ edit: false });
        document.body.style.overflow = !this.state.showAddAddressPopup ? "hidden" : "auto";
        this.setState({ showAddAddressPopup: !this.state.showAddAddressPopup });
    }

    toggleConfirmDeletePopup() {
        if (this.state.edit && this.state.showConfirmDeletePopup) this.setState({ edit: false });
        document.body.style.overflow = !this.state.showConfirmDeletePopup ? "hidden" : "auto";
        this.setState({ showConfirmDeletePopup: !this.state.showConfirmDeletePopup });
    }

    deleteAddress() {
        let address = this.state.addressToDelete;
        const { customer } = this.props.account;
        toast.show("Adresse wird gelöscht...", { type: "info" });
        store
            .dispatch(
                deleteAddress(
                    this.props.account.customer.customerUid,
                    this.state.indexToDelete,
                    address.Street.City.name,
                    address.Street.name,
                    address.housenumber,
                    address.Street.City.zipCode,
                    address.addressUid
                )
            )
            .then(() => {
                this.props.update(customer.azureId);
                toast.show("Adresse gelöscht", { type: "success" });
            });
    }

    editAddress(address) {
        const { customer } = this.props.account;
        this.setState(
            {
                additionalAddressToEdit: address,
                indexOfAddressToEdit: customer.addresses.indexOf(address),
                edit: true,
            },
            () => {
                this.toggleAddAddressPopup();
            }
        );
    }

    changeDefaultBillingAddress(address) {
        const { customer } = this.props.account;
        toast.show("Standardrechnungsadresse wird geändert...", { type: "info" });
        store
            .dispatch(
                updateStandardBillingAddress(
                    this.props.account.customer.customerUid,
                    customer.addresses.indexOf(address)
                )
            )
            .then(() => {
                this.props.update(customer.azureId);
                toast.show("Standardrechnungsadresse geändert", { type: "success" });
            });
    }

    changeDefaultDeliveryAddress(address) {
        const { customer } = this.props.account;
        toast.show("Standardlieferadresse wird geändert...", { type: "info" });
        store
            .dispatch(
                updateStandardDeliveryAddress(
                    this.props.account.customer.customerUid,
                    customer.addresses.indexOf(address)
                )
            )
            .then(() => {
                this.props.update(customer.azureId);
                toast.show("Standardlieferadresse geändert", { type: "success" });
            });
    }

    render() {
        const { customer } = this.props.account;
        const { theme } = this.props;
        const { windowWidth } = this.state;

        return (
            <React.Fragment>
                <View style={{ display: "flex", flexDirection: "row", zIndex: 10 }}>
                    <CustomTooltipIconButton
                        icon="arrow-left"
                        tooltipText="Zurück"
                        iconColor={this.props.settings.store.accentColor}
                        onButtonClick={() => this.props.history.push("/customer/")}
                        containerStyle={{ marginLeft: 25, marginTop: 5 }}
                    />
                    <Text style={{ marginTop: 10, marginLeft: 25, marginBottom: 25 }}>Adressverwaltung</Text>
                </View>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        maxWidth: "1280px",
                        width: "100%",
                        marginLeft: windowWidth <= 1024 ? "auto" : 15,
                        marginRight: windowWidth <= 1024 ? "auto" : 15,
                    }}
                >
                    {customer.addresses &&
                        customer.addresses.map((address, index) => {
                            return (
                                <Card
                                    key={address.addressId}
                                    containerStyle={{
                                        ...StyleSheet.flatten(customerStyle.card),
                                        backgroundColor: this.props.settings.store.surfaceColor,
                                        border: "1px solid " + this.props.settings.store.accentColor,
                                        cursor: "default",
                                        marginBottom: 25,
                                        width:
                                            windowWidth <= 376
                                                ? "calc(100% - 10px)"
                                                : windowWidth <= 1024
                                                ? "calc( (100% / 3) - 10px)"
                                                : "calc( (100% / 4) - 10px)",
                                    }}
                                    wrapperStyle={{
                                        ...StyleSheet.flatten(customerStyle.addressCardWrapper),
                                    }}
                                >
                                    <Card.Title
                                        style={{
                                            color: getContrastColor(this.props.settings.store.surfaceColor),
                                            marginBottom: 25,
                                        }}
                                    >
                                        {address.firstName + " " + address.lastName}
                                    </Card.Title>
                                    <View style={customerStyle.addressCardInnerContainer}>
                                        <Text>
                                            {address.Street.name +
                                                " " +
                                                address.housenumber +
                                                "\n" +
                                                address.Street.City.zipCode +
                                                " " +
                                                address.Street.City.name +
                                                "\n\n" +
                                                (address.company ? address.company : "") +
                                                "\n" +
                                                (address.addressDetails ? address.addressDetails : "")}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            justifyContent: "center",
                                            alignItems: "flex-end",
                                            flexDirection: "row",
                                            marginTop: 15,
                                            marginBottom: 15,
                                            width: "100%",
                                        }}
                                    >
                                        <CustomTooltipIconButton
                                            icon="pencil"
                                            iconColor={getContrastColor(this.props.settings.store.primaryColor)}
                                            tooltipText="Adresse bearbeiten"
                                            onButtonClick={() => this.editAddress(address)}
                                            toolTipPosition="top"
                                            containerStyle={{
                                                backgroundColor: this.props.settings.store.primaryColor,
                                                borderRadius: 7,
                                                marginRight: 15,
                                            }}
                                        />

                                        <CustomTooltipIconButton
                                            icon="file"
                                            tooltipText="als Standardrechnungsadresse wählen"
                                            iconColor={
                                                address.isDefaultBillingAddress
                                                    ? getContrastColor(this.props.settings.store.accentColor)
                                                    : getContrastColor(this.props.settings.store.primaryColor)
                                            }
                                            onButtonClick={() => this.changeDefaultBillingAddress(address)}
                                            toolTipPosition="top"
                                            containerStyle={{
                                                backgroundColor: address.isDefaultBillingAddress
                                                    ? this.props.settings.store.accentColor
                                                    : this.props.settings.store.primaryColor,
                                                borderRadius: 7,
                                                marginRight: 15,
                                            }}
                                        />
                                        <CustomTooltipIconButton
                                            icon="truck"
                                            tooltipText="als Standardlieferadresse wählen"
                                            iconColor={
                                                address.isDefaultDeliveryAddress
                                                    ? getContrastColor(this.props.settings.store.accentColor)
                                                    : getContrastColor(this.props.settings.store.primaryColor)
                                            }
                                            onButtonClick={() => this.changeDefaultDeliveryAddress(address)}
                                            toolTipPosition="top"
                                            containerStyle={{
                                                backgroundColor: address.isDefaultDeliveryAddress
                                                    ? this.props.settings.store.accentColor
                                                    : this.props.settings.store.primaryColor,
                                                borderRadius: 7,
                                                marginRight: 15,
                                            }}
                                        />
                                        <CustomTooltipIconButton
                                            icon="trash"
                                            tooltipText="Adresse löschen"
                                            iconColor={getContrastColor(this.props.settings.store.primaryColor)}
                                            onButtonClick={() => {
                                                if (customer.addresses.length === 1) {
                                                    toast.show("Sie müssen mindestens eine Adresse angeben", {
                                                        type: "danger",
                                                    });
                                                    return;
                                                } else {
                                                    this.setState(
                                                        {
                                                            addressToDelete: address,
                                                            indexToDelete: customer.addresses.indexOf(address),
                                                        },
                                                        () => this.toggleConfirmDeletePopup()
                                                    );
                                                }
                                            }}
                                            toolTipPosition="top"
                                            containerStyle={{
                                                backgroundColor: this.props.settings.store.primaryColor,
                                                borderRadius: 7,
                                                marginRight: 0,
                                            }}
                                        />
                                    </View>
                                </Card>
                            );
                        })}
                    <Card
                        containerStyle={{
                            ...StyleSheet.flatten(customerStyle.card),
                            backgroundColor: this.props.settings.store.surfaceColor,
                            border: "1px dashed " + this.props.settings.store.accentColor,
                            marginBottom: 25,
                            width:
                                windowWidth <= 376
                                    ? "calc(100% - 10px)"
                                    : windowWidth <= 1024
                                    ? "calc( (100% / 3) - 10px)"
                                    : "calc( (100% / 4) - 10px)",
                        }}
                    >
                        <Pressable onPress={() => this.toggleAddAddressPopup()} style={customerStyle.cardTouchable}>
                            <View style={customerStyle.cardInnerContainer}>
                                <Avatar
                                    size="small"
                                    rounded
                                    icon={{
                                        name: "add",
                                        color: getContrastColor(this.props.settings.store.primaryColor),
                                    }}
                                    containerStyle={{
                                        backgroundColor: this.props.settings.store.primaryColor,
                                    }}
                                />

                                <Text
                                    style={{
                                        marginTop: 25,
                                        color: getContrastColor(this.props.settings.store.surfaceColor),
                                    }}
                                >
                                    Adresse hinzufügen
                                </Text>
                            </View>
                        </Pressable>
                    </Card>
                </View>

                {this.state.showAddAddressPopup && (
                    <CustomOverlay
                        onHide={this.toggleConfirmDeletePopup}
                        windowWidth={windowWidth}
                        extraPadding={false}
                    >
                        <AddAddressPopup
                            visible={this.state.showAddAddressPopup}
                            togglePopup={this.toggleAddAddressPopup}
                            numberOfAddressesSaved={customer.addresses.length}
                            addressIndex={this.state.indexOfAddressToEdit}
                            addressToEdit={this.state.additionalAddressToEdit}
                            edit={this.state.edit}
                            theme={theme}
                            update={() => this.props.update(customer.azureId)}
                        />
                    </CustomOverlay>
                )}
                {this.state.showConfirmDeletePopup && (
                    <CustomOverlay
                        onHide={this.toggleConfirmDeletePopup}
                        windowWidth={windowWidth}
                        extraPadding={false}
                    >
                        <ConfirmDeletePopup
                            visible={this.state.showConfirmDeletePopup}
                            togglePopup={this.toggleConfirmDeletePopup}
                            theme={theme}
                            delete={() => this.deleteAddress()}
                        />
                    </CustomOverlay>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, theme } = state;
    return { settings, account, theme };
}

export default connect(mapStateToProps)(withRouter(CustomerAddresses));
