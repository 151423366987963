const tenant = "ggbios.onmicrosoft.com"; //Domain des B2C Mandanten.

export const b2cPolicies = {
    name: {
        signUpSignIn: "B2C_1_ggbios", //Name des Benutzerflows für Anmeldung und Registrierung
        forgotPassword: "B2C_1_PasswortReset", //Name des benutzerflows für Kennwortzurücksetzung
    },
    authorities: {
        signUpSignIn: {
            authority: "https://ggbios.b2clogin.com/ggbios.onmicrosoft.com/B2C_1_ggbios", // "https://B2CMANDANTENNAME.b2clogin/B2CMANDANTENDOMAIN/REGISTRIERUNGSUSERFLOWNAME
            clientId: "63113f0d-3b76-4b00-b6ba-e08c81599dd3", //Anwendungs-ID (Client) der onlineshop App Registrierung in B2C
        },
    },
    authorityDomain: "ggbios.b2clogin.com", //B2CMANDANTENNAME.b2clogin.com
};
export const config = {
    auth: {
        clientId: "", //Anwendungs-ID (Client) der onlineshop App Registrierung in B2C
        redirectUri: window.location.protocol + "//" + window.location.host, //Redirect URL  der App Registrierung in B2C
        authority: "",
        knownAuthorities: [],
        scopes: ["openid", "profile"],
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};
