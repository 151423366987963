import React, { Component } from "react";
import { View, Animated, Pressable } from "react-native";
import { Header, Icon, Text } from "react-native-elements";
import { appColors, styles } from "../../shared/styles";
import Cart from "./Cart";
import CartTotal from "./CartTotal";
import { connect } from "react-redux";
import { withRouter } from "../../routers/routing";
import { getContrastColor } from "../../shared/helpers";

class CartSidebarMobile extends Component {
    constructor(props) {
        super(props);

        this.widthValue = new Animated.Value(0);

        this.slideIn = this.slideIn.bind(this);
        this.slideOut = this.slideOut.bind(this);
    }

    componentDidUpdate() {
        const { cartSidebarOpen } = this.props;

        if (cartSidebarOpen) {
            this.slideIn();
        } else {
            this.slideOut();
        }
    }

    slideIn() {
        Animated.timing(this.widthValue, {
            toValue: 1,
            duration: 300,
            useNativeDriver: false,
        }).start();
    }

    slideOut() {
        Animated.timing(this.widthValue, {
            toValue: 0,
            duration: 300,
            useNativeDriver: false,
        }).start();
    }

    handleCloseSidebar = () => {
        this.props.toggleCartSidebar(false);
    };

    render() {
        const { cartSidebarOpen, settings } = this.props;
        const width = this.widthValue.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 200],
        });
        return (
            <Animated.View
                style={{
                    backgroundColor: appColors.grey5,
                    width: width,
                    height: "100vh",
                    zIndex: 1,
                    overflow: "hidden",
                    position: "fixed",
                    right: 0,
                }}
            >
                {cartSidebarOpen && (
                    <View>
                        <Pressable
                            onPress={this.handleCloseSidebar}
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                backgroundColor: settings.store.primaryColor,
                                paddingBottom: 10,
                                paddingTop: 10,
                                paddingRight: 15,
                                paddingLeft: 15,
                            }}
                        >
                            <Text style={{ marginRight: 8, color: getContrastColor(settings.store.primaryColor) }}>
                                Schließen
                            </Text>
                            <Icon
                                name="close"
                                type="font-awesome"
                                size={20}
                                color={getContrastColor(settings.store.primaryColor)}
                            />
                        </Pressable>
                        <Header
                            placement="left"
                            backgroundColor="#333"
                            centerComponent={{
                                text: (
                                    <Text
                                        h2
                                        h2Style={{
                                            color: appColors.white,
                                            marginBottom: 0,
                                            lineHeight: "100%",
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                        }}
                                    >
                                        Warenkorb
                                    </Text>
                                ),
                            }}
                            leftComponent={<Icon name="shopping-cart" type="font-awesome" size={20} color="#fff" />}
                            rightComponent={<CartTotal />}
                        />
                        <View style={styles.contentContainer}>
                            <Cart showCustomerData={this.props.showCustomerData} />
                        </View>
                    </View>
                )}
            </Animated.View>
        );
    }
}

function mapStateToProps(state) {
    const { settings } = state;
    return { settings };
}

export default connect(mapStateToProps)(withRouter(CartSidebarMobile));
