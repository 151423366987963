import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import WebModal from "modal-react-native-web";
import moment from "moment";
import React, { Component } from "react";
import { Dimensions, Modal, Platform, Pressable, ScrollView, StyleSheet, View } from "react-native";
import { Icon, Overlay, ThemeProvider } from "react-native-elements";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { ToastProvider } from "react-native-toast-notifications";
import Toast from "react-native-toast-notifications";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { config } from "./src/AzureConfig";
import SiteHeader from "./src/layouts/header/Header";
import { PrivateRoute } from "./src/routers/PrivateRoute";
import { Route, Router, Switch } from "./src/routers/routing";
import { CartScreen, CartSidebar } from "./src/screens/Cart";
import CartSidebarMobile from "./src/screens/Cart/CartSidebarMobile";
import CustomerData from "./src/screens/CustomerData";
import Customer from "./src/screens/customerScreens/Customer";
import CustomerAddresses from "./src/screens/customerScreens/CustomerAddresses";
import CustomerProfile from "./src/screens/customerScreens/CustomerProfile";
import DataProtection from "./src/screens/Impressum/dataProtection";
import Impressum from "./src/screens/Impressum/impressum";
import OrderTracking from "./src/screens/OrderTracking/OrderTracking";
import WeeklyPlanScreen from "./src/screens/WeeklyPlan";
import ZipCode from "./src/screens/ZipCode/ZipCode";
import Footer from "./src/shared/footer";
import { appColors, styles } from "./src/shared/styles";
import { getCustomer, setCustomer } from "./src/store/actions/accountActions";
import { stop } from "./src/store/actions/orderStatusActions";
import { getSettings } from "./src/store/actions/settingsActions";
import { getShopTheme } from "./src/store/actions/themeActions";
import {
    getZipCodes,
    SET_NEXT_DELIVERY_DATE,
    SET_SELECTED_INDEX,
    SET_ZIPCODE,
    SET_ZIPCODEAREA,
} from "./src/store/actions/zipCodeActions";
import { persistor, store } from "./src/store/store";

import "moment/dist/locale/de";

moment.locale("de");

const theme = {
    colors: appColors,
    Text: {
        h1Style: { fontSize: 22, fontWeight: "bold", marginBottom: 15 },
        h2Style: { fontSize: 18, fontWeight: "bold", marginBottom: 15 },
        h3Style: { fontSize: 14, fontWeight: "bold", marginBottom: 15 },
        h4Style: { fontSize: 14, fontWeight: "bold", marginBottom: 0 },
        style: {
            fontFamily: "regular",
            fontSize: 14,
        },
    },
    Input: {
        containerStyle: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        inputStyle: {
            fontSize: 16,
        },
    },
    Card: {
        containerStyle: {
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: appColors.grey0,
            padding: 0,
            border: "none",
        },
    },
    Button: {
        titleStyle: {
            fontSize: 15,
        },
    },
};

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customerDataVisible: false,
            zipCodeVisible: true,
            orderTrackingVisible: false,
            windowWidth: Dimensions.get("window").width,
            zipCodesAreas: [],
            weeklyPlanTitle: "",
            cartSidebarOpen: false,
            settingsLoaded: false,
            customerUid: undefined,
            redirectCounter: 10,
            isAuthenticated: false,
            error: "",
            user: {},
            mobileMenuOpen: false,
            cartOpen: false,
            customerUid: "",
            paymentLink: "",
        };

        this.mainZip = "66606";

        this.showCustomerData = this.showCustomerData.bind(this);
        this.hideCustomerData = this.hideCustomerData.bind(this);
        this.showZipCode = this.showZipCode.bind(this);
        this.hideZipCode = this.hideZipCode.bind(this);
        this.showOrderTracking = this.showOrderTracking.bind(this);
        this.hideOrderTracking = this.hideOrderTracking.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);

        this.getZipCodeArea = this.getZipCodeArea.bind(this);
        this.calculateNextDeliveryDate = this.calculateNextDeliveryDate.bind(this);

        /**
         * Account
         */
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.updateCustomer = this.updateCustomer.bind(this);
        this.publicClientApplication = new PublicClientApplication(config);
    }

    async updateCustomer(azureId) {
        store.dispatch(getCustomer(azureId)).then((response) => {
            let customer = {
                customerUid: response.customerUid,
                azureId: response.azureId,
                firstName: response.firstName,
                familyName: response.familyName,
                addresses: JSON.parse(response.addresses),
                zipCode: response.zipCode,
                title: response.title,
                email: response.email,
                houseNumber: response.houseNumber,
                phoneNumber: response.phonenumber,
                customerCourses: JSON.parse(response.customerCourses),
            };
            store.dispatch(setCustomer(customer));
        });
    }

    async login() {
        try {
            await this.publicClientApplication
                .loginPopup({
                    scopes: config.scopes,
                    prompt: "select_account",
                })
                .then((response) => {
                    this.updateCustomer(response.uniqueId);
                    this.setState({ customerUid: response.uniqueId });
                    localStorage.setItem("customersUid", response.uniqueId);
                });
        } catch (err) {
            this.setState({ isAuthenticated: false, user: {}, error: err });
        }
    }

    logout() {
        store.dispatch(setCustomer(undefined));
        localStorage.removeItem("customersUid");
        this.publicClientApplication.logout();
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
        // store.dispatch(getSettings());
        store.dispatch(getSettings()).then((res) => {
            // this.setState({ loading: false, theme: res });
            this.setState({ settingsLoaded: true });
            this.publicClientApplication = new PublicClientApplication(config);
        });
        // store.dispatch(getShopTheme());

        /**
         * Patch for Mensashop
         * The zipCode is fixed here so that the user does not have to enter it manually.
         * Todo: Make zipCode configurable via backend
         */

        store.dispatch(getZipCodes()).then((result) => {
            this.setState(
                {
                    zipCodesAreas: result,
                },
                () => {
                    var zipCodeArea = this.getZipCodeArea(this.mainZip);
                    this.calculateNextDeliveryDate(zipCodeArea);
                    store.dispatch({ type: SET_ZIPCODEAREA, zipCodeArea: zipCodeArea });
                    store.dispatch({ type: SET_ZIPCODE, zipCode: this.mainZip });
                    this.hideZipCode();
                }
            );
        });
    }

    getZipCodeArea(zipCode) {
        var result;
        for (var i = 0; i < this.state.zipCodesAreas.length; i++) {
            if (this.state.zipCodesAreas[i].zipCodes.find((code) => code === zipCode)) {
                result = this.state.zipCodesAreas[i];
                break;
            }
        }
        return result;
    }

    calculateNextDeliveryDate(zipCodeArea) {
        var sortedDeliveryDays = zipCodeArea.deliveryDays.sort();
        var currentDay = new moment().day();
        var nextDeliveryDay;
        var selectedIndex = 0;
        for (var i = 0; i < sortedDeliveryDays.length; i++) {
            if (parseInt(sortedDeliveryDays[i]) + 1 >= currentDay) {
                nextDeliveryDay = parseInt(sortedDeliveryDays[i]) + 1;
                selectedIndex = i;
                break;
            }
        }
        var nextDeliveryDate;
        if (nextDeliveryDay) {
            nextDeliveryDate = new moment().add(nextDeliveryDay - currentDay, "days");
        } else {
            nextDeliveryDate = new moment().add(7 + parseInt(sortedDeliveryDays[0] + 1) - currentDay, "days");
        }
        store.dispatch({ type: SET_SELECTED_INDEX, selectedIndex: selectedIndex });
        store.dispatch({
            type: SET_NEXT_DELIVERY_DATE,
            nextDeliveryDate: nextDeliveryDate.format("YYYY-MM-DD"),
            forceUpdate: true,
        });
    }

    componentWillUnmount() {
        Dimensions.removeEventListener("change", this.updateDimensions);
    }

    updateWeeklyPlanTitle = (newTitle) => {
        this.setState({ weeklyPlanTitle: newTitle });
    };

    toggleCustomerData() {
        var visibility = !this.state.customerDataOpen;
        document.body.style.overflow = visibility ? "hidden" : "auto";
        this.setState({
            customerDataOpen: visibility,
            portalVisible: visibility,
            cartOpen: false,
        });
    }

    showCustomerData() {
        this.setState({
            customerDataVisible: true,
        });
    }

    hideCustomerData() {
        this.setState({
            customerDataVisible: false,
        });
    }

    showZipCode() {
        this.setState({
            zipCodeVisible: true,
        });
    }

    hideZipCode() {
        this.setState({
            zipCodeVisible: false,
        });
    }

    showZipCode() {
        this.setState({
            zipCodeVisible: true,
        });
    }

    showOrderTracking(id = null) {
        this.setState({
            orderTrackingVisible: true,
            orderTrackingId: id,
        });
    }

    hideOrderTracking() {
        this.setState(
            {
                orderTrackingVisible: false,
            },
            () => {
                store.dispatch(stop());
            }
        );
    }

    updateDimensions() {
        this.setState({ windowWidth: Dimensions.get("window").width });
    }

    toggleCartSidebar = () => {
        this.setState((prevState) => ({
            cartSidebarOpen: !prevState.cartSidebarOpen,
        }));
    };

    render() {
        const { cartSidebarOpen } = this.state;
        return (
            <SafeAreaProvider>
                <Provider store={store}>
                    <ToastProvider>
                        <Toast ref={(ref) => (global["toast"] = ref)} />
                        <ThemeProvider theme={theme}>
                            <MsalProvider instance={this.publicClientApplication}>
                                <PersistGate loading={null} persistor={persistor}>
                                    <Router>
                                        <React.Fragment>
                                            <View style={styles.mainContainer}>
                                                <View style={styles.screenContainer}>
                                                    {this.state.settingsLoaded && (
                                                        <React.Fragment>
                                                            <SiteHeader
                                                                weeklyPlanTitle={this.state.weeklyPlanTitle}
                                                                toggleCartSidebar={this.toggleCartSidebar}
                                                                toggleLoginPopup={this.login}
                                                                logout={this.logout}
                                                            />
                                                            <Switch>
                                                                <Route
                                                                    path="/"
                                                                    exact
                                                                    render={() => (
                                                                        <WeeklyPlanScreen
                                                                            orderTrackingVisible={false}
                                                                            articleDetailsVisible={false}
                                                                            showOrderTracking={this.showOrderTracking}
                                                                            showZipCode={this.showZipCode}
                                                                            showArticleDetails={this.showArticleDetails}
                                                                            updateWeeklyPlanTitle={
                                                                                this.updateWeeklyPlanTitle
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                                <Route
                                                                    path="/orderTracking/:id?/:paymentSuccessfull?/:returnString?/"
                                                                    exact
                                                                    render={(props) => <OrderTracking {...props} />}
                                                                />

                                                                <Route
                                                                    path="/cart"
                                                                    render={() => (
                                                                        <CartScreen
                                                                            showCustomerData={this.showCustomerData}
                                                                        />
                                                                    )}
                                                                />
                                                                <PrivateRoute
                                                                    path="/customer"
                                                                    exact
                                                                    render={() => (
                                                                        <Customer update={this.updateCustomer} />
                                                                    )}
                                                                />
                                                                <PrivateRoute
                                                                    path="/customer/addresses"
                                                                    exact
                                                                    render={() => (
                                                                        <CustomerAddresses
                                                                            update={this.updateCustomer}
                                                                            showBackButton={true}
                                                                        />
                                                                    )}
                                                                />
                                                                <PrivateRoute
                                                                    path="/customer/profile"
                                                                    exact
                                                                    render={() => (
                                                                        <CustomerProfile update={this.updateCustomer} />
                                                                    )}
                                                                />
                                                                <Route
                                                                    path="/impressum"
                                                                    exact
                                                                    render={() => <Impressum />}
                                                                />
                                                                <Route
                                                                    path="/dataprotection"
                                                                    exact
                                                                    render={() => <DataProtection />}
                                                                />
                                                            </Switch>
                                                        </React.Fragment>
                                                    )}
                                                    <View style={{ position: "absolute", bottom: 0, width: "100%" }}>
                                                        <Footer />
                                                    </View>
                                                </View>
                                                {this.state.windowWidth >= 1200 ? (
                                                    <View style={styles.sidebarContainer}>
                                                        <CartSidebar showCustomerData={this.showCustomerData} />
                                                    </View>
                                                ) : (
                                                    <CartSidebarMobile
                                                        showCustomerData={this.showCustomerData}
                                                        cartSidebarOpen={cartSidebarOpen}
                                                        toggleCartSidebar={this.toggleCartSidebar}
                                                    />
                                                )}
                                            </View>
                                        </React.Fragment>

                                        {this.state.customerDataVisible && (
                                            <View style={styles.overlayWrapper}>
                                                <Pressable onPress={this.hideCustomerData} style={styles.closeButton}>
                                                    <Icon name="close" type="material" size={20} color="white" />
                                                </Pressable>
                                                <View
                                                    style={{
                                                        ...StyleSheet.flatten(styles.overlay),
                                                        width: this.state.windowWidth >= 1200 ? "65vw" : "90vw",
                                                    }}
                                                >
                                                    <ScrollView
                                                        style={styles.overlayContent}
                                                        contentContainerStyle={styles.scrollContentContainer}
                                                    >
                                                        <View style={styles.formContainer}>
                                                            <CustomerData
                                                                hide={this.hideCustomerData}
                                                                update={this.updateCustomer}
                                                            />
                                                        </View>
                                                    </ScrollView>
                                                </View>
                                            </View>
                                        )}

                                        <Overlay
                                            overlayStyle={{
                                                padding: 20,
                                            }}
                                            ariaHideApp={false}
                                            ModalComponent={Platform.OS === "web" ? WebModal : Modal}
                                            isVisible={this.state.zipCodeVisible}
                                            onBackdropPress={this.hideZipCode}
                                        >
                                            <ZipCode hideZipCode={this.hideZipCode} showZipCode={this.showZipCode} />
                                        </Overlay>
                                        <Overlay
                                            overlayStyle={{
                                                width: "50%",
                                                padding: 20,
                                                maxWidth: 400,
                                                minWidth: 300,
                                                overflow: "auto",
                                                maxHeight: "100vh",
                                                borderRadius: 10,
                                            }}
                                            ariaHideApp={false}
                                            ModalComponent={Platform.OS === "web" ? WebModal : Modal}
                                            isVisible={this.state.orderTrackingVisible}
                                            onBackdropPress={this.hideOrderTracking}
                                        >
                                            <Icon
                                                name="times"
                                                type="font-awesome-5"
                                                size={20}
                                                color={appColors.grey0}
                                                onPress={this.hideOrderTracking}
                                            />

                                            <OrderTracking
                                                hideOrderTracking={this.hideOrderTracking}
                                                id={this.state.orderTrackingId}
                                            />
                                        </Overlay>
                                    </Router>
                                </PersistGate>
                            </MsalProvider>
                        </ThemeProvider>
                    </ToastProvider>
                </Provider>
            </SafeAreaProvider>
        );
    }
}
