import { connection } from "../signalr/orderStatus";

export const SET_UID = "SET_UID";

export function setUid(uid) {
    return {
        type: SET_UID,
        uid: uid,
    };
}
export const SET_ORDER_STATUS = "SET_ORDER_STATUS";

export function setOrderStatus(data) {
    return {
        type: SET_ORDER_STATUS,
        data: data,
    };
}

export function start(uid) {
    return function (dispatch, getState, invoke) {
        dispatch(setUid(uid));
        return connection.start().then(() => {
            return invoke("Start", uid);
        });
    };
}

export function stop() {
    return function (dispatch, getState, invoke) {
        let state = getState();
        invoke("Stop", String(state.orderStatus.uid)).then(() => connection.stop());
        dispatch(setUid(null));
        dispatch(setOrderStatus(null));
    };
}

export function getOrderStatus(uid) {
    return function (dispatch, getState, invoke) {
        return invoke("GetOrderStatus", uid, uid);
    };
}
