import React, { Component } from "react";
import RenderHTML from "react-native-render-html";
import { ScrollView, View } from "react-native-web";

export default class Impressum extends Component {
    render() {
        let text = `<div>
        <p style={{ textAlign: "center" }}>
            <b>Inhaltlich verantwortlich nach § 5 TMG:</b>
        </p>
        <p style={{ textAlign: "center" }}>Geschäftsführer</p>
        <p style={{ textAlign: "center" }}>John Hüsch</p>
        <p style={{ textAlign: "center" }}>Donauschwabenstraße 6</p>
        <p style={{ textAlign: "center" }}>71332 Waiblingen</p>

        <p style={{ textAlign: "center" }}>Soulkitchen GmbH</p>
        <p style={{ textAlign: "center" }}>RemsPark Waiblingen</p>
        <p style={{ textAlign: "center" }}>Ruhrstraße 5 </p>
        <p style={{ textAlign: "center" }}>71332 Waiblingen</p>

       

        <p style={{ textAlign: "center" }}>Tel: 0160 3661267</p>
        <p style={{ textAlign: "center" }}>
            Sämtliche Inhalte dieser Homepage sind urheberrechtlich geschützt!
            <br />
            Dies gilt insbesondere für Logos, Slogans, Fotos und Zitate.
            <br />
            Neben dem Urheberrechtsschutz sind Logos und Slogans als Markenzeichen beim Deutschen Patent- und
            Markenamt für uns oder unsere Auftraggeber registriert.
        </p>
        <p style={{ textAlign: "center" }}>
            Jegliche Verwendung urheberrechtlich und/oder markenrechtlich geschützter Inhalte wird ohne
            Vorankündigung abgemahnt und zieht neben dem Schadensersatz für entgangene Nutzung auch den
            Ausgleich unserer Kosten für unsere anwaltliche Inanspruchnahme nach sich.
            <br />
            Im Falle einer Abmahnung machen wir von unserem gesetzlichen Recht Gebrauch, eine strafbewehrte
            Unterlassungserklärung auch gerichtlich zu erzwingen!
        </p>
        <p style={{ textAlign: "center" }}>
            Alle auf der Homepage genannten Preise verstehen sich zzgl. der jeweils gesetzlich geltenden
            Mehrwertsteuer.
            <br />
            Es handelt sich dabei nicht um Angebote sondern um die Aufforderung zur Abgabe eines Angebots.
            <br />
            Erst nach Erhalt eines auf den Kunden zugeschnittenen Angebots sind wir an dessen Inhalte
            kaufmännisch gebunden.
            <br />
            Mit der erstmaligen Versendung eines Angebots an einen potenziellen Kunde oder Anfrager erhält
            dieser auch unsere aktuell gültigen Allgemeinen Geschäftsbedingungen (AGB), die Grundlage unserer
            Geschäfte sind.
            <br />
            Ein auf Wunsch des Kunden Abweichen von den AGB muss im jeweiligen Angebot explizit ausgewiesen
            sein, um seine Gültigkeit zu entfalten.
        </p>
        <p style={{ textAlign: "center" }}>
            Für versehentlich eingestellte fehlerhafte Inhalte sowie für die Inhalte an weiterführende Webseiten
            übernehmen wir keine Verantwortung.
            <br />
            Nachweislich falsch dargestellte Inhalte werden auf Anforderung nach Verifizierung unmittelbar
            geändert.
        </p>
        <p style={{ textAlign: "center" }}>
            Verantwortlich für die Inhalte: Restaurant Glufamichel vertreten durch den Geschäftsführer Walter Huber.
        </p>
    </div>`;
        text = text
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");
        return (
            <ScrollView nativeID="contentText" style={{ padding: "30px" }}>
                {text && <RenderHTML source={{ html: htmlDecode(text) }} />}
            </ScrollView>
        );
    }
}

function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}
