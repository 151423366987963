import React, { Component } from "react";
import { Card, Divider, Text } from "react-native-elements";
import { ActivityIndicator, View } from "react-native-web";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getOrderStatusByUid } from "../../store/actions/orderActions";
import { getOrderStatus, setOrderStatus, start } from "../../store/actions/orderStatusActions";
import { checkAndBookPayment } from "../../store/actions/paymentActions";
import { store } from "../../store/store";
import WeeklyPlanHeader from "../WeeklyPlan/WeeklyPlanHeader";
import ArticleList from "./ArticleList";
import CustomerInformations from "./CustomerInformations";
import OrderInformations from "./OrderInformations";

class OrderTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ loading: true });
        store.dispatch(setOrderStatus(undefined));
        if (id) {
            store.dispatch(getOrderStatusByUid(id)).then((result) => {
                this.setState({ loading: false });
                store.dispatch(setOrderStatus(result));
            });
            setInterval(() => {
                if (!this.props.orderStatus.data || this.props.orderStatus.data.orderStateString !== "Abgeschlossen") {
                    store.dispatch(getOrderStatusByUid(id)).then((result) => {
                        store.dispatch(setOrderStatus(result));
                    });
                }
            }, 10000);
        }
    }

    render() {
        const { orderStatus } = this.props;
        const id = orderStatus.data ? orderStatus.data.uid : null;
        const { windowSize } = this.props;
        let mobile = window.innerWidth < 1028;
        return (
            <View>
                {/* <WeeklyPlanHeader
                    weeklyPlanTitle={this.state.weeklyPlanTitle}
                    weeklyPlanDate={this.state.weeklyPlanDate}
                    showZipCode={this.props.showZipCode}
                    goToNextWeeklyPlan={this.goToNextWeeklyPlan}
                    goToPrevWeeklyPlan={this.goToPrevWeeklyPlan}
                    orderTracking={true}
                /> */}
                <View
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <View style={{ width: mobile ? 350 : 800 }}>
                        {(!id || orderStatus.data === false) && !this.state.loading && (
                            <Text>Keine passende Bestellung gefunden</Text>
                        )}
                        {id && orderStatus.data === null && (
                            <View>
                                <ActivityIndicator size="large" />
                            </View>
                        )}
                        {id && (
                            <View>
                                {orderStatus.data && (
                                    <Card
                                        containerStyle={{
                                            backgroundColor: "white",
                                        }}
                                        style={{
                                            marginTop: 50,
                                            marginLeft: mobile ? 5 : 50,
                                            borderRadius: 13,
                                            backgroundColor: "white",
                                        }}
                                        title={"Ihre Bestellung (Nr. " + orderStatus.data.pizzaManagerId}
                                    >
                                        <View>
                                            <Text h2>
                                                {"Ihre Bestellung (Nr. " + orderStatus.data.pizzaManagerId + " )"}
                                            </Text>
                                            <OrderInformations data={orderStatus.data} />
                                            <Divider style={{ marginVertical: 20 }} />
                                            <CustomerInformations
                                                customer={orderStatus.data.customer}
                                                textColor={this.props.settings.store.accentColor}
                                            />
                                            <Divider style={{ marginVertical: 20 }} />
                                            <ArticleList
                                                cartJson={orderStatus.data.cartJson}
                                                textColor={this.props.settings.store.accentColor}
                                            />
                                        </View>
                                    </Card>
                                )}
                            </View>
                        )}
                    </View>
                    {this.state.loading && <ActivityIndicator size={"large"} style={{ marginTop: 100 }} animated />}
                </View>
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { orderStatus, settings } = state;
    return { orderStatus, settings };
}

export default connect(mapStateToProps)(withRouter(OrderTracking));
