import { StyleSheet } from "react-native";
import { appColors } from "../../shared/styles";

export const articleDetailsStyle = StyleSheet.create({
    description: {
        color: appColors.grey5,
        marginBottom: 15,
    },
    additionalInformation: {
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 10,
    },
    h4: {
        color: appColors.white,
        fontSize: 14,
        fontWeight: "bold",
    },
    h5: {
        color: appColors.white,
        fontSize: 14,
    },
    additives: {
        color: appColors.white,
        marginBottom: 20,
    },
    ingredientsList: {
        color: appColors.white,
        marginBottom: 10,
    },
});
