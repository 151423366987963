import { store } from "../store"
import { REMOVE_ALL_ORDER_ITEMS } from "./cartActions"

export const SET_ORDERTYPE = 'SET_ORDERTYPE'

export function setOrdertype(orderType) {
    return {
        type: SET_ORDERTYPE,
        orderType: orderType
    }
}

export const SET_ORDERTYPE_BY_ID = 'SET_ORDERTYPE_BY_ID'

export function setOrdertypeById(orderTypeId) {
    store.dispatch({ type: REMOVE_ALL_ORDER_ITEMS })
    return {
        type: SET_ORDERTYPE_BY_ID,
        orderTypeId: orderTypeId
    }
}

export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA'

export function setCustomerData(customerData) {
    return {
        type: SET_CUSTOMER_DATA,
        customerData: customerData
    }
}