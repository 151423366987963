import { StyleSheet } from "react-native";

const styleDesktop = {
    logo: {
        height: 30,
        width: 100,
        cursor: "pointer",
    },
};

const styleMobile = {
    logoMobile: {
        height: 30,
        width: 30,
        cursor: "pointer",
    },
};

export const headerStyles = StyleSheet.create({ ...styleDesktop, ...styleMobile });
