import React, { Component } from "react";
import { Header } from "react-native-elements";
import { connect } from "react-redux";

import { withRouter } from "../../routers/routing";

class AppHeader extends Component {
    render() {
        return (
            <Header
                placement="left"
                containerStyle={{ zIndex: 99999, minHeight: "58px" }}
                backgroundColor={this.props.settings.store.accentColor}
                centerComponent={this.props.centerComponent}
                leftComponent={this.props.leftComponent}
                orderTracking={this.props.orderTracking}
                rightComponent={
                    <span>
                        <span style={{ position: "relative" }}>{this.props.rightComponent}</span>
                    </span>
                }
            />
        );
    }
}

function mapStateToProps(state) {
    const { customer, theme, settings } = state;
    return { customer, theme, settings };
}

export default connect(mapStateToProps)(withRouter(AppHeader));
