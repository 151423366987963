import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";
import { store } from "../store";
import { REMOVE_ALL_ORDER_ITEMS } from "./cartActions";

const config = new Config();

export const SET_ORDERTYPE = "SET_ORDERTYPE";

export function setOrdertype(orderType) {
    return {
        type: SET_ORDERTYPE,
        orderType: orderType,
    };
}

export const SET_ORDERTYPE_BY_ID = "SET_ORDERTYPE_BY_ID";

export function setOrdertypeById(orderTypeId) {
    store.dispatch({ type: REMOVE_ALL_ORDER_ITEMS });
    return {
        type: SET_ORDERTYPE_BY_ID,
        orderTypeId: orderTypeId,
    };
}

export const SET_CUSTOMER = "SET_CUSTOMER";

export function setCustomer(customer) {
    return {
        type: SET_CUSTOMER,
        customer: customer,
    };
}

export const SET_CUSTOMERCOURSES = "SET_CUSTOMERCOURSES";

export function setCustomerCourses(customerCourses) {
    return {
        type: SET_CUSTOMERCOURSES,
        customerCourses: customerCourses,
    };
}

export function loginCustomer(email, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email, password: password }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/loginCustomer", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => store.dispatch(setCustomer(json)));
    };
}

export function getCustomer(azureId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(azureId),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/GetCustomerFromOnlineShop", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
export function updateAddress(
    customerUid,
    title,
    firstName,
    lastName,
    addressIndex,
    city,
    street,
    houseNumber,
    zipCode,
    company,
    addressDetails,
    isDefaultBillingAddress,
    isDefaultDeliveryAddress,
    addressUid
) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            customerUid: customerUid,
            title: title,
            firstName: firstName,
            lastName: lastName,
            addressIndex: addressIndex,
            city: city,
            street: street,
            houseNumber: houseNumber,
            zipCode: zipCode,
            company: company,
            addressDetails: addressDetails,
            isDefaultDeliveryAddress: isDefaultDeliveryAddress,
            isDefaultBillingAddress: isDefaultBillingAddress,
            addressUid: addressUid,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/customers/updateAddress", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function deleteAddress(customerUid, addressIndex, city, street, houseNumber, zipCode, addressUid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            customerUid: customerUid,
            addressIndex: addressIndex,
            city: city,
            street: street,
            houseNumber: houseNumber,
            zipCode: zipCode,
            addressUid: addressUid,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/customers/deleteAddress", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function registerCustomer(customer) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customer }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/registerCustomer", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function updateStandardBillingAddress(customerUid, addressIndex) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customerUid: customerUid, addressIndex: addressIndex }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/UpdateStandardBillingAddressFromOnlineShop", requestOptions).then(
            (response) => handleResponse(response)
        );
    };
}

export function updateStandardDeliveryAddress(customerUid, addressIndex) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customerUid: customerUid, addressIndex: addressIndex }),
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/Customers/UpdateStandardDeliveryAddressFromOnlineShop",
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function getShoppingList(customersUid, from, to) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customersUid: customersUid, from: from, to: to }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/OnlineShop/GetShoppingList", requestOptions).then(
            (response) => handleResponse(response),
            (err) => console.log(err)
        );
    };
}

export function checkShoppingItem(cookingProcessIngredient) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(cookingProcessIngredient),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/OnlineShop/CheckShoppingItem", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function GetCoursesByCustomer(customersUid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(customersUid),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/GetCoursesByCustomer", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => store.dispatch(setCustomerCourses(json)));
    };
}

export function DeleteCustomerCourse(customerCoursesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(customerCoursesId),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/DeleteCustomerCourse", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => store.dispatch(setCustomerCourses(json)));
    };
}

export function UpdateCustomerCourses(customersUid, courses) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customersUid: customersUid, courses: courses }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/UpdateCustomerCourses", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => store.dispatch(setCustomerCourses(json)));
    };
}

export function CreateCustomerCourse(customersUid, coursesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customersUid: customersUid, coursesId: coursesId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/CreateCustomerCourse", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => store.dispatch(setCustomerCourses(json)));
    };
}

export function updateOnlineShopCustomer(customerUid, azureId, firstName, lastName, phoneNumber) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            customerUid: customerUid,
            azureId: azureId,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/updateOnlineShopCustomer", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => store.dispatch(setCustomerCourses(json)));
    };
}
