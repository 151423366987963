import moment from "moment";
import React, { Component } from "react";
import { Pressable, View, Dimensions, Image } from "react-native";
import { Icon, Text } from "react-native-elements";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

import Config from "../../../Config";
import AppHeader from "../../components/AppHeader";
import { weeklyPlanStyles } from "../../screens/WeeklyPlan/WeeklyPlanStyles";
import { getContrastColor } from "../../shared/helpers";
import { headerStyles } from "./HeaderStyle";

const config = new Config();
class SiteHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            weeklyPlanTitle: "",
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            cartOpen: false,
        };

        this.formattedWeeklyPlanTitle = this.formattedWeeklyPlanTitle.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        // this.cartIcon = this.cartIcon.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        if (this.props.weeklyPlanDate) {
            if (prevProps.weeklyPlanDate !== this.props.weeklyPlanDate) {
                this.setState({
                    weeklyPlanTitle: this.formattedWeeklyPlanTitle(),
                });
            }
        }
    }

    componentWillUnmount() {
        Dimensions.removeEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({ windowWidth: Dimensions.get("window").width, windowHeight: Dimensions.get("window").height });
    }

    handleLogin() {
        const { account } = this.props;
        this.setState({
            userMenuVisible: false,
        });
        if (account.customer) {
            this.props.logout();
        } else {
            this.props.toggleLoginPopup();
        }
    }

    formattedWeeklyPlanTitle() {
        let deliveryDate =
            this.props.zipCode && this.props.zipCode.nextDeliveryDate ? this.props.zipCode.nextDeliveryDate : "";

        if (deliveryDate instanceof moment) {
            deliveryDate = deliveryDate.toISOString();
        }
        return <View style={weeklyPlanStyles.headerContainer}></View>;
    }

    headerIcons() {
        const { account } = this.props;
        return (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                {/* {!this.props.account.customer && (
                    <Pressable onPress={() => this.handleLogin()} style={{ marginRight: 10 }}>
                        <Icon
                            type="ionicon"
                            size={18}
                            name="log-in-outline"
                            color={getContrastColor(this.props.settings.store.accentColor)}
                        />
                        <Text style={{ fontSize: 12, color: getContrastColor(this.props.settings.store.accentColor) }}>
                            Anmelden
                        </Text>
                    </Pressable>
                )} */}
                {this.props.account.customer && (
                    <>
                        <Pressable onPress={() => this.props.history.push("/customer/")} style={{ marginRight: 10 }}>
                            <Icon
                                type="ionicon"
                                size={18}
                                name="person-outline"
                                color={getContrastColor(this.props.settings.store.accentColor)}
                            />
                            <Text
                                style={{ fontSize: 12, color: getContrastColor(this.props.settings.store.accentColor) }}
                            >
                                Hallo, {account.customer.firstName}!
                            </Text>
                        </Pressable>

                        <Pressable onPress={() => this.handleLogin()} style={{ marginRight: 10 }}>
                            <Icon
                                type="ionicon"
                                size={18}
                                name="log-out-outline"
                                color={getContrastColor(this.props.settings.store.accentColor)}
                            />
                            <Text
                                style={{ fontSize: 12, color: getContrastColor(this.props.settings.store.accentColor) }}
                            >
                                Abmelden
                            </Text>
                        </Pressable>
                    </>
                )}

                {this.state.windowWidth < 1200 && (
                    <Pressable onPress={this.props.toggleCartSidebar} style={{ marginRight: 10 }}>
                        <Icon
                            type="ionicon"
                            size={18}
                            name="cart-outline"
                            color={getContrastColor(this.props.settings.store.accentColor)}
                        />
                        <Text style={{ fontSize: 12, color: getContrastColor(this.props.settings.store.accentColor) }}>
                            Warenkorb
                            {this.props.cart.orderItems.length > 0
                                ? " (" + this.props.cart.orderItems.length + ")"
                                : ""}
                        </Text>
                    </Pressable>
                )}
            </View>
        );
    }

    render() {
        const { theme, account } = this.props;
        const { windowWidth } = this.state;
        return (
            <>
                <AppHeader
                    leftComponent={
                        <>
                            {theme.theme.logoUrl && (
                                <Pressable onPress={() => this.props.history.push("/")}>
                                    <Image
                                        source={{ uri: config.imageStorageBlobURL + theme.theme.logoUrl }}
                                        style={windowWidth >= 1200 ? headerStyles.logo : headerStyles.logoMobile}
                                        resizeMode="contain"
                                    />
                                </Pressable>
                            )}
                        </>
                    }
                    centerComponent={
                        <Text
                            h1
                            h1Style={{
                                color: getContrastColor(this.props.settings.store.accentColor),
                                marginBottom: 0,
                                lineHeight: "30px",
                            }}
                            onPress={() => this.props.history.push("/")}
                            numberOfLines={1}
                            ellipsizeMode="tail"
                        >
                            {theme.theme.title}
                        </Text>
                    }
                    orderTracking={this.props.OrderTracking}
                    rightComponent={this.headerIcons()}
                    showZipCode={this.props.showZipCode}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    const { cart, zipCode, theme, account, settings } = state;
    return { cart, zipCode, theme, account, settings };
}

export default connect(mapStateToProps)(withRouter(SiteHeader));
