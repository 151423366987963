import moment from "moment";
import React, { Component } from "react";
import { ActivityIndicator, Linking, Platform } from "react-native";
import { View } from "react-native";
import { Icon, Text } from "react-native-elements";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

import { appColors } from "../../shared/styles";
import { postOrder } from "../../store/actions/orderActions";
import { getPaymentLink } from "../../store/actions/paymentActions";
import { store } from "../../store/store";

class CompleteOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTask: "",
            error: false,
            errorText: "",
            redirectCounter: 10,
        };

        this.handleCompleteOrder = this.handleCompleteOrder.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.redirectCountdown = this.redirectCountdown.bind(this);
    }

    componentDidMount() {
        const { customerData } = this.props;
        if (customerData) {
            this.handleCompleteOrder(customerData);
        } else {
            this.setState({
                error: true,
                errorText: "Kundendaten nicht korrekt",
            });
        }
    }

    handleCompleteOrder(customerData) {
        this.setState({
            currentTask: "Bestellung wird aufgenommen",
        });
        const order = this.createOrder(customerData);
        store
            .dispatch(postOrder(order))
            .then((json) => {
                console.log(json);
                this.setState(
                    {
                        currentTask: !this.props.cash
                            ? "Bezahlvorgang startet gleich"
                            : "Bestellung erfolgreich aufgenommen",
                    },
                    () => {
                        if (!this.props.cash) {
                            store
                                .dispatch(getPaymentLink(json.uid))
                                .then((result) => {
                                    if (result.result.status_code == 100) {
                                        this.setState({
                                            currentTask: "Du wirst weitergeleitet zum Bezahlvorgang in",
                                        });
                                        this.redirectCountdown(result.result.redirect_url);
                                    } else {
                                        this.setState({
                                            error: true,
                                            errorText: "Es ist zu einem Fehler beim Bezahlvorgang gekommen",
                                        });
                                    }
                                })
                                .catch(() => {
                                    this.setState({
                                        error: true,
                                        errorText: "Bezahlvorgang konnte nicht gestartet werden",
                                    });
                                });
                        } else {
                            Linking.openURL(window.location.href + "orderTracking/" + json.uid + "/");
                            store.dispatch({ type: "REMOVE_ALL_ORDER_ITEMS" });
                            this.props.toggleCompleteOrder();
                        }
                    }
                );
            })
            .catch(() => {
                this.setState({
                    error: true,
                    errorText: "Bestellung konnte nicht aufgenommen werden",
                });
            });
    }

    createOrder(customerData) {
        const { cart, zipCode, customer } = this.props;
        let deliveryDate = zipCode && zipCode.nextDeliveryDate ? zipCode.nextDeliveryDate : "";
        console.log(deliveryDate);
        let cartValue = 0;
        cart.orderItems.forEach((item) => (cartValue += item.Price.price1));
        let billingAddress = customerData.billingAddress ? JSON.parse(customerData.billingAddress) : undefined;
        let deliveryAddress = customerData.deliveryAddress ? JSON.parse(customerData.deliveryAddress) : undefined;
        return {
            bonuscodes: [],
            customData: {},
            orderTypesId: 6,
            customDataTemplate: {},
            requestedDeliveryTime: deliveryDate != "" ? this.getIsoTimeString(deliveryDate) : null,
            printBill: customerData.printBill,
            printRecipe: customerData.printRecipe,
            comment: customerData.comment,
            deliverLumpSum:
                cartValue < this.props.settings.store.deliverFreeValue ? this.props.settings.store.deliverLumpSum : 0,

            minOrderValue: this.props.settings.store.minOrderValue,
            customer: {
                allowAdvertise: "false",
                customerUid: this.props.account.customer ? this.props.account.customer.customerUid : "",
                azureId: this.props.account.customer ? this.props.account.customer.azureId : "",
                city: deliveryAddress ? deliveryAddress.Street.City.name : customerData.city,
                email: customerData.email,
                title: deliveryAddress ? deliveryAddress.title : customerData.title,
                firstName: deliveryAddress ? deliveryAddress.firstName : customerData.firstname,
                houseNumber: deliveryAddress ? deliveryAddress.housenumber : customerData.houseNumber,
                lastName: deliveryAddress ? deliveryAddress.lastName : customerData.lastname,
                phoneNumber: customerData.phonenumber,
                street: deliveryAddress ? deliveryAddress.Street.name : customerData.street,
                zipcode: deliveryAddress ? deliveryAddress.Street.City.zipCode : customerData.zip,
                company: deliveryAddress ? deliveryAddress.company : customerData.company,
                supplement: deliveryAddress ? deliveryAddress.addressDetails : customerData.addressDetails,
                useDifferentBillingAddress: deliveryAddress
                    ? deliveryAddress == billingAddress
                        ? false
                        : true
                    : customerData.useDifferentBillingAddress,
                billingTitle: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.title
                        : deliveryAddress.title
                    : customerData.title,
                billingFirstName: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.firstName
                        : deliveryAddress.firstName
                    : customerData.firstname,
                billingLastName: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.lastName
                        : deliveryAddress.lastName
                    : customerData.lastname,
                billingZipcode: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.Street.City.zipCode
                        : deliveryAddress.Street.City.zipCode
                    : customerData.zip,
                billingCity: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.Street.City.name
                        : deliveryAddress.Street.City.name
                    : customerData.city,
                billingStreet: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.Street.name
                        : deliveryAddress.Street.name
                    : customerData.street,
                billingHouseNumber: deliveryAddress
                    ? customerData.useDifferentBillingAddress
                        ? billingAddress.housenumber
                        : deliveryAddress.housenumber
                    : customerData.housenumber,
            },
            orderItems: cart.orderItems.map((item) => ({
                amount: item.entry.amount,
                articlesId: item.entry.articlesId,
                articlesName: item.entryArticle ? item.entry.Article.name : "",
                price: item.Price.price1,
                requestedDeliveryTime: this.getStoreTimeforCartItem(item),
                sizesId: item.ArticleSize.sizesId,
                dayToCook: new Date().toISOString(),
            })),

            paymentBillOptions: 0,
            paymentBillType: 1,
            paymentTypesId: this.props.cash ? 202 : 50,
            storeId: 9999,
        };
    }

    getIsoTimeString(date) {
        const returnDate = new Date(parseInt(date) * 1000);
        console.log(returnDate.getDate(), new Date(null).getDate());
        if (returnDate.getDate() == new Date(null).getDate()) return null;
        returnDate.setHours(returnDate.getHours() + 4);
        return returnDate.toISOString();
    }

    getStoreTimeforCartItem(cartItem) {
        const returnDate = new Date(cartItem.weeklyPlanDay);
        console.log(returnDate.toISOString(), new Date(null).toISOString());

        returnDate.setHours(returnDate.getHours() + 1);
        returnDate.setMinutes(returnDate.getMinutes());
        return returnDate.toISOString();
    }

    redirectCountdown(url) {
        var counter = setInterval(() => {
            if (this.state.redirectCounter <= 0) {
                clearInterval(counter);
                // Linking.openURL(url);
                if (Platform.OS === "web") {
                    window.location.href = url;
                } else {
                    Linking.canOpenURL(url)
                        .then((canOpen) => {
                            if (canOpen) {
                                Linking.openURL(url);
                            } else {
                                console.log("Cannot open URL: " + url);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            } else {
                this.setState({
                    redirectCounter: this.state.redirectCounter - 1,
                });
            }
        }, 1000);
    }

    render() {
        const { currentTask, redirectCounter, error, errorText } = this.state;
        return (
            <View>
                {!error && (
                    <View>
                        <Text h3>
                            {currentTask}...{redirectCounter < 10 ? redirectCounter : ""}
                        </Text>
                        <ActivityIndicator size="large" color={appColors.primary} />
                    </View>
                )}
                {error && (
                    <View>
                        <Text h3 h3Style={{ color: appColors.error }}>
                            {errorText}
                        </Text>
                        <Icon type="font-awesome-5" name="exclamation-circle" color={appColors.error} />
                    </View>
                )}
            </View>
        );
    }
}

CompleteOrder.defaultProps = {
    customerData: null,
};

function mapStateToProps(state) {
    const { account, zipCode, cart, settings } = state;
    return { account, zipCode, cart, settings };
}

export default connect(mapStateToProps)(withRouter(CompleteOrder));
