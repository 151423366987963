import React, { Component } from "react";
import { View } from "react-native";
import { Header, Icon, Text } from "react-native-elements";
import { appColors, styles } from "../../shared/styles";
import Cart from "./Cart";
import CartTotal from "./CartTotal";

export default class CartSidebar extends Component {
    render() {
        return (
            <View style={{ flex: 1 }}>
                <Header
                    containerStyle={{ paddingBottom: 13, paddingTop: 13, minHeight: "58px" }}
                    placement="left"
                    backgroundColor="#333"
                    centerComponent={{
                        text: (
                            <Text
                                h2
                                h2Style={{
                                    color: appColors.white,
                                    marginBottom: 0,
                                    lineHeight: "24px",
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                }}
                            >
                                Warenkorb
                            </Text>
                        ),
                    }}
                    leftComponent={<Icon name="shopping-cart" type="font-awesome" size={20} color="#fff" />}
                    rightComponent={<CartTotal />}
                />
                <View style={styles.contentContainer}>
                    <Cart showCustomerData={this.props.showCustomerData} />
                </View>
            </View>
        );
    }
}
