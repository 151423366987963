import React, { Component } from "react";
import { ScrollView, View } from "react-native";
import { Button, Text } from "react-native-elements";
import { connect } from "react-redux";

import { getContrastColor, HSLToHex } from "../../shared/helpers";
import { appColors, styles } from "../../shared/styles";
import { REMOVE_ORDER_ITEM } from "../../store/actions/cartActions";
import { store } from "../../store/store";
import CartItem from "./CartItem";
import { cartStyles } from "./CartStyles";

function removeOrderItem(orderItem) {
    store.dispatch({ type: REMOVE_ORDER_ITEM, orderItem });
}

// function sortByDate(a, b) {
//     return new Date(a.weeklyPlanDay) - new Date(b.weeklyPlanDay);
// }

function sortByDayToCook(a, b) {
    return new Date(a.dayToCook) - new Date(b.dayToCook);
}
export class Cart extends Component {
    constructor(props) {
        super(props);

        this.calculateTotal = this.calculateTotal.bind(this);
    }

    calculateTotal() {
        let total = 0;

        if (this.props.cart.orderItems.length > 0) {
            let i = 0;

            while (this.props.cart.orderItems[i]) {
                const orderItem = this.props.cart.orderItems[i];

                if (orderItem) {
                    const price = orderItem.Price ? orderItem.Price.price1 : 0;
                    const { amount } = orderItem.entry;

                    if (price && amount) {
                        total += price * amount;
                    }
                }

                i++;
            }
        }

        total = Math.round(total * 100) / 100;

        return total;
    }

    render() {
        return (
            <View style={cartStyles.container}>
                <ScrollView style={cartStyles.articleContainer}>
                    {this.props.cart.orderItems.length < 1 && <Text>Warenkorb ist leer</Text>}
                    {this.props.cart.orderItems.length > 0 && (
                        <>
                            {this.props.cart.orderItems.sort(sortByDayToCook).map((orderItem, index) => (
                                <CartItem key={index} orderItem={orderItem} removeOrderItem={removeOrderItem} />
                            ))}
                        </>
                    )}
                </ScrollView>
                {/* // <div
                //     style={{
                //         height: "auto",
                //         color: "black",
                //         marginBottom: 20,
                //         display: "flex",
                //         flexDirection: "column",
                //         borderTop: true,
                //         borderTopStyle: "solid",
                //         borderTopWidth: 2,
                //     }}
                // >
                //     <Text style={{ marginTop: 10 }}>{"Warenkorb: " + this.calculateTotal().toFixed(2) + "€"}</Text>
                // </div> */}

                <Button
                    buttonStyle={{ backgroundColor: this.props.settings.store.accentColor }}
                    title="Bestellen"
                    onPress={this.props.showCustomerData}
                    titleStyle={{ color: getContrastColor(this.props.settings.store.accentColor) }}
                    disabled={
                        this.calculateTotal() < this.props.settings.store.minOrderValue ||
                        this.props.cart.orderItems.length < 1
                    }
                />
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { cart, customer, settings, theme } = state;
    return { cart, customer, settings, theme };
}

export default connect(mapStateToProps)(Cart);
