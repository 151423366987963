class Config {
    constructor() {
        this.currencyFormat = "0[.]00 $";
        this.imageStorageBlobURL = "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/";
    }

    get backendHost() {
        let url = new URL(window.location.href);
        if (process.env.NODE_ENV === "development") {
            return "http://localhost:5000/b";
        } else {
            return url.origin + "/b";
        }
    }

    get shopUrl() {
        let url = new URL(window.location.href);
        if (process.env.NODE_ENV === "development") {
            return "http://localhost:19006/";
        } else {
            return url.origin;
        }
    }
}

export default Config;
