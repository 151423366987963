import { config } from "../../AzureConfig";
import { SET_SETTINGS } from "../actions/settingsActions";

const initialState = {
    store: {
        maxSingleArticleAmount: 9,
    },
    customerData: {
        fields: [
            {
                id: "title",
                label: "",
                placeholder: "Anrede auswählen",
                type: "select",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "firstname",
                label: "",
                placeholder: "Vorname",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "lastname",
                label: "",
                placeholder: "Nachname",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "email",
                label: "",
                placeholder: "E-Mail-Adresse",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                    {
                        type: "email",
                        params: ["Keine korrekte E-Mail-Adresse"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "phonenumber",
                label: "",
                placeholder: "Telefonnummer",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "street",
                label: "",
                placeholder: "Straße",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "houseNumber",
                label: "",
                placeholder: "Hausnummer",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "zip",
                label: "",
                placeholder: "PLZ",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                // not needed for mensa shop
                // disabled: true,

                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "city",
                label: "",
                placeholder: "Stadt",
                type: "text",
                validationType: "string",
                validations: [
                    {
                        type: "required",
                        params: ["Das ist ein Pflichtfeld"],
                    },
                ],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "comment",
                label: "",
                placeholder: "Kommentar/Wünsche",
                type: "textarea",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: false,
                showWhenLoggedIn: true,
                showWhenNotLoggedIn: true,
            },
            {
                id: "deliveryAddress",
                label: "",
                placeholder: "Lieferadresse auswählen",
                type: "selectAddress",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: true,
                showWhenNotLoggedIn: false,
            },
            {
                id: "useDifferentBillingAddress",
                label: "",
                placeholder: "abweichende Rechnungsadresse verwenden",
                type: "useDifferentBillingAddress",
                validationType: "bool",
                value: false,
                showWhenLoggedIn: true,
                showWhenNotLoggedIn: false,
            },

            {
                id: "billingTitle",
                label: "",
                placeholder: "Anrede",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingFirstName",
                label: "",
                placeholder: "Vorname",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingLastName",
                label: "",
                placeholder: "Nachname",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingStreet",
                label: "",
                placeholder: "Straße",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingHousenumber",
                label: "",
                placeholder: "Hausnummer",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingZipCode",
                label: "",
                placeholder: "PLZ",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingCity",
                label: "",
                placeholder: "Stadt",
                type: "text",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: false,
                showWhenNotLoggedIn: true,
            },
            {
                id: "billingAddress",
                label: "",
                placeholder: "Rechnungsadresse auswählen",
                type: "selectAddress",
                validationType: "string",
                validations: [],
                value: "",
                showInRegistration: true,
                showWhenLoggedIn: true,
                showWhenNotLoggedIn: false,
            },
            {
                id: "printBill",
                label: "",
                placeholder: "Rechnung drucken",
                type: "printBillCheckbox",
                validationType: "boolean",
                validations: [],
                value: "",
                showInRegistration: false,
                showWhenLoggedIn: true,
                showWhenNotLoggedIn: true,
            },
            {
                id: "printRecipe",
                label: "",
                placeholder: "Rezept drucken",
                type: "printRecipeCheckbox",
                validationType: "boolean",
                validations: [],
                value: "",
            },
        ],
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SETTINGS:
            if (action.store) {
                config.auth.clientId = action.store.onlineShopClientId;
                config.auth.authority = action.store.signUpUserFlowAuthority;
                config.auth.knownAuthorities = [action.store.authorityDomain];
                delete action.store.onlineShopClientId;
                delete action.store.signUpUserFlowAuthority;
                delete action.store.authorityDomain;
            }
            return Object.assign({}, state, {
                store: { ...state.store, ...action.store },
            });
        default:
            return state;
    }
};
