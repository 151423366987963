import { SET_THEME } from "../actions/themeActions";

const initialState = {
    theme: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_THEME:
            return Object.assign({}, state, {
                theme: action.theme,
            });
        default:
            return state;
    }
};
