import { Formik } from "formik";
import React, { Component } from "react";
import { View } from "react-native-web";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { updateOnlineShopCustomer } from "../../store/actions/accountActions";
import { store } from "../../store/store";
import CustomInputField from "../../helpers/inputField/CustomInputField";
import { getContrastColor } from "../../shared/helpers";
import * as yup from "yup";
import { Button, Card, Text } from "react-native-elements";
import CustomTooltipIconButton from "../../helpers/IconButton/CustomIconButton";
import { StyleSheet } from "react-native";
import { customerStyle } from "./CustomerStyle";

const yupObject = yup.object().shape({
    firstName: yup.string().required("Bitte einen Vornamen eingeben"),
    familyName: yup.string().required("Bitte einen Nachnamen eingeben"),
    phoneNumber: yup.string().required("Bitte eine Telefonnummer eingeben"),
});

class CustomerProfile extends Component {
    render() {
        const { customer } = this.props.account;
        return (
            <View>
                <View style={{ display: "flex", flexDirection: "row", zIndex: 10 }}>
                    <CustomTooltipIconButton
                        icon="arrow-left"
                        tooltipText="Zurück"
                        iconColor={this.props.settings.store.accentColor}
                        onButtonClick={() => this.props.history.push("/customer/")}
                        containerStyle={{ marginLeft: 25, marginTop: 5 }}
                    />
                    <Text style={{ marginTop: 10, marginLeft: 25, marginBottom: 25 }}>Accountverwaltung</Text>
                </View>
                <View
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Card
                        containerStyle={{
                            ...StyleSheet.flatten(customerStyle.card),
                            backgroundColor: this.props.settings.store.surfaceColor,
                            border: "1px solid " + this.props.settings.store.accentColor,
                            marginBottom: 25,
                            width: 350,
                        }}
                    >
                        <Formik
                            initialValues={{
                                firstName: customer.firstName,
                                familyName: customer.familyName,
                                email: customer.email,
                                phoneNumber: customer.phoneNumber,
                            }}
                            validationSchema={yupObject}
                            onSubmit={(values) => {
                                toast.show("Änderungen werden gespeichert...", { type: "info" });

                                store
                                    .dispatch(
                                        updateOnlineShopCustomer(
                                            customer.customerUid,
                                            customer.azureId,
                                            values.firstName,
                                            values.familyName,
                                            values.phoneNumber
                                        )
                                    )
                                    .then((response) => {
                                        toast.show("Änderungen wurden gespeichert", { type: "success" });
                                        this.props.update(customer.azureId);
                                    });
                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: 30,
                                    }}
                                >
                                    <CustomInputField
                                        name="firstName"
                                        label="Vorname"
                                        margin="dense"
                                        onChange={handleChange("firstName")}
                                        value={values.firstName}
                                        error={errors.firstName ? errors.firstName : ""}
                                    />

                                    <CustomInputField
                                        name="familyName"
                                        label="Nachname"
                                        margin="dense"
                                        onChange={handleChange("familyName")}
                                        value={values.familyName}
                                        error={errors.familyName ? errors.familyName : ""}
                                    />

                                    <CustomInputField
                                        name="email"
                                        label="E-Mail"
                                        disabled={true}
                                        margin="dense"
                                        onChange={handleChange("email")}
                                        value={values.email}
                                    />

                                    <CustomInputField
                                        name="phoneNumber"
                                        label="Telefonnummer"
                                        margin="dense"
                                        onChange={handleChange("phoneNumber")}
                                        value={values.phoneNumber}
                                        error={errors.phoneNumber ? errors.phoneNumber : ""}
                                    />

                                    <Button
                                        title={"Änderungen speichern"}
                                        titleStyle={{
                                            color: getContrastColor(this.props.settings.store.accentColor),
                                        }}
                                        buttonStyle={{
                                            backgroundColor: this.props.settings.store.accentColor,
                                            marginTop: 50,
                                            marginBottom: 50,
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                        }}
                                        onPress={handleSubmit}
                                        uppercase={false}
                                    />
                                </View>
                            )}
                        </Formik>
                    </Card>
                </View>
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}

export default connect(mapStateToProps)(withRouter(CustomerProfile));
