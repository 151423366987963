import moment from "moment";
import React, { forwardRef, useState } from "react";
import { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { Button, Text } from "react-native-elements";
import MaterialCommunityIcon from "react-native-vector-icons/MaterialCommunityIcons";
import { useSelector } from "react-redux";

import { appColors } from "../../shared/styles";
import { SET_NEXT_DELIVERY_DATE, SET_SELECTED_INDEX } from "../../store/actions/zipCodeActions";
import { store } from "../../store/store";

export default function DeliveryDateField(props) {
    let { deliveryDate } = props;
    let date = new Date(deliveryDate);

    const { zipCode } = useSelector((state) => state);

    const isDeliveryDay = (date) => {
        // const day = moment(date).day() - 1;
        // console.log(zipCode.zipCodeArea.deliveryDays);
        // let result = zipCode.zipCodeArea.deliveryDays.filter((deliveryDay) => {
        //     let currentDay = String(day);
        //     return deliveryDay === currentDay;
        // });
        // if (result.length > 0) {
        //     return true;
        // } else {
        //     return false;
        // }
        return true;
    };

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button
            title={value}
            onPress={onClick}
            type="outline"
            iconRight
            titleStyle={{ color: appColors.white }}
            buttonStyle={{
                borderColor: appColors.white,
                borderStyle: "dashed",
                paddingVertical: 0,
                paddingHorizontal: 5,
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                minHeight: 24,
            }}
            icon={
                <MaterialCommunityIcon
                    name="calendar-week"
                    size={13}
                    color={appColors.white}
                    style={{ paddingLeft: 5 }}
                />
            }
        />
    ));

    return (
        <ReactDatePicker
            dateFormat="dd-MM-yyyy"
            selected={date}
            // minDate={moment().add(2, "days").toDate()}
            onChange={(date) => {
                let d = moment(date);
                let di = d.day() - 1;
                let selectedIndex = zipCode.zipCodeArea.deliveryDays.findIndex((deliveryDay) => {
                    let currentDay = String(di);
                    return deliveryDay === currentDay;
                });

                store.dispatch({ type: SET_SELECTED_INDEX, selectedIndex: selectedIndex });
                store.dispatch({
                    type: SET_NEXT_DELIVERY_DATE,
                    nextDeliveryDate: d.format("YYYY-MM-DD"),
                    forceUpdate: true,
                });
            }}
            filterDate={isDeliveryDay}
            customInput={<CustomInput />}
        />
    );
}
