import moment from "moment";
import React from "react";
import { View } from "react-native";
import { Divider, Text } from "react-native-elements";
import { orderTrackingStyles } from "./OrderTrackingStyles";

export default function OrderInformations(props) {
    return (
        <View>
            <View style={orderTrackingStyles.row}>
                <View style={orderTrackingStyles.rowLabel}>
                    <Text style={orderTrackingStyles.rowLabelText}>Status</Text>
                </View>
                <View style={orderTrackingStyles.rowValue}>
                    <Text>{props.data.orderStateString}</Text>
                </View>
            </View>
            <View style={orderTrackingStyles.row}>
                <View style={orderTrackingStyles.rowLabel}>
                    <Text style={orderTrackingStyles.rowLabelText}>Zahlungsweise</Text>
                </View>
                <View style={orderTrackingStyles.rowValue}>
                    <Text>{props.data.paymenTypeString}</Text>
                </View>
            </View>
        </View>
    );
}
