import React, { Component, useState } from "react";
import { Picker } from "@react-native-picker/picker";
import { View, Dimensions } from "react-native";
import { connect } from "react-redux";

import { updateAddress } from "../../store/actions/accountActions";
import { store } from "../../store/store";
import CustomInputField from "../../helpers/inputField/CustomInputField";
import * as yup from "yup";
import { getContrastColor } from "../../shared/helpers";
import { Button, Text } from "react-native-elements";
import { styles } from "../../shared/styles";
import { StyleSheet } from "react-native";
import { customerDataStyles } from "../CustomerData/CustomerDataStyles";

const yupObject = yup.object().shape({
    firstName: yup.string().required("Bitte einen Vornamen eingeben"),
    lastName: yup.string().required("Bitte einen Nachnamen eingeben"),
    zipCode: yup
        .string()
        .matches(/^\d{5}$/, "Ungültige Postleitzahl")
        .required("Bitte Postleitzahl eingeben"),
    city: yup.string().required("Bitte eine Stadt eingeben"),
    street: yup.string().required("Bitte den Straßennamen eingeben"),
    houseNumber: yup.string().required("Bitte geben Sie eine Hausnummer ein"),
});

class AddAddressPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: "",
            street: "",
            houseNumber: "",
            zipCode: "",
            firstName: "",
            lastName: "",
            title: "Herr",
            company: "",
            addressDetails: "",
            addressIndex: undefined,
            isDefaultDeliveryAddress: false,
            isDefaultBillingAddress: false,
            tempTitle: "Herr",
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            errors: {},
        };

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        const { customer } = this.props.account;
        Dimensions.addEventListener("change", this.updateDimensions);

        if (this.props.edit) {
            this.setState({
                street: this.props.addressToEdit.Street.name,
                city: this.props.addressToEdit.Street.City.name,
                company: this.props.addressToEdit.company,
                addressDetails: this.props.addressToEdit.addressDetails,
                houseNumber: this.props.addressToEdit.housenumber,
                zipCode: this.props.addressToEdit.Street.City.zipCode,
                title: this.props.addressToEdit.title,
                tempTitle: this.props.addressToEdit.title,
                firstName: this.props.addressToEdit.firstName,
                lastName: this.props.addressToEdit.lastName,
                isDefaultDeliveryAddress: this.props.addressToEdit.isDefaultDeliveryAddress,
                isDefaultBillingAddress: this.props.addressToEdit.isDefaultBillingAddress,
                addressIndex: this.props.addressIndex,
                addressUid: this.props.addressToEdit.addressUid,
            });
        }
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { visible, togglePopup, numberOfAddressesSaved, account, update, theme } = this.props;
        const { customer } = this.props.account;

        var hasErrors = {
            formErrors: true,
        };

        let data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            zipCode: this.state.zipCode,
            city: this.state.city,
            street: this.state.street,
            houseNumber: this.state.houseNumber,
        };

        this.setState({ errors: {} });
        yupObject
            .validate(data, { abortEarly: false })
            .then(() => {
                hasErrors.formErrors = false;

                let indexToUpdate = store
                    .dispatch(
                        updateAddress(
                            account.customer.customerUid,
                            this.state.title ? this.state.title : customer.title,
                            this.state.firstName ? this.state.firstName : customer.firstName,
                            this.state.lastName ? this.state.lastName : customer.familyName,
                            this.props.edit ? this.state.addressIndex : numberOfAddressesSaved,
                            this.state.city,
                            this.state.street,
                            this.state.houseNumber,
                            this.state.zipCode,
                            this.state.company,
                            this.state.addressDetails,
                            this.state.isDefaultBillingAddress,
                            this.state.isDefaultDeliveryAddress,
                            this.state.addressUid
                        )
                    )
                    .then(() => {
                        update();
                        togglePopup();
                    });
            })
            .catch((err) => {
                const errors = {};
                err.inner.forEach((errElement) => {
                    errors[errElement.path] = errElement.message;
                });
                this.setState({ errors });
                console.log("Fehler: ", errors);
            });
    };

    render() {
        const { visible, togglePopup, numberOfAddressesSaved, account, update, theme } = this.props;
        const { customer } = this.props.account;
        const { windowWidth, errors } = this.state;

        return (
            <>
                <View style={styles.overlayContainer}>
                    <Text h2>{this.props.edit ? "Adresse bearbeiten" : "Neue Adresse hinzufügen"}</Text>
                </View>
                <View>
                    <Text>Anrede</Text>
                    <View
                        key={this.state.addressIndex}
                        nativeID={this.state.addressIndex}
                        style={customerDataStyles.fieldRow}
                    >
                        <View style={errors ? styles.pickerErrorWrapper : styles.pickerWrapper}>
                            <Picker
                                key={this.state.addressIndex}
                                name={this.state.addressIndex}
                                onValueChange={(value) => {
                                    this.setState({ title: value });
                                    this.setState({ tempTitle: value });
                                }}
                                selectedValue={this.state.title}
                                // onBlur={props.handleBlur}
                                style={styles.picker}
                            >
                                <Picker.Item key={1} label="Frau" value="Frau" />
                                <Picker.Item key={2} label="Herr" value="Herr" />
                                <Picker.Item key={3} label="Divers" value="Divers" />
                            </Picker>
                        </View>
                        {errors && (
                            <View>
                                <Text style={styles.pickerError}>{errors.titel ? errors.titel : ""}</Text>
                            </View>
                        )}
                    </View>

                    <CustomInputField
                        name="Vorname"
                        label="Vorname"
                        margin="dense"
                        error={errors.firstName ? errors.firstName : ""}
                        onChange={(e) => this.setState({ firstName: e.target.value })}
                        value={this.state.firstName}
                    />

                    <CustomInputField
                        name="Nachname"
                        label="Nachname"
                        margin="dense"
                        error={errors.lastName ? errors.lastName : ""}
                        onChange={(e) => this.setState({ lastName: e.target.value })}
                        value={this.state.lastName}
                    />

                    <CustomInputField
                        name="Postleitzahl"
                        label="Postleitzahl"
                        margin="dense"
                        error={errors.zipCode ? errors.zipCode : ""}
                        onChange={(e) => this.setState({ zipCode: e.target.value })}
                        value={this.state.zipCode}
                    />

                    <CustomInputField
                        name="Stadt"
                        label="Stadt"
                        margin="dense"
                        error={errors.city ? errors.city : ""}
                        onChange={(e) => this.setState({ city: e.target.value })}
                        value={this.state.city}
                    />

                    <CustomInputField
                        name="Straße"
                        label="Straße"
                        margin="dense"
                        error={errors.street ? errors.street : ""}
                        onChange={(e) => this.setState({ street: e.target.value })}
                        value={this.state.street}
                    />

                    <CustomInputField
                        name="Hausnummer"
                        label="Hausnummer"
                        margin="dense"
                        error={errors.houseNumber ? errors.houseNumber : ""}
                        onChange={(e) => this.setState({ houseNumber: e.target.value })}
                        value={this.state.houseNumber}
                    />

                    <CustomInputField
                        name="Firma"
                        label="Firma"
                        margin="dense"
                        onChange={(e) => this.setState({ company: e.target.value })}
                        value={this.state.company}
                    />

                    <CustomInputField
                        name="Addreszusatz"
                        label="Addreszusatz"
                        margin="dense"
                        onChange={(e) => this.setState({ addressDetails: e.target.value })}
                        value={this.state.addressDetails}
                    />
                </View>
                <View
                    style={{
                        ...StyleSheet.flatten(styles.overlayButtonContainerFullRow),
                        flexDirection: this.props.windowWidth <= 756 ? "column" : "row",
                        marginRight: this.props.windowWidth <= 756 ? 0 : "10",
                        marginLeft: this.props.windowWidth <= 756 ? 0 : "10",
                    }}
                >
                    <View
                        style={{
                            marginRight: this.props.windowWidth <= 756 ? 0 : "2%",
                            marginLeft: this.props.windowWidth <= 756 ? 0 : "10",
                            marginBottom: this.props.windowWidth <= 756 ? 10 : 10,
                            width: this.props.windowWidth <= 756 ? "100%" : "48%",
                            flex: 1,
                        }}
                    >
                        <Button
                            title={"Abbrechen"}
                            titleStyle={{
                                color: getContrastColor(this.props.settings.store.accentColor),
                            }}
                            buttonStyle={{
                                backgroundColor: this.props.settings.store.notificationColor,
                                marginRight: 20,
                                paddingLeft: 15,
                                paddingRight: 15,
                            }}
                            uppercase={false}
                            onPress={this.props.togglePopup}
                        />
                    </View>
                    <View
                        style={{
                            marginBottom: this.props.windowWidth <= 756 ? 10 : 10,
                            width: this.props.windowWidth <= 756 ? "100%" : "48%",
                            flex: 1,
                        }}
                    >
                        <Button
                            title={this.props.edit ? "Änderungen speichern" : "Neue Adresse speichern"}
                            titleStyle={{
                                color: getContrastColor(this.props.settings.store.accentColor),
                            }}
                            buttonStyle={{
                                backgroundColor: this.props.settings.store.primaryColor,
                                paddingLeft: 15,
                                paddingRight: 15,
                            }}
                            onPress={this.handleSubmit}
                            uppercase={false}
                        />
                    </View>
                </View>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { account, settings } = state;
    return { account, settings };
}

export default connect(mapStateToProps)(AddAddressPopup);
