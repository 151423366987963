import { Platform, StyleSheet } from "react-native";

export const appColors = {
    primary: "#C81517",
    secondary: "#8F0CE8",
    white: "#ffffff",
    black: "#242424",
    grey0: "#393939",
    grey1: "#434343",
    grey2: "#5e5e5e",
    grey3: "#868686",
    grey4: "#bdbdbd",
    grey5: "#e1e1e1",
    greyOutline: "#bbb",
    searchBg: "#303030",
    success: "#52c41a",
    error: "#C81517",
    warning: "#faad14",
    disabled: "hsl(208, 8%, 90%)",
    // Darker color if hairlineWidth is not thin enough
    divider: StyleSheet.hairlineWidth < 1 ? "#bcbbc1" : "rgba(0, 0, 0, 0.12)",
    platform: {
        ios: {
            primary: "#007aff",
            secondary: "#5856d6",
            grey: "#7d7d7d",
            searchBg: "#dcdce1",
            success: "#4cd964",
            error: "#ff3b30",
            warning: "#ffcc00",
        },
        android: {
            primary: "#2196f3",
            secondary: "#9C27B0",
            grey: "rgba(0, 0, 0, 0.54)",
            success: "#4caf50",
            error: "#f44336",
            warning: "#ffeb3b",
        },
        web: {
            grey: "#7d7d7d",
        },
    },
};

export const styles = StyleSheet.create({
    contentContainer: {
        flex: 1,
        padding: 10,
    },
    mainContainer: {
        flex: 1,
        flexDirection: "row",
        maxHeight: Platform.OS === "web" ? "100vh" : "auto",
    },
    screenContainer: {
        flex: 1,
    },
    sidebarContainer: {
        width: 250,
        backgroundColor: appColors.grey5,
    },
    loadingContainer: {
        flex: 1,
        justifyContent: "center",
    },
    picker: {
        borderWidth: 0,
        color: "#000",
        minHeight: 40,
        fontSize: 16,
    },
    pickerError: {
        color: appColors.error,
        fontSize: 12,
        margin: 5,
    },
    pickerWrapper: {
        borderBottomWidth: 1,
        borderColor: "rgb(134, 147, 158)",
        transform: [{ translateX: 0 }],
        marginBottom: 10,
        width: "100%",
    },
    pickerErrorWrapper: {
        borderBottomWidth: 1,
        //transform: transform,
        marginBottom: 0,
        width: "100%",
    },
    overlayCloseIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 10,
        width: 30,
        height: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: appColors.grey5,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    headerContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    sideButton: {
        padding: 5,
        borderRadius: 25,
    },
    middleContainer: {
        flex: 1,
        padding: 10,
        justifyContent: "center",
        alignItems: "center",
    },
    buttonIcon: {
        marginRight: 0,
    },
    buttonText: {
        fontSize: 16,
        color: appColors.white,
    },
    headerText: {
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
    },
    overlayWrapper: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.7666)",
    },
    overlay: {
        backgroundColor: "white",
        borderRadius: 8,
        // width: "65vw",
        maxHeight: "80vh",
        overflow: "hidden",
    },
    overlayContent: {
        padding: 20,
        paddingRight: 30,
    },
    formContainer: {
        flex: 1,
        justifyContent: "center",
    },
    scrollContentContainer: {
        flexGrow: 1,
        paddingBottom: 50,
    },
    scrollContentContainerWithoutPadding: {
        flexGrow: 1,
    },
    closeButton: {
        position: "absolute",
        top: 10,
        right: 10,
        backgroundColor: "transparent",
        padding: 10,
        zIndex: 1,
    },
    overlayContainer: {
        width: "95%",
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: 1,
    },
    overlayButtonContainerFullRow: {
        justifyContent: "space-between",
        width: "100%",
        paddingRight: 10,
        paddingLeft: 10,
    },
});
