import React, { Component } from "react";
import { View } from "react-native";
import { Icon, Text } from "react-native-elements";
import AppHeader from "../../components/AppHeader";
import { Link } from "../../routers/routing";
import { appColors, styles } from "../../shared/styles";
import Cart from "./Cart";
import CartTotal from "./CartTotal";

export default class CartScreen extends Component {
    render() {
        return (
            <View style={{ flex: 1, backgroundColor: appColors.grey5 }}>
                <AppHeader
                    centerComponent={<Text style={{ color: "#fff" }}>Warenkorb</Text>}
                    leftComponent={
                        <Link to="/">
                            <Icon name="arrow-back" color="#fff" />
                        </Link>
                    }
                    rightComponent={<CartTotal />}
                    showOrderType={false}
                />
                <View style={styles.contentContainer}>
                    <Cart showCustomerData={this.props.showCustomerData} />
                </View>
            </View>
        );
    }
}
