import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { Image, Text } from "react-native-elements";
import { ActivityIndicator } from "react-native-web";

import Config from "../../../Config";
import { withRouter } from "../../routers/routing";
import { GetAdditivesForOnlineShop } from "../../store/actions/additiveActions";
import { store } from "../../store/store";
import Additives from "./Additives";
import { articleDetailsStyle } from "./ArticleDetailsStyle";
import Ingredients from "./Ingredients";

const config = new Config();

class ArticleDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleAdditives: [],
            loading: false,
        };
    }

    componentDidMount() {
        const { article } = this.props;
        this.setState({ loading: true });
        if (article) {
            store.dispatch(GetAdditivesForOnlineShop(article.articlesId)).then((articleAdditives) => {
                this.setState({ articleAdditives }, () => {
                    this.setState({ loading: false });
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { article } = this.props;
        if (article && article !== prevProps.article) {
            this.setState({ loading: true, articleAdditives: [] });
            store.dispatch(GetAdditivesForOnlineShop(article.articlesId)).then((articleAdditives) => {
                this.setState({ articleAdditives }, () => {
                    this.setState({ loading: false });
                });
            });
        }
    }

    render() {
        const { article } = this.props;
        return (
            <View>
                {article && (
                    <View style={{ position: "relative" }}>
                        <Image
                            source={config.imageStorageBlobURL + article.guidFileName}
                            containerStyle={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, width: "100%" }}
                            style={{ width: "100%", height: 300 }}
                        />
                        <View style={{ paddingTop: 20, paddingHorizontal: 20 }}>
                            <Text h2 h2Style={{ color: "#fff", fontSize: 18, fontWeight: "bold", marginBottom: 15 }}>
                                {article.name}
                            </Text>
                            {article.description && (
                                <Text style={StyleSheet.flatten(articleDetailsStyle.description)}>
                                    {article.description}
                                </Text>
                            )}
                            {article.description2 && (
                                <Text style={StyleSheet.flatten(articleDetailsStyle.description)}>
                                    {article.description2}
                                </Text>
                            )}
                            {article.description3 && (
                                <Text style={StyleSheet.flatten(articleDetailsStyle.description)}>
                                    {article.description3}
                                </Text>
                            )}
                        </View>
                        <View style={articleDetailsStyle.additionalInformation}>
                            {this.state.articleAdditives.length > 0 && (
                                <Additives additives={this.state.articleAdditives} />
                            )}
                            {this.state.loading && (
                                <ActivityIndicator size={"large"} style={{ marginTop: 10 }} animated />
                            )}
                            {article.Ingredients.length > 0 && <Ingredients ingredients={article.Ingredients} />}
                        </View>
                    </View>
                )}
            </View>
        );
    }
}

export default withRouter(ArticleDetails);
