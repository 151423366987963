import { combineReducers } from "redux";
import customer from "./customerReducer";
import weeklyPlan from "./weeklyPlanReducer";
import settings from "./settingsReducer";
import account from "./accountReducer";
import cart from "./cartReducer";
import order from "./orderReducer";
import orderStatus from "./orderStatusReducer";
import zipCode from "./zipCodeReducer";
import theme from "./themeReducer";
import AsyncStorage from "@react-native-community/async-storage";
import { persistReducer } from "redux-persist";

const customerPersistConfig = {
    key: "customer",
    storage: AsyncStorage,
    blacklist: ["orderTypes"],
};

const rootReducer = combineReducers({
    settings,
    customer: persistReducer(customerPersistConfig, customer),
    weeklyPlan,
    account,
    cart,
    order,
    zipCode,
    orderStatus,
    theme,
});

export default rootReducer;
