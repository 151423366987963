import React, { useLayoutEffect, useState } from "react";
import { Dimensions } from "react-native";
import * as yup from "yup";

export const possibleOrderDayValue = 24;

export function useWindowSize() {
    const [size, setSize] = useState([Dimensions.get("window").width, Dimensions.get("window").height]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([Dimensions.get("window").width, Dimensions.get("window").height]);
        }
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

export const withHooksHOC = (Component) => (props) => {
    const windowSize = useWindowSize();

    return <Component windowSize={windowSize} {...props} />;
};

export function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
        return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
        const { params, type } = validation;
        if (!validator[type]) {
            return;
        }
        validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
}

export function HSLToHex(hsl) {
    if (hsl) {
        if (hsl === undefined) return "#000000";
        if (hsl === null) return "#000000";
        if (hsl === "#000000") return hsl;

        hsl = "hsl(" + hsl + ")";
        let sep = hsl.indexOf(",") > -1 ? "," : " ";
        hsl = hsl.substr(4).split(")")[0].split(sep);
        let h = hsl[0],
            s = hsl[1].substr(0, hsl[1].length - 1) / 100,
            l = hsl[2].substr(0, hsl[2].length - 1) / 100;

        // Strip label and convert to degrees (if necessary)
        if (h.indexOf("deg") > -1) h = h.substr(0, h.length - 3);
        else if (h.indexOf("rad") > -1) h = Math.round(h.substr(0, h.length - 3) * (180 / Math.PI));
        else if (h.indexOf("turn") > -1) h = Math.round(h.substr(0, h.length - 4) * 360);
        if (h >= 360) h %= 360;

        let c = (1 - Math.abs(2 * l - 1)) * s,
            x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
            m = l - c / 2,
            r = 0,
            g = 0,
            b = 0;

        if (0 <= h && h < 60) {
            r = c;
            g = x;
            b = 0;
        } else if (60 <= h && h < 120) {
            r = x;
            g = c;
            b = 0;
        } else if (120 <= h && h < 180) {
            r = 0;
            g = c;
            b = x;
        } else if (180 <= h && h < 240) {
            r = 0;
            g = x;
            b = c;
        } else if (240 <= h && h < 300) {
            r = x;
            g = 0;
            b = c;
        } else if (300 <= h && h < 360) {
            r = c;
            g = 0;
            b = x;
        }
        // Having obtained RGB, convert channels to hex
        r = Math.round((r + m) * 255).toString(16);
        g = Math.round((g + m) * 255).toString(16);
        b = Math.round((b + m) * 255).toString(16);

        // Prepend 0s, if necessary
        if (r.length === 1) r = "0" + r;
        if (g.length === 1) g = "0" + g;
        if (b.length === 1) b = "0" + b;

        return "#" + r + g + b;
    } else return "#000000";
}

/* Get readable color */
export function getContrastColor(backgroundHexColor) {
    if (backgroundHexColor === undefined) return "#ffffff";
    const hexToRgb = (hex) => ({
        r: parseInt(hex.substr(1, 2), 16),
        g: parseInt(hex.substr(3, 2), 16),
        b: parseInt(hex.substr(5, 2), 16),
    });

    const rgbToLuminance = (rgb) => {
        const sRgb = (c) => {
            const v = c / 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        };
        return 0.2126 * sRgb(rgb.r) + 0.7152 * sRgb(rgb.g) + 0.0722 * sRgb(rgb.b);
    };

    const backgroundRgb = hexToRgb(backgroundHexColor);
    const backgroundLuminance = rgbToLuminance(backgroundRgb);

    const getContrastColorBasedOnLuminance = (luminance) => {
        return luminance > 0.179 ? "#000000" : "#FFFFFF";
    };

    return getContrastColorBasedOnLuminance(backgroundLuminance);
}

/** Get inverted Color */
// export function getContrastColor(hex) {
//     if (hex[0] === "#") {
//         hex = hex.slice(1);
//     }

//     const r = parseInt(hex.slice(0, 2), 16);
//     const g = parseInt(hex.slice(2, 4), 16);
//     const b = parseInt(hex.slice(4, 6), 16);

//     const invertedR = 255 - r;
//     const invertedG = 255 - g;
//     const invertedB = 255 - b;

//     const invertedColor = "#" + ((1 << 24) + (invertedR << 16) + (invertedG << 8) + invertedB).toString(16).slice(1);

//     return invertedColor;
// }

/** get Complementary Color */
// function RGBtoHSL(rgb) {
//     let r = rgb[0] / 255;
//     let g = rgb[1] / 255;
//     let b = rgb[2] / 255;
//     let max = Math.max(r, g, b);
//     let min = Math.min(r, g, b);
//     let h,
//         s,
//         l = (max + min) / 2;

//     if (max === min) {
//         h = s = 0;
//     } else {
//         let d = max - min;
//         s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
//         switch (max) {
//             case r:
//                 h = (g - b) / d + (g < b ? 6 : 0);
//                 break;
//             case g:
//                 h = (b - r) / d + 2;
//                 break;
//             case b:
//                 h = (r - g) / d + 4;
//                 break;
//         }
//         h /= 6;
//     }
//     return [h, s, l];
// }

// function HSLtoRGB(hsl) {
//     let h = hsl[0];
//     let s = hsl[1];
//     let l = hsl[2];
//     let r, g, b;

//     if (s === 0) {
//         r = g = b = l; // achromatic
//     } else {
//         function hue2rgb(p, q, t) {
//             if (t < 0) t += 1;
//             if (t > 1) t -= 1;
//             if (t < 1 / 6) return p + (q - p) * 6 * t;
//             if (t < 1 / 2) return q;
//             if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
//             return p;
//         }

//         let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
//         let p = 2 * l - q;
//         r = hue2rgb(p, q, h + 1 / 3);
//         g = hue2rgb(p, q, h);
//         b = hue2rgb(p, q, h - 1 / 3);
//     }
//     return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
// }

// export function getContrastColor(hexColor) {
//     let r = parseInt(hexColor.slice(1, 3), 16);
//     let g = parseInt(hexColor.slice(3, 5), 16);
//     let b = parseInt(hexColor.slice(5, 7), 16);

//     let hsl = RGBtoHSL([r, g, b]);

//     hsl[0] = (hsl[0] + 0.5) % 1;

//     let rgb = HSLtoRGB(hsl);

//     return (
//         "#" +
//         rgb
//             .map((x) => {
//                 let hex = x.toString(16);
//                 return hex.length === 1 ? "0" + hex : hex;
//             })
//             .join("")
//     );
// }
