import { SET_CURRENT_WEEKLY_PLANS_ID } from "../actions/weeklyPlanActions";

const initialState = {
    weeklyPlansId: undefined
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_WEEKLY_PLANS_ID:
            return Object.assign({}, state, {
                weeklyPlansId: action.weeklyPlansId
            })
        default:
            return state;
    }
}