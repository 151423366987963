import React, { Component } from "react";
import { getContrastColor } from "../../shared/helpers";
import { Button, Text } from "react-native-elements";
import { View } from "react-native";
import { styles } from "../../shared/styles";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

class ConfirmDeletePopup extends Component {
    render() {
        return (
            <>
                <View style={styles.overlayContainer}>
                    <Text h2>Diese Adresse wirklich löschen?</Text>
                </View>
                <View
                    style={{
                        ...StyleSheet.flatten(styles.overlayButtonContainerFullRow),
                        flexDirection: this.props.windowWidth <= 756 ? "column" : "row",
                        marginRight: this.props.windowWidth <= 756 ? 0 : "10",
                        marginLeft: this.props.windowWidth <= 756 ? 0 : "10",
                    }}
                >
                    <View
                        style={{
                            marginRight: this.props.windowWidth <= 756 ? 0 : "2%",
                            marginLeft: this.props.windowWidth <= 756 ? 0 : "10",
                            marginBottom: this.props.windowWidth <= 756 ? 10 : 10,
                            width: this.props.windowWidth <= 756 ? "100%" : "48%",
                            flex: 1,
                        }}
                    >
                        <Button
                            title={"Nein"}
                            titleStyle={{
                                color: getContrastColor(this.props.settings.store.accentColor),
                            }}
                            buttonStyle={{
                                backgroundColor: this.props.settings.store.accentColor,
                                marginRight: 20,
                                paddingLeft: 15,
                                paddingRight: 15,
                            }}
                            uppercase={false}
                            onPress={this.props.togglePopup}
                        />
                    </View>
                    <View
                        style={{
                            marginBottom: this.props.windowWidth <= 756 ? 10 : 10,
                            width: this.props.windowWidth <= 756 ? "100%" : "48%",
                            flex: 1,
                        }}
                    >
                        <Button
                            title={"Ja"}
                            titleStyle={{
                                color: getContrastColor(this.props.settings.store.accentColor),
                            }}
                            buttonStyle={{
                                backgroundColor: this.props.settings.store.notificationColor,
                                paddingLeft: 15,
                                paddingRight: 15,
                            }}
                            onPress={() => {
                                this.props.delete();
                                this.props.togglePopup();
                            }}
                            uppercase={false}
                        />
                    </View>
                </View>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}

export default connect(mapStateToProps)(withRouter(ConfirmDeletePopup));
