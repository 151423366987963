export function loadFont(fonts) {
    var fontStyleElement = document.createElement("style");
    fontStyleElement.textContent = `
        @font-face {
            font-family: "regular";
            src: url("${fonts.regularFontPath}") format("woff2");
            font-weight: ${fonts.regularFontWeight};
            font-style: normal;
        }
        @font-face {
            font-family: "medium";
            src: url("${fonts.mediumFontPath}") format("woff2");
            font-weight: ${fonts.mediumFontWeight};
            font-style: normal;
        }
        @font-face {
            font-family: "light";
            src: url("${fonts.lightFontPath}") format("woff2");
            font-weight: ${fonts.lightFontWeight};
            font-style: normal;
        }
        @font-face {
            font-family: "thin";
            src: url("${fonts.thinFontPath}") format("woff2");
            font-weight: ${fonts.thinFontWeight};
            font-style: normal;
        }`;
    fontStyleElement.id = "fontCSS";
    document.head.appendChild(fontStyleElement);
}
