import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export const SET_ZIPCODE = "SET_ZIPCODE";
export const SET_ZIPCODEAREA = "SET_ZIPCODEAREA";
export const SET_NEXT_DELIVERY_DATE = "SET_NEXT_DELIVERY_DATE";
export const SET_SELECTED_INDEX = "SET_SELECTED_INDEX";

export function getZipCodes() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/ZipCodeArea/getZipCodes", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
