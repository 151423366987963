import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export function GetAdditivesForOnlineShop(articlesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ articlesId: articlesId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/OnlineShop/GetAdditivesForOnlineShop", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
