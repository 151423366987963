import { SET_CUSTOMER_DATA, SET_ORDERTYPE, SET_ORDERTYPE_BY_ID } from "../actions/customerActions";

const initialState = {
    orderTypes: [
        {
            id: 1,
            name: "Lieferung",
            isDelivery: true,
            bookImmediately: false,
        },
        {
            id: 2,
            name: "Abholung",
            isDelivery: false,
            bookImmediately: false,
        },
        {
            id: 3,
            name: "Restaurant",
            isDelivery: false,
            bookImmediately: false,
        },
    ],
    orderType: {
        id: 3,
        name: "Restaurant",
        isDelivery: false,
        bookImmediately: false,
    },
    customerData: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDERTYPE:
            return Object.assign({}, state, {
                orderType: action.orderType,
            });
        case SET_ORDERTYPE_BY_ID:
            let newState = Object.assign({}, state);
            let orderTypeIndex = newState.orderTypes.findIndex((x) => {
                if (x) {
                    return x.id === parseInt(action.orderTypeId);
                } else {
                    return false;
                }
            });
            if (newState.orderTypes[orderTypeIndex]) {
                newState.orderType = newState.orderTypes[orderTypeIndex];
            }
            return newState;
        case SET_CUSTOMER_DATA:
            return Object.assign({}, state, {
                customerData: action.customerData,
            });
        default:
            return state;
    }
};
