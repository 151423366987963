import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
    let u = localStorage.getItem("customersUid");
    if (Component) {
        return (
            <Route
                {...rest}
                render={(props) => {
                    return u ? <Component {...props} /> : <Redirect to={{ pathname: "/" }} />;
                }}
            />
        );
    } else {
        if (u) {
            return <Route {...rest} />;
        } else {
            return <Redirect to={{ pathname: "/" }} />;
        }
    }
};
