import AsyncStorage from "@react-native-community/async-storage";
import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

// import { rootReducer } from "./reducers";
import rootReducer from "./reducers/rootReducer";

const persistConfig = {
    key: "root",
    storage: AsyncStorage,
    // blacklist: ["customer", "weeklyPlan", "settings"],
    blacklist: ["weeklyPlan", "settings"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk];

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));

export const persistor = persistStore(store);
