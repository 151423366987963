import React, { useState, useEffect, useRef } from "react";
import { View, Text, StyleSheet } from "react-native";
import { Button, Icon } from "react-native-elements";

const CustomTooltipIconButton = ({
    icon,
    tooltipText,
    onButtonClick,
    iconColor = "white",
    containerStyle,
    toolTipPosition = "under",
}) => {
    const [isHovering, setIsHovering] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            const element = ref.current;

            const handleMouseEnter = () => setIsHovering(true);
            const handleMouseLeave = () => setIsHovering(false);

            element.addEventListener("mouseenter", handleMouseEnter);
            element.addEventListener("mouseleave", handleMouseLeave);

            return () => {
                element.removeEventListener("mouseenter", handleMouseEnter);
                element.removeEventListener("mouseleave", handleMouseLeave);
            };
        }
    }, [ref]);

    return (
        <View ref={ref} style={[styles.defaultContainer, containerStyle]}>
            <Button
                type="clear"
                icon={<Icon name={icon} type="font-awesome" size={15} color={iconColor} />}
                onPress={onButtonClick}
            />
            {isHovering && (
                <View style={toolTipPosition === "under" ? styles.tooltip : styles.toolTipAbove}>
                    <Text style={styles.tooltipText} numberOfLines={2}>
                        {tooltipText}
                    </Text>
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    defaultContainer: {
        position: "relative",
        zIndex: 500,
    },
    tooltip: {
        position: "absolute",
        bottom: "-10px",
        left: "50%",
        transform: [{ translateX: "-50%" }],
        padding: 5,
        backgroundColor: "rgba(0,0,0,0.7)",
        borderRadius: 4,
        zIndex: 1000,
    },
    toolTipAbove: {
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: [{ translateX: "-50%" }],
        padding: 5,
        backgroundColor: "rgba(0,0,0,0.7)",
        borderRadius: 4,
        zIndex: 1000,
    },
    tooltipText: {
        color: "white",
        fontSize: 12,
        textAlign: "center",
    },
});

export default CustomTooltipIconButton;
