import {
    ADD_ORDER_ITEM, REMOVE_ORDER_ITEM, REMOVE_ALL_ORDER_ITEMS, UPDATE_ORDER_ITEM,
} from '../actions/cartActions';

const initialState = {
    orderItems: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case ADD_ORDER_ITEM: {
        const newOrderItem = action.orderItem;
        newOrderItem.orderItemId = state.orderItems.length;
        return { ...state, orderItems: [...state.orderItems, newOrderItem] };
    }
    case REMOVE_ORDER_ITEM: {
        const newState = { ...state };
        const orderItemToRemove = newState.orderItems.findIndex((x) => {
            if (x) {
                return x.orderItemId === action.orderItem.orderItemId;
            }
            return false;
        });
        if (newState.orderItems[orderItemToRemove]) {
            newState.orderItems.splice(orderItemToRemove, 1);
        }
        return newState;
    }
    case UPDATE_ORDER_ITEM: {
        const newState = { ...state };
        const orderItemToUpdate = newState.orderItems.findIndex((x) => {
            if (x) {
                return x.orderItemId === action.orderItem.orderItemId;
            }
            return false;
        });
        if (newState.orderItems[orderItemToUpdate]) {
            newState.orderItems[orderItemToUpdate] = action.orderItem;
        }
        return newState;
    }
    case REMOVE_ALL_ORDER_ITEMS:
        return { ...state, orderItems: [] };
    default:
        return state;
    }
};
