import moment from "moment";

export function getArticleSizesByOrderType(article, orderType) {
    let articleSizes = [];
    let i = 0;
    let hasPrice = null;

    if (article) {
        if (article.ArticleSizes.length > 0) {
            while (article.ArticleSizes[i]) {
                hasPrice = articleSizeHasPriceForOrderType(article.ArticleSizes[i], orderType);

                if (hasPrice) {
                    articleSizes.push(article.ArticleSizes[i]);
                }

                i++;
            }
        }
    }

    return articleSizes;
}

export function articleSizeHasPriceForOrderType(articleSize, orderType) {
    let hasPrice = false;
    let i = 0;
    if (articleSize && orderType) {
        if (articleSize.Prices.length > 0) {
            while (articleSize.Prices[i]) {
                /**
                 * Todo: make the orderType dynamic
                 */
                if (articleSize.Prices[i].orderTypesId === 1) {
                    hasPrice = true;
                }
                i++;
            }
        }
    }

    return hasPrice;
}

export function getPriceByArticleSizeOrderType(articleSize, orderType) {
    let price = null;
    let i = 0;

    if (articleSize && orderType) {
        if (articleSize.Prices) {
            if (articleSize.Prices.length > 0) {
                while (articleSize.Prices[i]) {
                    /**
                     * Todo: make the orderType dynamic
                     */
                    if (articleSize.Prices[i].orderTypesId === 1) {
                        price = articleSize.Prices[i];
                    }

                    i++;
                }
            }
        }
    }

    return price;
}

export function checkArticleAvailablies(article, validOnDay) {
    let articleAvailable = false;
    let articleValidDay = moment(validOnDay);
    let now = moment();

    articleAvailable = now.isBefore(articleValidDay);

    return articleAvailable;
}
