import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { View } from "react-native";
import { Badge, Button, Icon, Text } from "react-native-elements";
import MaterialCommunityIcon from "react-native-vector-icons/MaterialCommunityIcons";
import { Dimensions } from "react-native-web";
import { connect } from "react-redux";
import AppHeader from "../../components/AppHeader";
import { Link } from "../../routers/routing";
import { appColors } from "../../shared/styles";
import { SET_ZIPCODE } from "../../store/actions/zipCodeActions";
import { store } from "../../store/store";
import DeliveryDateField from "./DeliveryDateField";
import { weeklyPlanStyles } from "./WeeklyPlanStyles";
import { StyleSheet } from "react-native";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
class WeeklyPlanHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            weeklyPlanTitle: "",
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        };

        this.formattedWeeklyPlanTitle = this.formattedWeeklyPlanTitle.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.cartIcon = this.cartIcon.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        if (this.props.weeklyPlanDate) {
            if (prevProps.weeklyPlanDate !== this.props.weeklyPlanDate) {
                this.setState({
                    weeklyPlanTitle: this.formattedWeeklyPlanTitle(),
                });
            }
        }
    }

    componentWillUnmount() {
        Dimensions.removeEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({ windowWidth: Dimensions.get("window").width, windowHeight: Dimensions.get("window").height });
    }

    formattedWeeklyPlanTitle() {
        let deliveryDate =
            this.props.zipCode && this.props.zipCode.nextDeliveryDate ? this.props.zipCode.nextDeliveryDate : "";

        if (deliveryDate instanceof moment) {
            deliveryDate = deliveryDate.toISOString();
        }
        return (
            <View style={weeklyPlanStyles.headerContainer}>
                <MaterialCommunityIcon
                    onPress={this.props.goToPrevWeeklyPlan}
                    style={weeklyPlanStyles.headerArrowPrev}
                    name="arrow-left-circle-outline"
                    color={appColors.white}
                    size={20}
                />
                <Text style={{ color: appColors.white }}>Lieferung am </Text>
                {/* {deliveryDate} */}
                <DeliveryDateField deliveryDate={deliveryDate} />
                <Text style={{ color: appColors.white }}>
                    {this.props.zipCode.zipCodeArea.estimatedDeliveryTime
                        ? " circa " + this.props.zipCode.zipCodeArea.estimatedDeliveryTime
                        : ""}
                    nach
                </Text>
                <Button
                    title={this.props.zipCode.zipCode}
                    onPress={this.editZipCode}
                    type="outline"
                    iconRight
                    titleStyle={{ color: appColors.white }}
                    buttonStyle={{
                        borderColor: appColors.white,
                        borderStyle: "dashed",
                        paddingVertical: 0,
                        paddingHorizontal: 5,
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        minHeight: 24,
                    }}
                    icon={
                        <MaterialCommunityIcon
                            name="home-edit-outline"
                            style={{ paddingLeft: 5 }}
                            color={appColors.white}
                            size={15}
                        />
                    }
                />
                {/* <View style={weeklyPlanStyles.inlineEdit}>
                    <Text style={{ color: appColors.white }}>{this.props.zipCode.zipCode}</Text>
                    <MaterialCommunityIcon
                        onPress={this.editZipCode}
                        style={weeklyPlanStyles.headerEdit}
                        name="home-edit-outline"
                        color={appColors.white}
                        size={14}
                    />
                </View> */}
                <MaterialCommunityIcon
                    onPress={this.props.goToNextWeeklyPlan}
                    style={weeklyPlanStyles.headerArrowNext}
                    name="arrow-right-circle-outline"
                    color={appColors.white}
                    size={20}
                />
            </View>
        );
    }

    editZipCode() {
        store.dispatch({ type: SET_ZIPCODE, zipCode: null });
    }

    cartIcon() {
        const icon = (
            <Link style={{ textDecoration: "none" }} to="/cart">
                <Icon name="shopping-cart" type="font-awesome" size={20} color={appColors.white} />
                {this.props.cart.orderItems.length > 0 && (
                    <Badge
                        value={this.props.cart.orderItems.length}
                        status="success"
                        containerStyle={{ position: "absolute", top: 8, left: -4 }}
                    />
                )}
            </Link>
        );

        return this.state.windowWidth < 768 ? icon : undefined;
    }

    render() {
        const { theme } = this.props;
        return (
            <>
                <AppHeader
                    centerComponent={this.props.orderTracking ? undefined : this.formattedWeeklyPlanTitle()}
                    orderTracking={this.props.OrderTracking}
                    leftComponent={
                        <div
                            style={StyleSheet.flatten(weeklyPlanStyles.headerLogo)}
                            onClick={() => this.props.history.push("/")}
                        >
                            {theme.theme.logoUrl && (
                                <img
                                    src={theme.theme.logoUrl}
                                    style={StyleSheet.flatten(weeklyPlanStyles.headerImg)}
                                    alt={theme.theme.title}
                                />
                            )}
                            <Text
                                h1
                                h1Style={{
                                    color: appColors.white,
                                    marginBottom: 0,
                                    lineHeight: "100%",
                                }}
                            >
                                {theme.theme.title}
                            </Text>
                        </div>
                    }
                    rightComponent={this.cartIcon()}
                    showZipCode={this.props.showZipCode}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    const { cart, zipCode, theme } = state;
    return { cart, zipCode, theme };
}

export default connect(mapStateToProps)(withRouter(WeeklyPlanHeader));
