import { StyleSheet } from "react-native";

export const customerStyle = StyleSheet.create({
    card: {
        // alignItems: "center",
        alignItems: "stretch", // Änderung hier
        borderRadius: 10,
        // cursor: "pointer",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        margin: 5,
        marginLeft: 10,
        marginRight: 10,
        minHeight: 200,
    },
    cardTouchable: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        flex: 1,
        flexWrap: "wrap",
        height: "100%",
        justifyContent: "center",
        margin: 5,
        minHeight: 200,
        width: "100%",
    },
    cardInnerContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    // addressCardWrapper: {
    //     paddingLeft: 20,
    //     paddingRight: 20,
    //     paddingTop: 15,
    //     paddingBottom: 15,
    //     width: "100%",
    // },
    addressCardWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        paddingBottom: 15,
        height: "100%",
        width: "100%",
    },
    addressCardInnerContainer: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
    },
});
