import Color from "color";

import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";
import { store } from "../store";

const config = new Config();

export const SET_THEME = "SET_THEME";

export function set_theme(json) {
    let root = document.documentElement;
    document.title = json.title;
    if (json) {
        const theme = {};
        theme.URL = json.URL;
        theme.logoGuid = json.logoGuid;
        theme.title = json.title;
        theme.logoUrl = json.logoUrl;
        theme.customCSS = json.customCSS;
        theme.colors = {
            primaryColor: json.primary,
            accentColor: json.accentColor,
            accentColorl44: adjustColor(json.accentColor, 44),
            accentColorl14: adjustColor(json.accentColor, 14),
            accentColords8l10: adjustColor(json.accentColor, 10, -8),
            accentColordl6: adjustColor(json.accentColor, -6),
            accentColordl8: adjustColor(json.accentColor, -8),
            accentColordl10: adjustColor(json.accentColor, -10),
            accentColordl12: adjustColor(json.accentColor, -12),
            accentColordl16: adjustColor(json.accentColor, -16),
            accentColordl24: adjustColor(json.accentColor, -24),
            textColor: json.textColor,
            backgroundColor: json.backgroundColor,
            borderColor: json.borderColor,
            successColor: json.successColor,
            successColordl4: adjustColor(json.successColor, -4),
            successColordl8: adjustColor(json.successColor, -8),
            successColordl10: adjustColor(json.successColor, -10),
            successColordl12: adjustColor(json.successColor, -12),
            successColordl16: adjustColor(json.successColor, -16),
            successColordl20: adjustColor(json.successColor, -20),
            successColordl24: adjustColor(json.successColor, -24),
            warningColor: json.warningColor,
            notificationColor: json.notification,
            dangerColor: json.dangerColor,
            dangerColorl16: adjustColor(json.dangerColor, 16),
            dangerColordl4: adjustColor(json.dangerColor, -4),
            dangerColordl8: adjustColor(json.dangerColor, -8),
            dangerColordl10: adjustColor(json.dangerColor, -10),
            dangerColordl12: adjustColor(json.dangerColor, -12),
            dangerColordl16: adjustColor(json.dangerColor, -16),
            dangerColordl20: adjustColor(json.dangerColor, -20),
            dangerColordl24: adjustColor(json.dangerColor, -24),
        };
        theme.fonts = {
            regular: {
                fontFamily: json.fonts.regular.fontFamily,
                fontWeight: json.fonts.regular.fontWeight,
            },
            medium: {
                fontFamily: json.fonts.medium.fontFamily,
                fontWeight: json.fonts.medium.fontWeight,
            },
            light: {
                fontFamily: json.fonts.light.fontFamily,
                fontWeight: json.fonts.light.fontWeight,
            },
            thin: {
                fontFamily: json.fonts.thin.fontFamily,
                fontWeight: json.fonts.thin.fontWeight,
            },
        };

        root.removeAttribute("style");

        root.style.setProperty("--accent-color", theme.colors.accentColor);
        root.style.setProperty("--accent-color-l-44", theme.colors.accentColorl44);
        root.style.setProperty("--accent-color-l-14", theme.colors.accentColorl14);
        root.style.setProperty("--accent-color-ds-8-l-10", theme.colors.accentColords8l10);
        root.style.setProperty("--accent-color-dl-6", theme.colors.accentColordl6);
        root.style.setProperty("--accent-color-dl-8", theme.colors.accentColordl8);
        root.style.setProperty("--accent-color-dl-10", theme.colors.accentColordl10);
        root.style.setProperty("--accent-color-dl-12", theme.colors.accentColordl12);
        root.style.setProperty("--accent-color-dl-16", theme.colors.accentColordl16);
        root.style.setProperty("--accent-color-dl-24", theme.colors.accentColordl24);
        root.style.setProperty("--text-color", theme.colors.textColor);
        root.style.setProperty("--background-color", theme.colors.backgroundColor);
        root.style.setProperty("--border-color", theme.colors.borderColor);
        root.style.setProperty("--success-color", theme.colors.successColor);
        root.style.setProperty("--success-color-dl-4", theme.colors.successColordl4);
        root.style.setProperty("--success-color-dl-8", theme.colors.successColordl8);
        root.style.setProperty("--success-color-dl-10", theme.colors.successColordl10);
        root.style.setProperty("--success-color-dl-12", theme.colors.successColordl12);
        root.style.setProperty("--success-color-dl-16", theme.colors.successColordl16);
        root.style.setProperty("--success-color-dl-20", theme.colors.successColordl20);
        root.style.setProperty("--success-color-dl-24", theme.colors.successColordl24);
        root.style.setProperty("--warning-color", theme.colors.warningColor);
        root.style.setProperty("--danger-color", theme.colors.dangerColor);
        root.style.setProperty("--danger-color-l-16", theme.colors.dangerColorl16);
        root.style.setProperty("--danger-color-dl-4", theme.colors.dangerColordl4);
        root.style.setProperty("--danger-color-dl-8", theme.colors.dangerColordl8);
        root.style.setProperty("--danger-color-dl-10", theme.colors.dangerColordl10);
        root.style.setProperty("--danger-color-dl-12", theme.colors.dangerColordl12);
        root.style.setProperty("--danger-color-dl-16", theme.colors.dangerColordl16);
        root.style.setProperty("--danger-color-dl-20", theme.colors.dangerColordl20);
        root.style.setProperty("--danger-color-dl-24", theme.colors.dangerColordl24);

        if (document.getElementById("customCSS")) {
            document.getElementById("customCSS").remove();
        }

        const customStyle = document.createElement("style");
        customStyle.setAttribute("id", "customCSS");
        document.head.appendChild(customStyle);
        customStyle.innerHTML = theme.customCSS ? theme.customCSS : "";

        return {
            type: SET_THEME,
            theme: theme,
        };
    }

    return { type: SET_THEME };
}

export function getShopTheme() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Theme/getShopTheme", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => store.dispatch(set_theme(json)));
    };
}

function adjustColor(hsl = "0,0%,0%", l = undefined, s = undefined) {
    if (hsl.at(0) == "#") {
        return hsl;
    }

    let color = new Color("hsl(" + hsl + ")").round(0);

    let hue = color.array()[0];
    let saturation = color.array()[1];
    let lightness = color.array()[2];

    if (l) {
        lightness = lightness + l;
    }

    if (s) {
        saturation = saturation + s;
    }

    return hue.toString() + ", " + saturation.toString() + "%, " + lightness.toString() + "%";
}
