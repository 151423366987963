import { get } from "fetch-mock";
import React, { Component } from "react";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { Card, Text } from "react-native-elements";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

import Config from "../../../Config";
import { getContrastColor, HSLToHex } from "../../shared/helpers";
import { getArticleSizesByOrderType, getPriceByArticleSizeOrderType } from "../../shared/menucard";
import { appColors } from "../../shared/styles";
import { ADD_ORDER_ITEM } from "../../store/actions/cartActions";
import { store } from "../../store/store";
import ChooseDayToCook from "./ChooseDayToCook";
import EntrySizeSelect from "./EntrySizeSelect";
import { weeklyPlanStyles } from "./WeeklyPlanStyles";

const config = new Config();

class WeeklyPlanArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            articleSizes: getArticleSizesByOrderType(this.props.article, this.props.customer.orderType),
            selectedArticleSize: null,
            selectedArticlePrice: null,
            articleAvailable: true,
            ingredientArticles: [],
            dayToCook: new Date().getTime(),
            chooseDayToCookVisible: false,
        };

        this.addOrderItem = this.addOrderItem.bind(this);
        this.setArticlePrice = this.setArticlePrice.bind(this);
        this.setSelectedArticleSize = this.setSelectedArticleSize.bind(this);
        this.toggleChooseDayToCook = this.toggleChooseDayToCook.bind(this);
        this.setDayToCook = this.setDayToCook.bind(this);
        this.setTime = this.setTime.bind(this);
    }

    componentDidMount() {
        if (this.state.articleSizes.length > 0) {
            this.setState(
                {
                    selectedArticleSize: this.state.articleSizes[0],
                },
                this.setArticlePrice(this.state.articleSizes[0], this.props.customer.orderType)
            );
        }
    }

    setArticlePrice(articleSize, orderType) {
        let price = null;

        if (articleSize) {
            price = getPriceByArticleSizeOrderType(articleSize, orderType);
        }

        this.setState({
            selectedArticlePrice: price,
        });
    }

    setSelectedArticleSize(articleSizesId) {
        let selectedArticleSize = null;
        let i = 0;
        const newArticleSizesId = parseInt(articleSizesId, 10);

        while (!selectedArticleSize) {
            if (this.state.articleSizes[i].articleSizesId === newArticleSizesId) {
                selectedArticleSize = this.state.articleSizes[i];
            }

            i++;
        }

        this.setState(
            {
                selectedArticleSize,
            },
            this.setArticlePrice(selectedArticleSize, this.props.customer.orderType)
        );
    }

    setDayToCook(day) {
        const { dayToCook } = this.state;
        if (day !== dayToCook) {
            this.setState({
                dayToCook: day,
            });
        }
    }

    setTime(time) {
        let date = new Date(time);

        this.setState({ dayToCook: date });
    }

    toggleChooseDayToCook() {
        this.setState({
            chooseDayToCookVisible: !this.state.chooseDayToCookVisible,
        });
    }

    addOrderItem(entry) {
        const orderItem = {
            entry,
            weeklyPlanComponent: this.props.weeklyPlanComponent,
            ArticleSize: this.state.selectedArticleSize,
            Price: this.state.selectedArticlePrice,
            orderType: this.props.customer.orderType,
            dayToCook: this.state.dayToCook,
            weeklyPlanDay: this.state.dayToCook,
            requestedDeliveryTime: this.state.requestedDeliveryTime,
        };

        orderItem.entry.amount = 1;

        store.dispatch({ type: ADD_ORDER_ITEM, orderItem });
    }

    render() {
        return (
            <React.Fragment>
                <View nativeID="article" style={weeklyPlanStyles.articleWrapper}>
                    <Image
                        style={weeklyPlanStyles.articleImage}
                        source={config.imageStorageBlobURL + this.props.article.guidFileName}
                    />
                    <Card
                        containerStyle={{
                            flex: 1,
                            marginLeft: 0,
                            marginRight: 0,
                            backgroundColor: appColors.grey0,
                            borderColor: appColors.grey0,
                            padding: 10,
                            border: "none",
                            marginTop: 0,
                            borderRadius: 10,
                            height: "100%",
                            justifyContent: "space-between",
                            position: "relative",
                            width: "45%",
                        }}
                        wrapperStyle={{
                            height: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <View>
                            <Text h3 h3Style={StyleSheet.flatten(weeklyPlanStyles.articleName)}>
                                {this.props.article.name}
                            </Text>
                            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                {this.props.article.ArticleDiets.length > 0 &&
                                    this.props.article.ArticleDiets.map((ArticleDiet, index) => {
                                        if (ArticleDiet.Diet.Picture) {
                                            return (
                                                <img
                                                    key={index}
                                                    src={ArticleDiet.Diet.Picture.thumbUrl}
                                                    style={{ width: "15px", margin: "1px" }}
                                                />
                                            );
                                        }
                                    })}
                            </View>
                        </View>
                        <Text
                            onPress={() => this.props.showArticleDetails(this.props.article)}
                            style={StyleSheet.flatten(weeklyPlanStyles.detailButtonTitle)}
                        >
                            Details
                        </Text>
                        {this.state.articleSizes && (
                            <View>
                                {this.state.articleSizes.length > 1 && (
                                    <View>
                                        <EntrySizeSelect
                                            handleChange={this.setSelectedArticleSize}
                                            selectedSize={this.state.selectedArticleSize}
                                            sizes={this.state.articleSizes}
                                        />
                                    </View>
                                )}
                            </View>
                        )}
                        <View style={{ alignItems: "flex-end" }}>
                            {this.props.showCartButton && this.state.selectedArticlePrice && (
                                <Pressable disabled={!this.state.articleAvailable}>
                                    <View>
                                        <Text
                                            h4
                                            style={{
                                                display: "flex",
                                                backgroundColor: this.props.settings.store.accentColor,
                                                width: 60,
                                                height: 60,
                                                borderRadius: 60,
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: getContrastColor(this.props.settings.store.accentColor),
                                                // borderColor: getContrastColor(this.props.settings.store.accentColor),
                                                border: "1px solid #fff",
                                            }}
                                            onPress={() => {
                                                if (this.state.articleAvailable) {
                                                    this.toggleChooseDayToCook();
                                                }
                                            }}
                                            h4Style={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                color: getContrastColor(this.props.settings.store.accentColor),
                                            }}
                                        >
                                            <NumberFormat
                                                value={this.state.selectedArticlePrice.price1}
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                thousandSeparator="."
                                                suffix=" €"
                                                displayType="text"
                                            />
                                        </Text>
                                    </View>
                                </Pressable>
                            )}
                        </View>
                    </Card>
                </View>
                <ChooseDayToCook
                    visible={this.state.chooseDayToCookVisible}
                    entry={this.props.entry}
                    article={this.props.article}
                    onBackdropPress={this.toggleChooseDayToCook}
                    onAddToCartPress={this.addOrderItem}
                    onValueChange={this.setTime}
                    weeklyPlanDay={this.props.weeklyPlanDay}
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { customer, theme, settings } = state;
    return { customer, theme, settings };
}

export default connect(mapStateToProps)(WeeklyPlanArticle);
