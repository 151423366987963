import React from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import NumberFormat from "react-number-format";
import { appColors } from "../../shared/styles";
import { orderTrackingStyles } from "./OrderTrackingStyles";

export default function ArticleList(props) {
    return (
        <View style={orderTrackingStyles.articleList}>
            <View>
                <Text h2 h2Style={{ color: props.textColor }}>
                    Artikel
                </Text>
            </View>
            <View>
                {props.cartJson.map((cartItem, index) => {
                    return (
                        <View
                            key={index}
                            style={
                                cartItem.articleCount > 0
                                    ? orderTrackingStyles.cartRow
                                    : orderTrackingStyles.sumToPayRow
                            }
                        >
                            <View style={orderTrackingStyles.articleTitle}>
                                {cartItem.articleCount > 0 && (
                                    <Text style={orderTrackingStyles.amount}>{cartItem.articleCount}x</Text>
                                )}
                                <Text
                                    style={
                                        cartItem.articleCount > 0
                                            ? orderTrackingStyles.articleNameText
                                            : orderTrackingStyles.sumToPayText
                                    }
                                >
                                    {cartItem.name}
                                </Text>
                                {cartItem.comment && (
                                    <View style={orderTrackingStyles.comment}>
                                        <Text>{cartItem.comment}</Text>
                                    </View>
                                )}
                                {cartItem.articleCount > 1 && (
                                    <View style={orderTrackingStyles.priceSingle}>
                                        <Text>({cartItem.singleArticlePrice}) </Text>
                                    </View>
                                )}
                            </View>
                            <View style={orderTrackingStyles.price}>
                                <View style={orderTrackingStyles.priceTotal}>
                                    <Text
                                        style={cartItem.articleCount === 0 ? orderTrackingStyles.sumToPayValue : null}
                                    >
                                        <NumberFormat
                                            value={cartItem.totalArticlePrice / 100}
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale
                                            thousandSeparator="."
                                            suffix=" €"
                                            displayType="text"
                                        />
                                    </Text>
                                </View>
                            </View>
                        </View>
                    );
                })}
            </View>
        </View>
    );
}
