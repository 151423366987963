import React, { Component } from "react";
import { Text } from "react-native-elements";
import { View } from "react-native-web";
import { connect, connectAdvanced } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

import { getContrastColor } from "./helpers";

class Footer extends Component {
    render() {
        return (
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50px",
                    backgroundColor: this.props.settings.store.accentColor,
                }}
            >
                <Text
                    style={{
                        marginLeft: "10px",
                        color: getContrastColor(this.props.settings.store.accentColor),
                        textDecorationLine: "underline",
                    }}
                    onPress={() => this.props.history.push("/impressum/")}
                >
                    Impressum
                </Text>
                <Text
                    style={{
                        marginLeft: "10px",
                        color: getContrastColor(this.props.settings.store.accentColor),
                        textDecorationLine: "underline",
                    }}
                    onPress={() => this.props.history.push("/DataProtection/")}
                >
                    Datenschutz
                </Text>
            </View>
        );
    }
}

function mapStateToProps(state) {
    return {
        settings: state.settings,
    };
}

export default connect(mapStateToProps)(withRouter(Footer));
