import { Picker } from "@react-native-picker/picker";
import React, { Component } from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import { appColors, styles } from "../../shared/styles";
import { weeklyPlanStyles } from "./WeeklyPlanStyles";

export default class EntrySizeSelect extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.props.handleChange(e.target.value);
    }

    render() {
        return (
            <View style={styles.pickerWrapper}>
                <Text style={{ color: appColors.white, fontWeight: "bold", fontSize: 12, marginBottom: 5 }}>Größe</Text>
                <Picker
                    style={{
                        ...styles.picker,
                        fontSize: 12,
                        backgroundColor: appColors.grey0,
                        color: appColors.white,
                        borderWidth: 0,
                        paddingHorizontal: 0,
                    }}
                    onChange={this.handleChange}
                >
                    {this.props.sizes.map((articleSize, index) => {
                        if (articleSize.Prices && !articleSize.Size.isDefault) {
                            return (
                                <Picker.Item
                                    key={index}
                                    label={articleSize.Size.displayName}
                                    value={articleSize.articleSizesId}
                                />
                            );
                        }
                    })}
                </Picker>
            </View>
        );
    }
}
