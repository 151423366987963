import moment from "moment";
import { SET_ZIPCODE, SET_ZIPCODEAREA, SET_NEXT_DELIVERY_DATE, SET_SELECTED_INDEX } from "../actions/zipCodeActions";

const initialState = {
    zipCodeArea: {},
    // zipCode: "",
    // Fix for mensa shop
    zipCode: "66606",
    nextDeliveryDate: new moment(),
    selectedIndex: 0,
    forceUpdate: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ZIPCODE:
            return Object.assign({}, state, {
                zipCode: action.zipCode,
            });
        case SET_ZIPCODEAREA:
            return Object.assign({}, state, {
                zipCodeArea: action.zipCodeArea,
            });
        case SET_NEXT_DELIVERY_DATE:
            return Object.assign({}, state, {
                nextDeliveryDate: action.nextDeliveryDate,
                forceUpdate: action.forceUpdate,
            });
        case SET_SELECTED_INDEX:
            return Object.assign({}, state, {
                selectedIndex: action.selectedIndex,
            });
        default:
            return state;
    }
};
