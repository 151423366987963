import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { Icon, ListItem, Text } from "react-native-elements";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

import { appColors, styles } from "../../shared/styles";
import { UPDATE_ORDER_ITEM } from "../../store/actions/cartActions";
import { store } from "../../store/store";

class CartItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: this.props.orderItem.entry.amount,
            calculatedPrice: 0,
        };

        this.articleInformation = this.articleInformation.bind(this);
        this.articlePrice = this.articlePrice.bind(this);
        this.calculatePrice = this.calculatePrice.bind(this);
        this.increaseAmount = this.increaseAmount.bind(this);
        this.decreaseAmount = this.decreaseAmount.bind(this);
        this.updateOrderItem = this.updateOrderItem.bind(this);
    }

    componentDidMount() {
        this.setState({
            calculatedPrice: this.calculatePrice(this.state.amount),
        });
    }

    articleInformation() {
        const day = moment.unix(this.props.orderItem.dayToCook).format("dd DD.MM.YYYY");
        return (
            <View style={{ width: "100%" }}>
                {this.props.orderItem.entry.Article.ArticleSizes.length > 1 && (
                    <Text style={{ fontSize: 12 }}>{this.props.orderItem.ArticleSize.Size.name}</Text>
                )}

                {/* <Text style={{ fontSize: 12, color: appColors.grey3, marginBottom: 10 }}>{`geplant für ${day}`}</Text> */}
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Icon
                            name="minus-circle"
                            type="font-awesome-5"
                            onPress={this.decreaseAmount}
                            size={16}
                            color={appColors.grey2}
                            containerStyle={{
                                marginRight: 7,
                            }}
                        />
                        <Text accessibilityHint="Anzahl">{this.state.amount}</Text>

                        <Icon
                            name="plus-circle"
                            type="font-awesome-5"
                            onPress={this.increaseAmount}
                            size={16}
                            color={appColors.grey2}
                            containerStyle={{
                                marginLeft: 7,
                            }}
                        />
                    </View>
                    <View>{this.articlePrice()}</View>
                </View>
            </View>
        );
    }

    calculatePrice(amount) {
        const singlePrice = this.props.orderItem.Price ? this.props.orderItem.Price.price1 : "";

        if (singlePrice) {
            return singlePrice * amount;
        }

        return singlePrice;
    }

    increaseAmount() {
        const newAmount = this.state.amount + 1;
        if (newAmount <= this.props.settings.store.maxSingleArticleAmount) {
            this.setState(
                {
                    amount: newAmount,
                    calculatedPrice: this.calculatePrice(newAmount),
                },
                () => this.updateOrderItem(newAmount)
            );
        }
    }

    decreaseAmount() {
        const newAmount = this.state.amount - 1;
        if (newAmount >= 1) {
            this.setState(
                {
                    amount: newAmount,
                    calculatedPrice: this.calculatePrice(newAmount),
                },
                () => this.updateOrderItem(newAmount)
            );
        }
    }

    updateOrderItem(amount) {
        const newEntry = this.props.orderItem.entry;
        newEntry.amount = amount;

        const orderItem = {
            entry: newEntry,
            weeklyPlanComponent: this.props.orderItem.weeklyPlanComponent,
            weeklyPlanDay: this.props.orderItem.weeklyPlanDay,
            ArticleSize: this.props.orderItem.ArticleSize,
            Price: this.props.orderItem.Price,
        };
        store.dispatch({ type: UPDATE_ORDER_ITEM, orderItem });
    }

    articlePrice() {
        return (
            <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                <NumberFormat
                    value={this.state.calculatedPrice}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator="."
                    suffix=" €"
                    displayType="text"
                />
            </Text>
        );
    }

    render() {
        var date = new Date(this.props.orderItem.weeklyPlanDay);
        var dateString =
            date.getDate() +
            "." +
            (date.getMonth() + 1) +
            "." +
            date.getFullYear() +
            " " +
            date.getHours() +
            ":" +
            (date.getMinutes() < 10 ? "0" : "") +
            date.getMinutes();
        return (
            <>
                {this.props.orderItem && (
                    <ListItem testID="orderItem" containerStyle={{ borderRadius: 10, marginBottom: 15 }}>
                        {this.props.orderItem.entry.Article && (
                            <ListItem.Content>
                                <ListItem.Title numberOfLines={1}>
                                    {this.props.orderItem.entry.Article.name}
                                </ListItem.Title>
                                <Text style={{ fontSize: 12 }}>{dateString}</Text>
                                <ListItem.Subtitle style={{ fontSize: 12, width: "100%" }}>
                                    {this.articleInformation()}
                                </ListItem.Subtitle>

                                <Icon
                                    name="times"
                                    type="font-awesome-5"
                                    color="#fff"
                                    size={14}
                                    containerStyle={{
                                        ...StyleSheet.flatten(styles.overlayCloseIcon),
                                        top: -16,
                                        right: -16,
                                        backgroundColor: appColors.primary,
                                        width: 23,
                                        height: 23,
                                    }}
                                    onPress={() => {
                                        this.props.removeOrderItem(this.props.orderItem);
                                    }}
                                />
                            </ListItem.Content>
                        )}
                    </ListItem>
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    const { settings } = state;
    return { settings };
}

export default connect(mapStateToProps)(CartItem);
