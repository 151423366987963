//import { StyleSheet } from "@babel/core";
import { StyleSheet } from "react-native";

import { appColors } from "../../shared/styles";

export const weeklyPlanStyles = StyleSheet.create({
    dayContainer: {
        padding: 10,
    },
    // headerLogo: {
    //     display: "flex",
    //     fontSize: 20,
    //     fontWeight: 600,
    //     textDecoration: "none",
    //     marginLeft: 2,
    //     height: "100%",
    //     display: "flex",
    //     alignItems: "center",
    //     cursor: "pointer",
    // },
    // headerImg: {
    //     height: 24,
    //     width: "auto",
    //     marginRight: 10,
    // },
    dayContainerDisabled: {
        padding: 10,
        opacity: 0.5,
    },
    dayDesktopContainer: {
        // padding: 15,
        flex: 1,
        alignItems: "stretch",
    },
    dayDesktopContainerDisabled: {
        // padding: 15,
        flex: 1,
        opacity: 0.5,
    },
    desktopWrapper: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 100 / 3 + "%",
        minWidth: 300,
        padding: 10,
        // flex: 1,
    },
    desktopWrapperAlt: {
        width: "100%",
        padding: 5,
    },
    dayDate: {
        fontWeight: "bold",
        color: "#fff",
        marginBottom: 10,
    },
    componentContainer: {
        marginBottom: 20,
        height: "100%",
        maxHeight: 250,
    },
    componentContainerAlt: {
        marginBottom: 20,
        padding: 5,
        width: "25%",
        maxWidth: 200,
        display: "flex",
    },
    desktopComponentContainerWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "stretch",
    },
    entryButtonContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    desktopContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, 280px)",
        gridGap: 20,
    },
    desktopContainerAlt: {
        flexDirection: "column",
    },
    picker: {
        borderWidth: 0,
        color: "#000",
        minHeight: 40,
        fontSize: 12,
    },
    pickerError: {
        borderWidth: 0,
        alignSelf: "center",
        color: appColors.error,
        fontSize: 18,
        minHeight: 40,
    },
    pickerWrapper: {
        borderBottomWidth: 1,
        borderColor: appColors.grey3,
        transform: [{ translateX: 0 }],
        marginBottom: 20,
    },
    pickerErrorWrapper: {
        borderBottomWidth: 1,
        borderColor: appColors.error,
        //transform,
        marginBottom: 20,
    },
    headerContainer: {
        flexDirection: "row",
        flex: 1,
        alignItems: "center",
        zIndex: "99999",
        justifyContent: "center",
    },
    headerArrowPrev: {
        marginRight: 5,
    },
    headerArrowNext: {
        marginLeft: 5,
        marginRight: 10,
    },
    headerEdit: {
        marginLeft: 5,
    },
    articleName: {
        fontSize: 12,
        color: "#fff",
        fontWeight: "bold",
        marginBottom: 15,
    },
    articleDate: {
        textAlign: "left",
        color: "#fff",
        marginBottom: 0,
    },
    articleImage: {
        position: "absolute",
        borderRadius: 10,
        left: 0,
        bottom: 0,
        top: 0,
        right: 0,
        backgroundColor: appColors.grey5,
    },
    articleWrapper: {
        position: "relative",
        alignItems: "flex-end",
        height: 280,
        overflow: "hidden",
        borderRadius: 10,
    },
    detailButton: {
        padding: 0,
        justifyContent: "flex-start",
    },
    detailButtonTitle: {
        textDecorationLine: "underline",
        fontWeight: "bold",
        fontSize: 12,
        color: "#fff",
    },
    inlineEdit: {
        flexDirection: "row",
        alignItems: "center",
        borderStyle: "dashed",
        borderColor: appColors.white,
        borderRadius: 3,
        borderWidth: 1,
        backgroundColor: "rgba(255,255,255, 0.2)",
        paddingHorizontal: 5,
    },
});
