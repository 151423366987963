import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export const SET_CURRENT_WEEKLY_PLANS_ID = "SET_CURRENT_WEEKLY_PLANS_ID";

export function setCurrentWeeklyPlansId(weeklyPlansId) {
    return {
        type: SET_CURRENT_WEEKLY_PLANS_ID,
        weeklyPlansId,
    };
}

export function getNextValidWeeklyPlan() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/WeeklyPlans/getNextValidWeeklyPlan", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getWeeklyPlan(date, zipCodeArea, previousDeliveryDay, selectedIndex) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            date: date.add(5, "hour"),
            zipCodeArea: zipCodeArea,
            previousDeliveryDay: previousDeliveryDay,
            selectedIndex: selectedIndex,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/WeeklyPlans/GetWeeklyPlanByDateForShop", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getIngredientAticle(articlesId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(articlesId),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/GetIngredientArticlesForShop", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
