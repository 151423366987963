import { Picker } from "@react-native-picker/picker";
import { Formik } from "formik";
import React, { Component } from "react";
import { Dimensions, Pressable, StyleSheet, View } from "react-native";
import { Button, Card, CheckBox, Input, Text } from "react-native-elements";
import { connect } from "react-redux";
import * as yup from "yup";

import { createYupSchema, getContrastColor } from "../../shared/helpers";
import { styles } from "../../shared/styles";
import { getWebshopPaymentTypes } from "../../store/actions/paymentActions";
import { customerStyle } from "../customerScreens/CustomerStyle";
import CompleteOrder from "./CompleteOrder";
import { customerDataStyles } from "./CustomerDataStyles";

class CustomerData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            webshopPaymentTypes: [],
            useDifferentBillingAddress: false,
            completeOrder: false,
            customerData: null,
            cash: true,
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        };

        this.formElements = this.formElements.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.customerDataForm = React.createRef();
        this.buildInitialValues = this.buildInitialValues.bind(this);
        this.getPaymentTypes = this.getPaymentTypes.bind(this);
        this.toggleCompleteOrder = this.toggleCompleteOrder.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    toggleCompleteOrder() {
        this.setState({ completeOrder: !this.state.completeOrder }, () => {
            this.props.hide();
        });
    }

    formElements(props) {
        return this.props.settings.customerData.fields.map((field, index) => {
            const error = props.errors.hasOwnProperty(field.id) && props.errors[field.id];
            if (!this.props.isRegistration || field.showInRegistration) {
                if (field.type === "text") {
                    const { customer } = this.props.account;
                    const { windowWidth } = this.state;
                    let isBillingAddressAttribute = false;

                    switch (field.id) {
                        case "firstName":
                            if (customer) props.values[field.id] = customer.firstName;
                            break;
                        case "lastName":
                            if (customer) props.values[field.id] = customer.familyName;
                            break;
                        case "email":
                            if (customer) props.values[field.id] = customer.email;
                            break;
                        case "phoneNumber":
                            if (customer) props.values[field.id] = customer.phoneNumber;
                            break;
                        case "billingCity":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingStreet":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingHousenumber":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingZipCode":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingTitle":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingFirstName":
                            isBillingAddressAttribute = true;
                            break;
                        case "billingLastName":
                            isBillingAddressAttribute = true;
                            break;
                    }

                    return (
                        ((customer && field.showWhenLoggedIn) ||
                            (!customer && field.showWhenNotLoggedIn && !isBillingAddressAttribute) ||
                            (!customer && isBillingAddressAttribute && props.values["useDifferentBillingAddress"])) && (
                            <View
                                key={field.id}
                                nativeID={field.id}
                                style={
                                    (customerDataStyles.fieldRow,
                                    windowWidth <= 1028
                                        ? customerDataStyles.fieldFormFullRow
                                        : customerDataStyles.fieldFormSplitRow)
                                }
                            >
                                <Input
                                    key={index}
                                    name={field.id}
                                    placeholder={field.placeholder}
                                    value={props.values[field.id] ? props.values[field.id] : ""}
                                    onChange={props.handleChange(field.id)}
                                    disabled={field.disabled}
                                    onBlur={props.handleBlur(field.id)}
                                    errorMessage={props.errors[field.id] ? props.errors[field.id] : ""}
                                />
                            </View>
                        )
                    );
                }

                if (field.type === "textarea") {
                    return (
                        <View key={field.id} nativeID={field.id} style={customerDataStyles.fieldRow}>
                            <Input
                                key={index}
                                name={field.id}
                                placeholder={field.placeholder}
                                value={props.values[field.id] ? props.values[field.id] : ""}
                                onChange={props.handleChange(field.id)}
                                onBlur={props.handleBlur(field.id)}
                                multiline
                                numberOfLines={5}
                                errorMessage={props.errors[field.id] ? props.errors[field.id] : ""}
                            />
                        </View>
                    );
                }

                if (field.type === "printBillCheckbox") {
                    return (
                        <View key={field.id} nativeID={field.id} style={customerDataStyles.fieldRow}>
                            <CheckBox
                                key={index}
                                title="Rechnung drucken"
                                checked={props.values[field.id]}
                                style={{ marginLeft: 0, marginRight: 0 }}
                                onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                            />
                        </View>
                    );
                }

                //Not relevant for Mensa Shop
                // if (field.type === "printRecipeCheckbox") {
                //     return (
                //         <View key={field.id} nativeID={field.id} style={customerDataStyles.fieldRow}>
                //             <CheckBox
                //                 key={index}
                //                 title="Rezept drucken"
                //                 checked={props.values[field.id]}
                //                 onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                //             />
                //         </View>
                //     );
                // }

                if (field.type === "select") {
                    const { customer } = this.props.account;
                    return (
                        !customer && (
                            <View key={field.id} nativeID={field.id} style={customerDataStyles.fieldRow}>
                                <View style={error ? styles.pickerErrorWrapper : styles.pickerWrapper}>
                                    <Picker
                                        key={index}
                                        name={field.id}
                                        onValueChange={(value) => {
                                            props.setFieldValue(field.id, value);
                                        }}
                                        selectedValue={props.values[field.id]}
                                        onBlur={props.handleBlur}
                                        style={styles.picker}
                                    >
                                        <Picker.Item key={1} label="Frau" value="Frau" />
                                        <Picker.Item key={2} label="Herr" value="Herr" />
                                        <Picker.Item key={3} label="Divers" value="Divers" />
                                    </Picker>
                                </View>
                                {error && (
                                    <View>
                                        <Text style={styles.pickerError}>
                                            {props.errors[field.id] ? props.errors[field.id] : ""}
                                        </Text>
                                    </View>
                                )}
                            </View>
                        )
                    );
                }

                if (field.type === "useDifferentBillingAddress") {
                    return (
                        <View key={field.id} nativeID={field.id} style={customerDataStyles.fieldRow}>
                            <CheckBox
                                key={index}
                                title="Abweichende Rechnungsadresse"
                                checked={props.values[field.id]}
                                status={props.values[field.id]}
                                onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                            />
                        </View>
                    );
                }

                if (field.type === "selectAddress") {
                    const { customer } = this.props.account;
                    const { theme } = this.props;
                    const { windowWidth } = this.state;
                    var tempAddresses = [];
                    if (customer) {
                        tempAddresses = customer.addresses;
                    }
                    const [selectedAddress, setSelectedAddress] = React.useState(0);

                    const sortedDeliveryAddresses = tempAddresses.sort((a, b) => {
                        if (a.isDefaultDeliveryAddress) return -1;
                        if (b.isDefaultDeliveryAddress) return 1;
                        return a.addressId - b.addressId;
                    });

                    const sortedBillingAddresses = tempAddresses.sort((a, b) => {
                        if (a.isDefaultBillingAddress) return -1;
                        if (b.isDefaultBillingAddress) return 1;
                        return a.addressId - b.addressId;
                    });

                    return (
                        customer &&
                        (field.id === "deliveryAddress" || props.values["useDifferentBillingAddress"]) && (
                            <View key={field.id} nativeID={field.id} style={{ width: "100%" }}>
                                <Text style={{ width: "100%", marginBottom: 15, marginLeft: 10, fontWeight: "bold" }}>
                                    {field.id === "deliveryAddress" ? "Lieferadresse" : "Rechnungsadresse"}
                                </Text>
                                <View
                                    style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", width: "100%" }}
                                >
                                    {sortedDeliveryAddresses &&
                                        sortedDeliveryAddresses.map((address, index) => {
                                            return (
                                                <Card
                                                    containerStyle={{
                                                        ...StyleSheet.flatten(customerStyle.card),
                                                        backgroundColor:
                                                            selectedAddress === index
                                                                ? this.props.settings.store.accentColor
                                                                : this.props.settings.store.surfaceColor,
                                                        border: "1px solid " + this.props.settings.store.accentColor,
                                                        marginBottom: 25,
                                                        width:
                                                            windowWidth <= 768
                                                                ? "calc(100% - 10px)"
                                                                : "calc(33% - 10px)",
                                                        display: "flex",
                                                        margin: 5,
                                                        cursor: "pointer",
                                                    }}
                                                    wrapperStyle={{
                                                        ...StyleSheet.flatten(customerStyle.addressCardWrapper),
                                                    }}
                                                    key={index}
                                                >
                                                    <Pressable
                                                        onPress={() => {
                                                            props.setFieldValue(field.id, JSON.stringify(address));
                                                            setSelectedAddress(index);
                                                        }}
                                                        style={customerStyle.cardTouchable}
                                                    >
                                                        <Card.Title
                                                            style={{
                                                                color:
                                                                    selectedAddress === index
                                                                        ? getContrastColor(
                                                                              this.props.settings.store.accentColor
                                                                          )
                                                                        : this.props.settings.store.onSurfaceColor,
                                                                marginBottom: 25,
                                                            }}
                                                        >
                                                            {address.firstName + " " + address.lastName}
                                                        </Card.Title>
                                                        <View style={customerStyle.addressCardInnerContainer}>
                                                            <Text
                                                                style={{
                                                                    color:
                                                                        selectedAddress === index
                                                                            ? getContrastColor(
                                                                                  this.props.settings.store.accentColor
                                                                              )
                                                                            : this.props.settings.store.onSurfaceColor,
                                                                }}
                                                            >
                                                                {address.Street.name +
                                                                    " " +
                                                                    address.housenumber +
                                                                    "\n" +
                                                                    address.Street.City.zipCode +
                                                                    " " +
                                                                    address.Street.City.name +
                                                                    "\n\n" +
                                                                    (address.company ? address.company : "") +
                                                                    "\n" +
                                                                    (address.addressDetails
                                                                        ? address.addressDetails
                                                                        : "")}
                                                            </Text>
                                                        </View>
                                                    </Pressable>
                                                </Card>
                                            );
                                        })}
                                </View>
                            </View>
                        )
                    );
                }
            }
            return "";
        });
    }

    async buildInitialValues() {
        const initialValues = {};
        const { customer } = this.props.account;

        await this.props.settings.customerData.fields.forEach((field) => {
            let initialValue = field.value || "";
            if (field.id === "printBill" || field.id === "printRecipe") {
                initialValue = false;
            } else if (field.id === "billingAddress") {
                if (customer && customer.addresses) {
                    let address = customer.addresses.filter((address) => address.isDefaultBillingAddress)[0];
                    initialValue = JSON.stringify(address);
                }
            } else if (field.id === "deliveryAddress") {
                if (customer && customer.addresses) {
                    let address = customer.addresses.filter((address) => address.isDefaultDeliveryAddress)[0];
                    initialValue = JSON.stringify(address);
                }
            } else {
                if (this.props.account.customer) {
                    initialValue = this.props.account.customer[field.id]
                        ? this.props.account.customer[field.id]
                        : initialValue;
                }
            }

            initialValues[field.id] = field.value || initialValue;
        });

        if (this.props.account.customer) {
            if (this.customerDataForm.current) {
                this.customerDataForm.current.setValues(initialValues, false);
            }
        }

        return initialValues;
    }

    getPaymentTypes() {
        let paymentTypes = getWebshopPaymentTypes();
        if (paymentTypes) this.setState({ webshopPaymentTypes: paymentTypes });
    }

    render() {
        const { completeOrder, customerData } = this.state;
        const initialValues = this.buildInitialValues();
        const yepSchema = this.props.settings.customerData.fields.reduce(createYupSchema, {});
        const validateSchema = yup.object().shape(yepSchema);
        const { windowWidth } = this.state;
        const { customer } = this.props.account;
        return (
            <View style={customerDataStyles.container}>
                {!completeOrder && (
                    <>
                        <Text h2>Kundendaten</Text>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={customer ? null : validateSchema}
                            onSubmit={(values) => {
                                this.setState({ completeOrder: true, customerData: values });
                            }}
                            innerRef={this.customerDataForm}
                        >
                            {(props) => (
                                <View style={customerDataStyles.fieldsWrapper}>
                                    {this.formElements(props)}
                                    <View style={{ width: "100%" }}>
                                        <View>
                                            <CheckBox
                                                title="Barzahlung"
                                                checked={this.state.cash}
                                                style={{ marginLeft: 0, marginRight: 0 }}
                                                onPress={() => this.setState({ cash: !this.state.cash })}
                                            />
                                            {/* <CheckBox
                                                title="Onlinezahlung"
                                                checked={!this.state.cash}
                                                style={{ marginLeft: 0, marginRight: 0 }}
                                                onPress={() => this.setState({ cash: !this.state.cash })}
                                            /> */}
                                        </View>
                                    </View>
                                    <View
                                        style={{
                                            ...StyleSheet.flatten(customerDataStyles.buttonContainerFullRow),
                                            flexDirection: windowWidth <= 756 ? "column" : "row",
                                            marginRight: windowWidth <= 756 ? 0 : "10",
                                            marginLeft: windowWidth <= 756 ? 0 : "10",
                                        }}
                                    >
                                        <View
                                            style={{
                                                marginRight: windowWidth <= 756 ? 0 : "2%",
                                                marginLeft: windowWidth <= 756 ? 0 : "10",
                                                marginBottom: windowWidth <= 756 ? 10 : 10,
                                                width: windowWidth <= 756 ? "100%" : "48%",
                                                flex: 1,
                                            }}
                                        >
                                            <Button
                                                title={"Abbrechen"}
                                                titleStyle={{
                                                    color: getContrastColor(this.props.settings.store.accentColor),
                                                }}
                                                style={{
                                                    backgroundColor: this.props.settings.store.notificationColor,
                                                }}
                                                onPress={this.props.hide}
                                                uppercase={false}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                // marginRight: windowWidth <= 756 ? 0 : "0%",
                                                marginBottom: windowWidth <= 756 ? 10 : 10,
                                                width: windowWidth <= 756 ? "100%" : "48%",
                                                flex: 1,
                                            }}
                                        >
                                            <Button
                                                title="Bestellen"
                                                titleStyle={{
                                                    color: getContrastColor(this.props.settings.store.accentColor),
                                                }}
                                                buttonStyle={{
                                                    backgroundColor: this.props.settings.store.accentColor,
                                                }}
                                                onPress={props.handleSubmit}
                                            />
                                        </View>
                                    </View>
                                </View>
                            )}
                        </Formik>
                    </>
                )}
                {completeOrder && customerData !== null && (
                    <>
                        <CompleteOrder
                            customerData={customerData}
                            cash={this.state.cash}
                            toggleCompleteOrder={this.toggleCompleteOrder}
                        />
                    </>
                )}
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, customer, zipCode, theme } = state;
    return { settings, account, customer, zipCode, theme };
}

export default connect(mapStateToProps)(CustomerData);
