import { Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { View } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import { connect } from "react-redux";

import { HSLToHex } from "../../shared/helpers";
import {
    getZipCodes,
    SET_NEXT_DELIVERY_DATE,
    SET_SELECTED_INDEX,
    SET_ZIPCODE,
    SET_ZIPCODEAREA,
} from "../../store/actions/zipCodeActions";
import { store } from "../../store/store";

class ZipCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initRender: true,
            zipCodesAreas: [],
        };

        this.zipCodeForm = React.createRef();
        this.checkZipCodeIsValid = this.checkZipCodeIsValid.bind(this);
        this.getZipCodeArea = this.getZipCodeArea.bind(this);
        this.calculateNextDeliveryDate = this.calculateNextDeliveryDate.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        store.dispatch(getZipCodes()).then((result) => {
            this.setState({
                zipCodesAreas: result,
            });
        });
        if (this.props.zipCode.zipCode) {
            this.props.hideZipCode();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.zipCode.zipCode !== this.props.zipCode.zipCode && !this.props.zipCode.zipCode) {
            this.props.showZipCode();
        }
    }

    checkZipCodeIsValid(zipCode) {
        var result = false;
        this.state.zipCodesAreas.map((value) => {
            if (value.zipCodes.find((code) => code === zipCode)) {
                result = true;
            }
        });
        return result;
    }

    getZipCodeArea(zipCode) {
        var result;
        for (var i = 0; i < this.state.zipCodesAreas.length; i++) {
            if (this.state.zipCodesAreas[i].zipCodes.find((code) => code === zipCode)) {
                result = this.state.zipCodesAreas[i];
                break;
            }
        }
        return result;
    }

    calculateNextDeliveryDate(zipCodeArea) {
        var sortedDeliveryDays = zipCodeArea.deliveryDays.sort();
        var currentDay = new moment().day() + 2;
        var nextDeliveryDay;
        var selectedIndex = 0;
        for (var i = 0; i < sortedDeliveryDays.length; i++) {
            if (parseInt(sortedDeliveryDays[i]) + 1 >= currentDay) {
                nextDeliveryDay = parseInt(sortedDeliveryDays[i]) + 1;
                selectedIndex = i;
                break;
            }
        }
        var nextDeliveryDate;
        if (nextDeliveryDay) {
            nextDeliveryDate = new moment().add(nextDeliveryDay - (currentDay - 2), "days");
        } else {
            nextDeliveryDate = new moment().add(7 + parseInt(sortedDeliveryDays[0] + 1) - (currentDay - 2), "days");
        }
        store.dispatch({ type: SET_SELECTED_INDEX, selectedIndex: selectedIndex });
        store.dispatch({
            type: SET_NEXT_DELIVERY_DATE,
            nextDeliveryDate: nextDeliveryDate.format("YYYY-MM-DD"),
            forceUpdate: true,
        });
    }

    validate(values) {
        var errors = {};
        if (!values.zipCode) {
            errors.zipCode = "Dieses Feld wird benötigt";
        } else if (!this.checkZipCodeIsValid(values.zipCode)) {
            errors.zipCode = "Diese Postleizahl wird nicht beliefert";
        }
        return errors;
    }

    render() {
        return (
            <View>
                <View>
                    <Text h2>Postleitzahl angeben</Text>
                    <Formik
                        innerRef={this.zipCodeForm}
                        initialValues={{
                            zipCode: "",
                        }}
                        onSubmit={(values, actions) => {
                            if (this.checkZipCodeIsValid(values.zipCode)) {
                                var zipCodeArea = this.getZipCodeArea(values.zipCode);
                                this.calculateNextDeliveryDate(zipCodeArea);
                                store.dispatch({ type: SET_ZIPCODEAREA, zipCodeArea: zipCodeArea });
                                store.dispatch({ type: SET_ZIPCODE, zipCode: values.zipCode });
                                this.props.hideZipCode();
                            }
                        }}
                        validate={this.validate}
                    >
                        {(props) => {
                            return (
                                <React.Fragment>
                                    <View>
                                        <View>
                                            <Input
                                                placeholder={"Postleitzahl"}
                                                value={props.values.zipCode}
                                                onChange={props.handleChange("zipCode")}
                                                errorMessage={props.errors["zipCode"] ? props.errors["zipCode"] : ""}
                                            />
                                        </View>
                                        <View>
                                            <Button
                                                title="prüfen"
                                                buttonStyle={{
                                                    backgroundColor: this.props.settings.store.accentColor,
                                                }}
                                                onPress={props.handleSubmit}
                                            />
                                        </View>
                                    </View>
                                </React.Fragment>
                            );
                        }}
                    </Formik>
                </View>
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { customer, zipCode, settings } = state;
    return { customer, zipCode, settings };
}

export default connect(mapStateToProps)(ZipCode);
