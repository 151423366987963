import WebModal from "modal-react-native-web";
import moment from "moment";
import React, { Component } from "react";
import { ActivityIndicator, Dimensions, Modal, Platform, Pressable, ScrollView, View } from "react-native";
import { Icon, Overlay, Text } from "react-native-elements";
import MaterialCommunityIcon from "react-native-vector-icons/MaterialCommunityIcons";
import { connect } from "react-redux";

import { withRouter } from "../../routers/routing";
import { getContrastColor, possibleOrderDayValue } from "../../shared/helpers";
import { appColors, styles } from "../../shared/styles";
import { getWeeklyPlan } from "../../store/actions/weeklyPlanActions";
import { store } from "../../store/store";
import ArticleDetails from "../ArticleDetails/ArticleDetails";
import WeeklyPlan from "./WeeklyPlan";

class WeeklyPlanScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            weeklyPlanTitle: "",
            weeklyPlan: undefined,
            noWeeklyPlan: false,
            weeklyPlanDate: moment(),
            articleDetailsVisible: false,
            articleToShow: null,
            initialRender: true,
            currentDate: moment(),
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        };

        this.showArticleDetails = this.showArticleDetails.bind(this);
        this.hideArticleDetails = this.hideArticleDetails.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        //     Not needed for the mensa shop
        // if (this.props.orderTrackingVisible) {
        //     let orderId = null;
        //     if (this.props.match.params.id) {
        //         orderId = this.props.match.params.id;
        //     }
        //     this.props.showOrderTracking(orderId);
        // }
        Dimensions.addEventListener("change", this.updateDimensions);

        this.setState({
            initialRender: true,
        });
        this.prepareWeekDays().then(() => {
            this.setState({ isLoading: false });
        });
    }

    componentWillUnmount() {
        Dimensions.removeEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({ windowWidth: Dimensions.get("window").width, windowHeight: Dimensions.get("window").height });
    }

    // componentDidUpdate(prevProps, prevState) {
    //     Not needed for the mensa shop
    //     if (
    //         this.props.zipCode.forceUpdate &&
    //         this.props.zipCode.nextDeliveryDate !== prevProps.zipCode.nextDeliveryDate
    //     ) {
    //         // this.updateWeeklyPlan();
    //     }
    // }

    async getWeeklyPlanbySelectedDate(date, selectedIndex) {
        const momentDate = moment(date.format("YYYY-MM-DD"), "YYYY-MM-DD");
        try {
            const res = await store.dispatch(
                getWeeklyPlan(momentDate, this.props.zipCode.zipCodeArea, 0, selectedIndex)
            );

            if (res === null) {
                // Handle the case where res is null
            } else {
                let weeklyPlanReturn;

                if (res.newDate) {
                    weeklyPlanReturn = res.weeklyPlan;
                    this.props.updateWeeklyPlanTitle(res.weeklyPlan.name);
                } else {
                    weeklyPlanReturn = res.weeklyPlan;
                }

                return weeklyPlanReturn;
            }
        } catch (err) {
            // Handle errors here
        }
    }

    prepareWeeklyPlan(weeklyPlan, selectedIndex = null) {
        let i = 0;
        var selectedDeliveryDay = selectedIndex;
        while (weeklyPlan.WeeklyPlanComponents[i]) {
            let weeklyPlanComponent = weeklyPlan.WeeklyPlanComponents[i];
            let j = 0;
            while (weeklyPlanComponent.WeeklyPlanEntries[j]) {
                let weeklyPlanEntry = weeklyPlanComponent.WeeklyPlanEntries[j];
                if (weeklyPlanEntry.validOnDay !== parseInt(selectedDeliveryDay) + 1) {
                    weeklyPlan.WeeklyPlanComponents[i].WeeklyPlanEntries.splice(j, 1);
                    continue;
                }
                let k = 0;

                while (weeklyPlanEntry.Article.ArticleSizes[k]) {
                    let articleSize = weeklyPlanEntry.Article.ArticleSizes[k];

                    if (articleSize.Size.isDefault) {
                        weeklyPlan.WeeklyPlanComponents[i].WeeklyPlanEntries[j].Article.ArticleSizes.splice(k, 1);
                    }
                    k++;
                }
                j++;
            }

            weeklyPlan.WeeklyPlanComponents[i].WeeklyPlanEntries = weeklyPlan.WeeklyPlanComponents[
                i
            ].WeeklyPlanEntries.filter((x) => {
                return x.validOnDay === parseInt(selectedDeliveryDay) + 1;
            });
            i++;
        }
        return weeklyPlan;
    }

    showArticleDetails(article = null, day = null) {
        if (article) {
            this.setState({
                articleDetailsVisible: true,
                articleToShow: article,
            });
        }
    }

    hideArticleDetails() {
        this.setState({
            articleDetailsVisible: false,
            articleToShow: null,
        });
    }

    getStartOfWeek(date) {
        return moment(date).startOf("week").toDate();
    }

    getEndOfWeek(date) {
        return moment(date).endOf("week").toDate();
    }

    formatDateToDisplay(date) {
        return moment(date).format("dddd, DD.MM.YYYY");
    }

    formatHeaderDateToDisplay(date) {
        return moment(date).format("DD.MM.");
    }

    getWeekDays(date) {
        const startOfWeek = date.clone().startOf("isoWeek");
        const days = [];
        for (let i = 0; i < 7; i++) {
            days.push(startOfWeek.clone().add(i, "days"));
        }
        return days;
    }

    getCalendarWeek(date) {
        return moment(date).isoWeek();
    }

    formatDateToDisplay(date) {
        return moment(date).format("dddd, DD.MM.YYYY");
    }

    goPreviousWeek = () => {
        this.prepareWeekDays(-1).then(() => {
            this.setState({ isLoading: false });
        });
    };

    goNextWeek = () => {
        this.prepareWeekDays(1).then(() => {
            this.setState({ isLoading: false });
        });
    };

    async prepareWeekDays(weekShift) {
        this.setState({ isLoading: true });
        const { currentDate, windowWidth } = this.state;
        const newDate = currentDate.clone().add(weekShift, "week");

        const today = moment().startOf("day");
        const weekDayElements = [];
        let now = moment();
        const possibleOrderDay = moment().startOf("day").add(possibleOrderDayValue, "hours");

        for (let index = 0; index < 7; index++) {
            const date = this.getWeekDays(newDate)[index];
            const currentDay = date.startOf("day");
            let showCartButton = false;
            if (now.clone().isAfter(currentDay.clone().add(9, "hours").add(30, "minutes"))) {
                showCartButton = false;
            } else {
                showCartButton = true;
            }
            let weeklyPlan = null;
            let weeklyPlanData = null;
            const weeklyPlanComponents = [];
            weeklyPlan = await this.getWeeklyPlanbySelectedDate(date, index);
            var storeTimeString = "";
            if (weeklyPlan) {
                weeklyPlanData = this.prepareWeeklyPlan(weeklyPlan, index);
                if (weeklyPlanData.WeeklyPlanComponents.filter((x) => x.WeeklyPlanEntries.length > 0).length > 0) {
                    weeklyPlanComponents.push(
                        <WeeklyPlan
                            showArticleDetails={this.showArticleDetails}
                            showCartButton={showCartButton}
                            weeklyPlan={weeklyPlanData}
                            key={index}
                            day={newDate}
                        />
                    );
                    storeTimeString =
                        this.props.settings &&
                        this.props.settings.store &&
                        this.props.settings.store.storeTimes &&
                        this.props.settings.store.storeTimes[index] &&
                        this.props.settings.store.storeTimes[index].opening &&
                        this.props.settings.store.storeTimes[index].closing
                            ? ", " +
                              this.props.settings.store.storeTimes[index].opening +
                              " Uhr bis " +
                              this.props.settings.store.storeTimes[index].closing +
                              " Uhr"
                            : "";
                } else {
                    weeklyPlanComponents.push(<Text key={index}>Keine Artikel für diesen Tag gefunden.</Text>);
                }
            } else {
                weeklyPlanComponents.push(<Text key={index}>Keine Artikel für diesen Tag gefunden.</Text>);
            }

            const dayElement = (
                <View key={index} style={{ marginBottom: 25 }}>
                    <Text key={index} style={{ fontSize: 14, marginTop: 10 }} h2 h2Style={{ marginBottom: 15 }}>
                        {`${this.formatDateToDisplay(date)}` + storeTimeString}
                    </Text>
                    <Text>{weeklyPlanComponents}</Text>
                </View>
            );

            weekDayElements.push(dayElement);
        }

        const startOfWeek = this.getStartOfWeek(newDate);
        const endOfWeek = this.getEndOfWeek(newDate);
        const calendarWeek = this.getCalendarWeek(startOfWeek);

        this.setState({
            weekDayElements,
            startOfWeek,
            endOfWeek,
            calendarWeek,
            currentDate: newDate,
            isLoading: false,
        });
    }

    render() {
        const { windowWidth } = this.state;
        return (
            <View style={{ flex: 1 }}>
                <ScrollView style={styles.contentContainer}>
                    <View style={{ padding: 20 }}>
                        {!this.state.isLoading && (
                            <View style={styles.headerContainer}>
                                <Pressable
                                    onPress={this.goPreviousWeek}
                                    style={[
                                        styles.sideButton,
                                        { backgroundColor: this.props.settings.store.accentColor },
                                    ]}
                                >
                                    <MaterialCommunityIcon
                                        style={styles.buttonIcon}
                                        name="arrow-left-circle-outline"
                                        color={getContrastColor(this.props.settings.store.accentColor)}
                                        size={25}
                                    />
                                </Pressable>
                                <View style={styles.middleContainer}>
                                    {!this.state.isLoading && (
                                        <Text style={styles.headerText}>
                                            {`Kalenderwoche ${this.state.calendarWeek} ${
                                                windowWidth >= 756
                                                    ? `(vom ${this.formatHeaderDateToDisplay(
                                                          this.state.startOfWeek
                                                      )} bis ${this.formatHeaderDateToDisplay(this.state.endOfWeek)})`
                                                    : ""
                                            }`}
                                        </Text>
                                    )}
                                </View>
                                <Pressable
                                    onPress={this.goNextWeek}
                                    style={[
                                        styles.sideButton,
                                        { backgroundColor: this.props.settings.store.accentColor },
                                    ]}
                                >
                                    <MaterialCommunityIcon
                                        style={styles.buttonIcon}
                                        name="arrow-right-circle-outline"
                                        color={getContrastColor(this.props.settings.store.accentColor)}
                                        size={25}
                                    />
                                </Pressable>
                            </View>
                        )}

                        {this.state.isLoading && (
                            <View style={styles.loadingContainer}>
                                <ActivityIndicator size="large" color={appColors.primary} />
                            </View>
                        )}
                        {!this.state.isLoading && (
                            <View style={{ marginBottom: 50 }}>{this.state.weekDayElements}</View>
                        )}
                    </View>
                </ScrollView>

                <Overlay
                    overlayStyle={{
                        width: "50%",
                        padding: 0,
                        maxWidth: 700,
                        minWidth: 320,
                        overflow: "auto",
                        maxHeight: "100vh",
                        borderRadius: 10,
                        backgroundColor: appColors.grey0,
                    }}
                    ariaHideApp={false}
                    ModalComponent={Platform.OS === "web" ? WebModal : Modal}
                    isVisible={this.state.articleDetailsVisible}
                    onBackdropPress={this.hideArticleDetails}
                >
                    <Icon
                        name="times"
                        type="font-awesome-5"
                        size={20}
                        color={appColors.grey0}
                        onPress={this.hideArticleDetails}
                        containerStyle={styles.overlayCloseIcon}
                    />
                    <ArticleDetails article={this.state.articleToShow} hideArticleDetails={this.hideArticleDetails} />
                </Overlay>
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { zipCode, settings } = state;
    return { zipCode, settings };
}

export default connect(mapStateToProps)(withRouter(WeeklyPlanScreen));
