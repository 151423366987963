import { StyleSheet } from "react-native";

export const customerDataStyles = StyleSheet.create({
    container: {
        // width: 350,
        width: "95%",
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: 1,
    },
    rowWithMultiInput: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    fieldHouseNumber: {
        width: "30%",
        paddingRight: 0,
    },
    fieldStreet: {
        width: "70%",
        paddingLeft: 0,
    },
    fieldCity: {
        width: "70%",
        paddingRight: 0,
    },
    fieldZip: {
        width: "30%",
        paddingLeft: 0,
    },
    fieldsWrapper: {
        flexDirection: "row",
        flexWrap: "wrap",
        // maxWidth: 320,
    },
    fieldRow: {
        width: "100%",
    },
    containerFullRow: {
        width: "100%",
        flexDirection: "column",
    },
    containerSplitRow: {
        width: "100%",
        flexDirection: "row",
    },
    fieldFormFullRow: {
        width: "100%",
        marginBottom: 16,
    },
    fieldFormSplitRow: {
        width: "48%",
        marginRight: "2%",
        marginBottom: 16,
    },
    fieldFormthirdSplitRow: {
        width: "33%",
        marginRight: "2%",
        marginBottom: 16,
    },
    buttonContainerFullRow: {
        justifyContent: "space-between",
        width: "100%",
        paddingRight: 10,
        paddingLeft: 10,
    },
});
