import moment from "moment";
import React, { Component } from "react";
import { ActivityIndicator, View } from "react-native";
import { Divider, Text } from "react-native-elements";

import { appColors, styles } from "../../shared/styles";
import { getArticlesFromWeeklyPlan, getEntriesIngredients } from "../../shared/weeklyPlan";
import WeeklyPlanArticle from "./WeeklyPlanArticle";
import { weeklyPlanStyles } from "./WeeklyPlanStyles";

export default class WeeklyPlan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            weeklyPlanEntries: null,
            weeklyPlanEntriesIngredients: null,
            startDate: undefined,
            isLoading: true,
        };

        this.getWeeklyPlanData = this.getWeeklyPlanData.bind(this);
        this.getDateForDay = this.getDateForDay.bind(this);
    }

    componentDidMount() {
        this.getWeeklyPlanData();
    }

    async getWeeklyPlanData() {
        let entries = getArticlesFromWeeklyPlan(this.props.weeklyPlan);
        let entriesIngredients = await getEntriesIngredients(entries);
        const startDate = moment(this.props.weeklyPlan.date).startOf("week").valueOf();
        this.setState({
            weeklyPlanEntries: entries,
            weeklyPlanEntriesIngredients: entriesIngredients,
            startDate,
            isLoading: false,
        });
    }

    getDateForDay(day) {
        let dateForDay = "";
        const daysToAdd = day - 1;
        const startDate = moment(this.state.startDate);

        if (this.state.startDate) {
            dateForDay = startDate.add(daysToAdd, "days").valueOf();
        }
        return dateForDay;
    }

    render() {
        const { weeklyPlanEntries, weeklyPlanEntriesIngredients, isLoading } = this.state;
        return (
            <>
                {isLoading && (
                    <View style={styles.loadingContainer}>
                        <ActivityIndicator size="large" color={appColors.primary} />
                    </View>
                )}
                <>
                    {weeklyPlanEntries !== null && (
                        <>
                            <View style={weeklyPlanStyles.desktopContainer}>
                                {weeklyPlanEntries.map((entry) => {
                                    return (
                                        <WeeklyPlanArticle
                                            key={entry.weeklyPlanEntriesId}
                                            weeklyPlanComponent={entry.weeklyPlanComponent}
                                            entry={entry}
                                            article={entry.Article}
                                            showArticleDetails={this.props.showArticleDetails}
                                            showCartButton={this.props.showCartButton}
                                            weeklyPlanDay={this.getDateForDay(entry.validOnDay)}
                                        />
                                    );
                                })}
                            </View>
                        </>
                    )}
                    {/* 
                    Für (Hayn) Kochboxen, nicht benötigt für Mensa shop
                    {weeklyPlanEntriesIngredients !== null && (
                        <>
                            <Divider style={{ flex: 1, width: "100%", marginBottom: 20, marginTop: 30 }} />
                            <Text h2 h2Style={{ marginBottom: 15 }}>
                                Einzelkomponenten
                            </Text>
                            <View style={weeklyPlanStyles.desktopContainer}>
                                {weeklyPlanEntriesIngredients.map((entry, index) => {
                                    return (
                                        <WeeklyPlanArticle
                                            key={index}
                                            weeklyPlanComponent={entry.weeklyPlanComponent}
                                            entry={entry}
                                            article={entry.Article}
                                            showArticleDetails={this.props.showArticleDetails}
                                            weeklyPlanDay={this.getDateForDay(entry.validOnDay)}
                                        />
                                    );
                                })}
                            </View>
                        </>
                    )} */}
                </>
            </>
        );
    }
}
