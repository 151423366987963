import React from "react";
import { Input } from "react-native-elements";

let props = {
    disabled: false,
    error: false,
    helperText: "",
    id: 0,
    index: 0,
    label: "",
    margin: "none",
    minRows: 1,
    multiline: false,
    name: "",
    onBlur: () => {},
    onChange: () => {},
    required: false,
    placeholder: "",
    size: "medium",
    sx: {},
    type: "text",
    value: "",
    variant: "filled",
};

export default function CustomInputField(props) {
    return (
        <Input
            disabled={props.disabled}
            errorMessage={props.error}
            helperText={props.helperText}
            id={props.id}
            key={props.index}
            label={props.label}
            minRows={props.minRows}
            multiline={props.multiline}
            name={props.name}
            margin={props.margin}
            onBlur={props.onBlur}
            onChange={props.onChange}
            placeholder={props.placeholder}
            required={props.required}
            size={props.size}
            sx={props.sx}
            type={props.type}
            value={props.value}
            variant={props.variant}
        />
    );
}
